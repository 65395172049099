import React, { useEffect, useRef, useState } from "react";
import Header from "../CommonComponet/Header";
import Footer from "../CommonComponet/Footer";
import heartLike from "../../images/Heartlike.png"
import heartUnLike from "../../images/Heartnotlike.png"
import { CiCalendar } from "react-icons/ci";
import GooglePlacesAutocomplete from "react-google-autocomplete";
import {
  EventCreate,
  EventUpdate,
  FavoriteEvent,
  MyEventList,
  GetEventTags,
  GetCommentList,
} from "../../api/Api";
import FooterMobile from "../CommonComponet/FooterMobile";
import { Helmet } from "react-helmet";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { CiLocationOn } from "react-icons/ci";
import { AiOutlineClose } from "react-icons/ai";
import calendar from "../../images/Calendar.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Store } from "react-notifications-component";
import { MdInsertComment, MdOutlineCameraAlt, MdOutlineOutlinedFlag } from "react-icons/md";
import applyuser from "../../images/userprofile.png";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { CiEdit } from "react-icons/ci";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CropImageModal from "../CommonComponet/CropImageModal";
import { useNavigate } from "react-router-dom";
import ReportComment from "./ReportComment/ReportComment";
import { useAuth } from "../../middleware/AuthProvider";
import { timeAgo } from "../../helper/getCookie";
import verifyIcon from '../../images/verified-icon.png';

const MyEvent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [eventEditMessageShowModal, setEventEditMessageShowModal] = useState(false);
  const [eventShowModal, setEventShowModal] = useState(false);
  const [eventType, setEventType] = useState("Event");
  const [name, setName] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [address, setAddress] = useState("");

  const [date, setDate] = useState("");
  const [time, setTime] = useState();
  const [nameError, setNameError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [dateError, setDateError] = useState("");
  const [timeError, setTimeError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [description, setDescription] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [eventImages, setEventImages] = useState(null);
  const divRefpvt = useRef(null);
  const [imageError, setImageError] = useState("");
  const [myEventList, setMyEventList] = useState([]);
  const [eventModalData, setEventModalData] = useState({});
  const [cardSkeletonMeUser, setCardSkeletonMeUser] = useState(true);

  const [tagOptions, setTagOptions] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedTagsError, setSelectedTagsError] = useState("");

  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({ aspect: 2 / 1, unit: "px", x: 0, y: 0, width: 200, height: 100 });
  const [image, setImage] = useState(null);

  const quillRef = useRef(null);

  const fetchTags = async () => {
    try {
      const response = await GetEventTags();
      
      const formattedTags = response.data.data.map((tag) => ({
        label: tag.eventTags,
        value: tag.id
      }));
      setTagOptions(formattedTags);
    } catch (error) {
      console.error("Error fetching event tags:", error);
    }
  };

  useEffect(() => {
    document.title = "EVENTS and PARTIES On Hukup Australia";
    MyEventListApi();
    fetchTags();
  }, []);
  
  useEffect(() => {
    const quill = quillRef.current?.getEditor();
    
    if (!quill) return;
  
    // Listen for text changes
    quill.on("text-change", () => {
      const text = quill.getText().trim(); // Get plain text without formatting
      const regex = /(https?:\/\/[^\s]+)/g;
      let matches = [...text.matchAll(regex)]; // Match all URLs at once (avoids infinite loop)

      matches.forEach((match) => {
        const start = match.index;
        const end = start + match[0].length;

        // Apply link formatting only if not already linked
        const formats = quill.getFormat(start, end);
        if (!formats.link) {
          quill.formatText(start, end - start, "link", match[0]);
        }
      });
    });
  }, [eventShowModal])

  const closeToModal = () => {
    setEventShowModal(false);
    Errorclear();
    setName("");
    setDescription("");
    setDate("");
    setTime();
    setEventType("Event");
    setSelectedImage(null);
    setEventImages(null);
    setSelectedTags([]);
    setEventTypeModal("Add");
    setAddress("");
    setCity("");
    setState("");
    setCountry("");
    setZipcode("");
    setLatitude(null);
    setLongitude(null);
  };

  const formatDate = (dateString) => {
    // Parse the input date string into a Date object
    const date = new Date(dateString);
    // Get the year, month, and day
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-based
    const day = date.getDate().toString().padStart(2, "0");

    // Format the date as "YYYY-MM-DD"
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  };

  const validation = () => {
    Errorclear();
    var isvalid = true;

    if (name == "" || !name || name.trim() == "") {
      setNameError("Name is required");
      isvalid = false;
    }

    if (!selectedTags || !selectedTags.length) {
      setSelectedTagsError("Tag is required");
      isvalid = false;
    }

    if (latitude == "" || !latitude || longitude == "" || !longitude || address == "" || !address) {
      setAddressError("Location is required");
      isvalid = false;
    }

    // Only validate date and time if eventType is NOT "Cruise" or "Venue"
    if (eventType !== "Cruise" && eventType !== "Venue") {
      if (date == "" || !date) {
        setDateError("Date is required");
        isvalid = false;
      }
      if (time == "" || !time) {
        setTimeError("Time is required");
        isvalid = false;
      }
    }

    if (eventImages == null && !eventModalData.id) {
      setImageError("Please Select Image");
      isvalid = false;
    }

    if (description == "" || !description || description.trim() == "") {
      setDescriptionError("Description is required");
      isvalid = false;
    }

    return isvalid;
  };

  const Errorclear = (e) => {
    setNameError("");
    setAddressError("");
    setDateError("");
    setTimeError("");
    setImageError("");
    setDescriptionError("");
    setSelectedTagsError("");
  };

  const CreateEvent = () => {
    
    if (!validation() || isLoading) {
      return false;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("address", address);
    formData.append("city", city);
    formData.append("state", state);
    formData.append("country", country);
    formData.append("zipcode", zipcode);
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    if (eventType !== "Cruise" && eventType !== "Venue") {
      const hours = time.getHours().toString().padStart(2, "0");
      const minutes = time.getMinutes().toString().padStart(2, "0");
      formData.append("date", formatDate(date));
      formData.append("time", `${hours}:${minutes}`);
    }
    formData.append("type", eventType);
    if(eventImages) {
      if (eventTypeModal == "Add") {
        formData.append("image", eventImages, "event-" + myEventList.length + ".png")
      }
      else {
        formData.append("image", eventImages, "event-" + eventModalData.id + ".png")
      }
    }
    formData.append("description", description);

    if (selectedTags?.length > 0) {
      formData.append("tag_id", selectedTags.join(',')); // Ensure selectedTags is an array of values
    }

    if (eventTypeModal == "Add") {
      EventCreate(formData)
        .then(function (response) {
          if (!response.data.status) {
            Store.addNotification({
              message: response.data.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__zoomIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: false,
              },
            });
            return false;
          } else {
            Store.addNotification({
              message: response.data.message,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__zoomIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: false,
              },
            });
          }
          MyEventListApi();
          closeToModal();
          setIsLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          setIsLoading(false);
        });
    } else {
      formData.append("event_id", eventModalData.id);
      EventUpdate(formData)
        .then(function (response) {
          if (!response.data.status) {
            Store.addNotification({
              message: response.data.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__zoomIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: false,
              },
            });
            return false;
          } else {
            Store.addNotification({
              message: response.data.message,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__zoomIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: false,
              },
            });
          }
          MyEventListApi();
          closeToModal();
          setIsLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  const fileInputRef = useRef(null);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (e) => {
    if (e.target.files.length) {
      const imageFile = e.target.files[0];
      setCrop({ aspect: 2 / 1, unit: "px", x: 0, y: 0, width: 200, height: 100 });
      setSrc(URL.createObjectURL(imageFile));
    }
  };

  const MyEventListApi = () => {
    MyEventList()
      .then(function (response) {
        setCardSkeletonMeUser(false);
        setMyEventList(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [eventTypeModal, setEventTypeModal] = useState(null);

  const handleEventModelEditData = (item) => {
    setEventModalData(item);
    if(item.status == "approved") {
      setEventEditMessageShowModal(true);
    }
    else {
      setEventShowModal(true);
    }
    setEventTypeModal("Edit");

    // Ensure eventModalData exists before accessing properties
    if (item?.date) setDate(new Date(item.date));
    if (item?.name) setName(item.name);
    if (item?.type) setEventType(item.type);
    if (item?.image) setSelectedImage(item.image);
    if (item?.description) setDescription(item.description);
    if (item?.latitude) setLatitude(item.latitude);
    if (item?.longitude) setLongitude(item.longitude);
    if (item?.city) setCity(item.city);
    if (item?.state) setState(item.state);
    if (item?.country) setCountry(item.country);

    const oldTags = Array.isArray(item.tags) ? item.tags :
    item.tags.split(",")
    
    if (oldTags) {
      const tagIds = [];
      for (let index = 0; index < tagOptions.length; index++) {
        const option = tagOptions[index];
        if(oldTags.includes(option.label)) {
          tagIds.push(option.value)
        }
      }
      setSelectedTags(tagIds);
    }
              
    if (item?.address) {
      setAddress(item.address);
    } else {
      console.log("No Location Found in Item");
    }

    // Handle time safely
    if (item?.time) {
      setTime(new Date(`${item?.date} ${item?.time}`)); // Set the full time string
    }

  }

  const cropImageNow = () => {
      if (!crop.width || !crop.height) {
        return false;
      }
  
      if (crop.height < 20 || crop.width < 20) {
        Store.addNotification({
          message: "Notification",
          message: "Please select large area",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
        return false;
      }
  
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const ctx = canvas.getContext('2d');
  
      const pixelRatio = window.devicePixelRatio;
      canvas.width = scaleX * crop.width * pixelRatio;
      canvas.height = scaleY * crop.height * pixelRatio;
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = 'high';
  
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY,
      );
  
      // Converting to base64
      const base64Image = canvas.toDataURL('image/jpeg');
      const byteCharacters = atob(base64Image.split(",")[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/png" });
      setSelectedImage(URL.createObjectURL(blob))
      setEventImages(blob);
      setSrc(null);
  };
  
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={"EVENTS and PARTIES On Hukup Australia"}
        />
      </Helmet>

      <div className="container-fluid p-0 main_section">
        <div className="background_section_main">
          <div className="yellow_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="orange_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <img
            alt="Whitedot"
            src={require("../../../src/images/Whitedot.png")}
            className="d-none d-sm-none d-md-none d-lg-block Whitedot_first"
          />
          <img
            alt="Whitedot"
            src={require("../../../src/images/Whitedot.png")}
            className="d-none d-sm-none d-md-none d-lg-block Whitedot_second"
          />
          <img
            alt="orangeline"
            src={require("../../../src/images/orangeline.png")}
            className="d-none d-sm-none d-md-none d-lg-block footer_orangeline"
          />
          <img
            alt="greenline"
            src={require("../../../src/images/greenline.png")}
            className="d-none d-sm-none d-md-none d-lg-block footer_greenline"
          />
          <img
            alt="Ellipse"
            src={require("../../../src/images/Ellipse.png")}
            className="footer_ellipse_img"
          />
          <img
            alt="footerblackline"
            src={require("../../../src/images/footerblackline.png")}
            className="footer_blackline_img"
          />
        </div>
        <Header />

        <div className="container-fluid content_main px-0">
          <div className="container">
            <div className="row">
              <div className="col-12 Blocked_User_section_contain">
                <div className="row  ">
                  
                  <div className="mt-3">
                    <h1 className="Favourited_tab_title_name fw-bold d-flex justify-content-center">My Party</h1>
                    <div
                      className="col-12 Liked_section_slider mt-2 "
                      ref={divRefpvt}
                    >
                      {cardSkeletonMeUser && (
                        <SkeletonTheme>
                          <Row className="">
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                              (ele) => {
                                return (
                                  <Col xs={12} md={12} lg={12} xl={12}>
                                    <Skeleton height={200} count={1} />
                                  </Col>
                                );
                              }
                            )}
                          </Row>
                        </SkeletonTheme>
                      )}
                      <div className="row">
                        <div className="my-3 text-end">
                          <button
                            className="verify_email"
                            onClick={(e) => {
                              setEventShowModal(true);
                              setEventTypeModal("Add");
                            }}
                          >
                            Add Party
                          </button>
                        </div>
                      </div>
                      <div className="row row-cols-12 row-cols-lg-12 row-cols-md-12 row-cols-xl-12 px-0 px-sm-0 px-md-0 px-lg-0 px-xl-0">
                        {myEventList?.map((item) => {
                          return (
                            <ProfileCard
                              item={item}
                              handleEventModelEditData={handleEventModelEditData}
                            />
                          );
                        })}
                        {myEventList?.length === 0 && (
                            <div className="text-center w-100 py-5">
                              <h5>You have not created any event.</h5>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterMobile />
        </div>
        <Footer />
      </div>

      <Modal
        show={eventShowModal}
        onHide={() => closeToModal()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="main_modal_sectione"
      >
        <div className="modal_main_section_add_num_email">
          <Modal.Body>
            <div className="text-right d-flex align-items-center justify-content-between">
              <h5 className="mb-0 email_num_title">{eventTypeModal} Event</h5>
              <AiOutlineClose
                onClick={() => closeToModal()}
                className="close_email_number"
              />
            </div>
            <div className="my-4 email_verify_main">
              <div className="mb-1 d-flex align-items-center justify-content-center position-relative">
                <input
                  type="file"
                  accept=".jpg,.jpeg,.png"
                  onChange={handleImageChange}
                  ref={fileInputRef}
                  style={{ display: "none" }}
                />
                <div
                  onClick={handleImageClick}
                  className="position-relative cursor-pointer"
                >
                  {selectedImage ? (
                    <img
                      className="select-imgset mb-3"
                      src={selectedImage}
                      alt="Profile"
                      style={{ cursor: "pointer" }}
                      width={90}
                      height={90}
                    />
                  ) : (
                    <div className="user_icon_profile mb-3">
                      <span className="profile_user_icon">
                        <img
                          src={applyuser}
                          alt="user"
                          className="optioniconwidth"
                        />
                      </span>
                    </div>
                  )}
                  <div
                    className="profile-set-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleImageClick();
                    }}
                  >
                    <MdOutlineCameraAlt fill="#fff" />
                  </div>
                </div>
              </div>
              <p className="text-center errorClass">{imageError}</p>
              <div className="d-flex align-items-center justify-content-center">
                <div className="text-nowrap me-3">Event Type</div>
                <select
                  className="form-select Userdetails_modal_selector"
                  aria-label="Default select example"
                  value={eventType}
                  onChange={(e) => setEventType(e.target.value)}
                >
                  <option value="Event">Event</option>
                  <option value="Party">Party</option>
                  <option value="Cruise">Cruise</option>
                  <option value="Venue">Venue</option>
                </select>
              </div>

              <div className="my-3">

                <Select
                  options={tagOptions}
                  value={tagOptions.filter(option => selectedTags.includes(option.value))}
                  onChange={(selectedOptions) => {
                    setSelectedTags(selectedOptions.map(option => option.value))
                  }}
                  isMulti
                  className="event-tags  rounded-2 "
                  placeholder="Select tags"
                  isSearchable={false}
                  isClearable={false}
                />
                <p className="text-start errorClass">{selectedTagsError}</p>
              </div>

              <div className="my-3">
                <Form.Control
                  type="text"
                  placeholder="Enter Title"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  readOnly={eventTypeModal === "Edit" && eventType === "Cruise" || eventTypeModal === "Edit" && eventType === "Venue"}
                />
                <p className="text-start errorClass">{nameError}</p>
              </div>

              <div className="my-3">

                <GooglePlacesAutocomplete
                  className="form-control"
                  apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                  defaultValue={address}
                  onPlaceSelected={(place) => {
                    // Get full address components
                    const addressComponents = place.address_components;
                    let city = '';
                    let state = '';
                    let country = '';
                    let zipcode = 0;
                    let fullAddress = place.formatted_address;

                    // Extract address components
                    addressComponents.forEach(component => {
                      if (component.types.includes('locality')) {
                        city = component.long_name;
                      }
                      if (component.types.includes('administrative_area_level_1')) {
                        state = component.long_name;
                      }
                      if (component.types.includes('country')) {
                        country = component.long_name;
                      }
                      if (component.types.includes('postal_code')) {
                        zipcode = component.long_name;
                      }
                    });
                    setLatitude(place.geometry.location.lat());
                    setLongitude(place.geometry.location.lng());
                    setCity(city);
                    setState(state);
                    setCountry(country);
                    setZipcode(zipcode);
                    setAddress(fullAddress);
                  }}
                  options={{
                    types: ["establishment"],
                  }}
                />
                <p className="text-start errorClass">{addressError}</p>
              </div>

              {eventType !== "Cruise" && eventType !== "Venue" && (
                <>
                  <div className="my-3">
                    <div className="form-control">
                      <DatePicker
                        type="date"
                        showYearDropdown={true}
                        showMonthDropdown={true}
                        dropdownMode={"select"}
                        showIcon
                        icon={<img alt="Calendar" src={calendar}></img>}
                        selected={date}
                        onSelect={(date) => {
                          setDate(date);
                        }}
                        onChange={(date) => {
                          setDate(date);
                        }}
                        minDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/YYYY"
                      />
                    </div>
                    <p className="text-start errorClass">{dateError}</p>
                  </div>
                  <div className="my-3">
                    <div className="form-control">
                      <DatePicker
                        selected={time}
                        onChange={(date) => setTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        placeholderText="Select Time"
                      />
                    </div>
                    <p className="text-start errorClass">{timeError}</p>
                  </div>
                </>
              )}

              <div className="my-3">
                  <ReactQuill
                    ref={quillRef}
                    value={description}
                    onChange={setDescription}
                    modules={{ toolbar: false }} // Disable toolbar
                    placeholder="Enter Description"
                  />
                <p className="text-start errorClass">{descriptionError}</p>
              </div>
            </div>

            <div className="text-center">
              <button className="verify_email" onClick={() => CreateEvent()}>
                Submit
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      
      <Modal
        show={eventEditMessageShowModal}
        onHide={() => setEventEditMessageShowModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="main_modal_sectione"
      >
        <div className="modal_main_section_add_num_email">
          <Modal.Body>
            <p>
            If you edit the event, its status will change from approved to pending, and it will need to be re-approved by the admin.
            </p>
            <div className="text-center">
              <button className="verify_email" onClick={() => {
                setEventShowModal(true);
                setEventEditMessageShowModal(false);
              }}>
                Edit
              </button>
              <button className="cancel_btn ml-2" onClick={() => setEventEditMessageShowModal(false)}>
                Cancel
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      <CropImageModal
        src={src}
        setSrc={setSrc}
        crop={crop}
        setCrop={setCrop}
        image={image}
        setImage={setImage}
        cropImageNow={cropImageNow}
        ratio={2 / 1}
      />
      
    </>
  );
};

export default MyEvent;

const ProfileCard = (props) => {

  var navigate = useNavigate()
  const [isfavourite, setIsFavourite] = useState(props?.item?.is_event_favorite == 1)
  const [commentMsg, setCommentMsg] = useState(false);
  const [commentMessageList, SetCommentMessageList] = useState([]);

  const CommentListGet = () => {
    GetCommentList(props.item.id)
      .then(function (response) {
        SetCommentMessageList(response.data.data.slice(0, 2));
        setCommentMsg(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleFavoriteEvent = () => {
    FavoriteEvent(props.item.id).then(function (response) {
      setIsFavourite(!isfavourite)
    })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }

  return (
    <div className="card_main">

      <div className="event_card_section_main_sidebar_favo">
        <div className="card_section_sidebar_center_main">
          <div
            className="second_star"
            onClick={(e) => {
              if (!props?.item) return; // Ensure item exists
              props.handleEventModelEditData(props.item)
            }}
          >
            <CiEdit className="modal_Chatpage_luSend" />
          </div>

          {props?.item?.status == "approved" && (
            <>
              <div className="card_section_chiled" onClick={handleFavoriteEvent}>
                {isfavourite ? (
                  <img
                    src={heartLike}
                    alt="Like"
                    className="icon_heart_red_sidebar"

                  />
                ) : (
                  <img
                    src={heartUnLike}
                    alt="Unlike"
                    className="icon_heart_sidebar"

                  />
                )}
              </div>
              <div
                className="card_section_chiled"
                onClick={CommentListGet}
              >
                <MdInsertComment className="card_Chatpage_luSend_sidebar" />
              </div>
            </>
          )}
        </div>
      </div>

      <div className="col px-0">
        <div className="linkover event-card-main-div">
         
          <div className="event_card_section event_card_section_height d-flex w-100"
            onClick={() => {
              navigate(`/event-details/${props?.item?.id}`);
            }}>
            <div
              className="event_card_img_height"

            >
            
              <img
                src={props?.item?.image}
                className="card_background_img img-width img-fluid event_card_border_radius"
                alt="Profile"
              />
            </div>
            <div className="user_name_width_set ms-3 ">

              <h4 className="my-0 event_name fw-bold">{props?.item?.name}</h4>
              <h6 className="my-0  text-break mt-1 event_location ">{props?.item?.type}</h6>
              {
                (props?.item?.eventType !== "Cruise" && props?.item?.eventType !== "Venue") && (
                  <p className="my-0 event_location mt-1 d-flex align-items-center">
                    <CiCalendar className="mr-1" size={18} />
                    <span className="event_location_color">
                      {`${new Date(props?.item?.date).toLocaleDateString('en-US', {
                        weekday: 'short'
                      })}, ${new Date(props?.item?.date).getDate()} ${new Date(props?.item?.date).toLocaleDateString('en-US', {
                        month: 'long'
                      })}, ${new Date(`2000-01-01 ${props?.item?.time}`).toLocaleTimeString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true
                      })}`}
                    </span>
                  </p>
                )
              }

              <div className="d-flex w-100">
                {
                  props?.item?.lastCommentTime && (
                    <div className="my-0  text-break-events mt-1 d-flex align-items-center"><MdInsertComment className="mr-1" /><span className="event_location">{timeAgo(props?.item?.lastCommentTime)}</span></div>
                  )
                }
                {
                  props?.item?.distance && (
                    <div className="my-0  text-break-events mt-1 d-flex align-items-center"> <CiLocationOn className="mr-1" /><span className="event_location">{props?.item?.distance?.toFixed(2)}Km</span></div>
                  )
                }
              </div>


              <p className="my-0 event_location mt-1">
                <span className="event_location_color">
                  Status: {" " + props?.item?.status}
                </span>
              </p>

            </div>

          </div>
        </div>
      </div>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={commentMsg}
        onHide={() => {
          setCommentMsg(false);
        }}
      >
        <div className="Userdetails_modal_main_section text-center">
          <h4 className="Userdetails_modal_title">Replies</h4>
          <hr />
          <div>
            <div className="preMessageBox">
              {commentMessageList?.length > 0 ? (
                commentMessageList?.map((e, index) => {
                  return <CommentMessageListComponent data={e} key={index} />;
                })
              ) : (
                <div className="text-center d-flex justify-content-center align-items-center h-100">No reply</div>
              )}
            </div>

            <div className="d-flex justify-content-center">
              <Button
                className="Userdetails_modal_btn_Cancel"
                onClick={() => {
                  setCommentMsg(false);
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </Modal>

    </div>
  );
};

const CommentMessageListComponent = ({ data }) => {
  const navigate = useNavigate();
  const { AuthData } = useAuth();
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <>
      <div className="row">
        <div className="col-6 my-1 pe-0 text-nowrap comment_user_name">
          <div className="cursor_pointer_common" onClick={() => navigate("/user/" + data?.user_name)}>
            <img src={data?.user_image || "https://www.datingguide.com.au/images-SponsoredAds/2587.jpg"} className="rounded-pill comment-user-image" />
            <span>
              {data?.fullName}
              {data.userProfileVerify === 1 && <img alt='Verify' src={verifyIcon} className='user_verify-icon-home'></img>}
            </span>
          </div>
        </div>
        <div className="col-6 my-1 text-break text-end">
        {(() => {
          const commentTime = new Date(data?.created_at)
          const now = new Date()
          const diffMs = now - commentTime
          const diffMins = Math.floor(diffMs / 60000)
          const diffHours = Math.floor(diffMs / 3600000)
          const diffDays = Math.floor(diffMs / 86400000)
          const diffWeeks = Math.floor(diffDays / 7)

          if (diffMins < 1) {
            return 'Just Now'
          } else if (diffMins < 60) {
            return `${diffMins} minutes ago`
          } else if (diffHours < 24) {
            return `${diffHours} hours ago`
          } else if (diffDays < 7) {
            return `${diffDays} days ago`
          } else {
            return `${diffWeeks} weeks ago`
          }
        })()}
        </div>
      </div>
      <div className="row">
        <div className="col-9 my-1 text-break">
          {data?.comment_text}
        </div>
        <div className="col-3 my-1 text-break text-end">
          {AuthData.id !== data.userId && (
            <div className="text-black cursor_pointer_common">
              <MdOutlineOutlinedFlag size={20} onClick={() => setModalShow(true)} />
            </div>
          )}
        </div>
      </div>
      <ReportComment
        show={modalShow}
        onHide={() => setModalShow(false)}
        comment_id={data.id}
      />
    </>
  );
};