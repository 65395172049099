const baseURL = process.env.REACT_APP_API_URL;
const endpoint = baseURL + "/api/v1";

const api = {
  socketURL: baseURL,
  loginClaim: endpoint + "/user/login", 
  loginsendotpclaimaccount: endpoint + "/user/send-otp-claim-account",
  login: endpoint + "/user/verify-login-otp", 
  loginsendotp: endpoint + "/user/send-login-otp",
  countrycode:endpoint + "/country/list",
  updateProfile : endpoint + "/user/profile-update",

  usernameCheck : endpoint + "/user/username-check",

  profileSecondPageData : endpoint + "/profile-data/list",
  profileLocationData : endpoint + "/suburus-view",
  updateProfileSecondPage : endpoint + "/user/preference-update",
  updateProfilepartenerPage : endpoint + "/user/partener/add",
  homeUserList: endpoint + "/user/list-filter",
  favoritesUser: endpoint + "/user/favorites",
  likeUser : endpoint + "/user/liked",
  logOut : endpoint + "/user/logout",
  userDetalis : endpoint + "/user/details/",
  report:endpoint + "/user/report",
  membershipPlan: endpoint + "/user/membership-plan",
  likeView : endpoint + "/user/liked/view",
  likeYou: endpoint + "/user/liked/by-me/view",
  matches : endpoint + "/user/interest/match",
  galleryView : endpoint + "/user/gallery-view/",
  dropdownListReport : endpoint + "/setting/report-user-type/list",
  favoritesYou:endpoint + "/user/favorites/me/view",
  favoritesByYou : endpoint + "/user/favorites/view",
  footerList:endpoint + "/setting/content-list",
  footerPageData:endpoint + "/setting/content-details/", 
  faqPageData:endpoint + "/setting/faq-list",
  faqMetaPageData:endpoint + "/setting/faq-meta-list",


  vistedByUser:endpoint + "/visitor/me/view",
  userVisitor:endpoint + "/visitor/view",
  blockUserList: endpoint + "/user/block/view",
  blockAndUnblockUser:endpoint + "/user/block",
  userPublicGallery :endpoint + "/user/my-public-gallery",


  userAllGalleryapi :endpoint + "/user/view-image",

   
  userPrivateGallery :endpoint + "/user/my-private-gallery",
  otheruserPublicGallery :endpoint + "/user/gallery-view/",
  otheruserPrivateGallery :endpoint + "/user/gallery-private-access/",
  notificationView : endpoint + "/setting/notification-view",
  notificationSetting : endpoint + "/setting/notification",
  privacySetting : endpoint + "/setting/privacy",
  privacyView : endpoint + "/setting/privacy-view",
  reportProblemListSetting : endpoint + "/setting/report-problem-type/list",
  reportSetting:endpoint + "/setting/report-problem",
  deleteProfile : endpoint + "/user/delete",
  senddeleteotp : endpoint + "/user/send-delete-otp",
  verifydeleteotp : endpoint + "/user/verify-delete-otp",


  paymentHistory :endpoint + "/payment/view",
  payment_history_details :endpoint + "/payment/purchase-plan-details?id=",
  homepageFilter :endpoint + "/filter",
  myProfile : endpoint + "/user/profile-detail",
  makeanewfriend : endpoint + "/user/here-to-update",


  paymentCreateSession :endpoint + "/payment/create-checkout-session",
  userDetalisUpdate : endpoint + "/user/profile-detail-update",
  userInterestsUpadte : endpoint + "/user/profile-interest-update",
  addUserGallery : endpoint + "/user/image/upload",
  report_problem_list : endpoint + "setting/report-problem-type/list",
  profileFilterData : endpoint + "/user/category-filter",
  profileFilterPartnerData : endpoint + "/user/category-filter-partner",
  profileFilterUserInfoDatapost : endpoint + "/user/profile-dynamic-detail-update",
  profileFilterPartnerDatapost : endpoint + "/user/partner-dynamic-detail-update",
  userDetailesNote : endpoint + "/user/notes",
  image_uploads_url : endpoint + "/user/image-uploade",
  going_for_date : endpoint + "/date/going_for_date",
  accept_reject : endpoint + "/date/accept_reject",
  galleryDataDelete : endpoint + "/user/gallery/my-post-delete",
  gallerysetprofile : endpoint + "/post-to-profile",
  accept_reject_gallery_access : endpoint + "/user/gallery-access-status",
  pre_message_add : endpoint + "/pre-message-add",
  pre_message_view : endpoint + "/pre-message-view",
  pre_message_delete : endpoint + "/pre-message-delete",
  pre_message_edit : endpoint + "/pre-message-edit",
  gift_list : endpoint + "/gift-list",
  chatdelete : endpoint + "/user/clear-chat",
  login_page_links : endpoint + "/setting/content-list-show-on-login", 
  referral_page_links : endpoint + "/setting/content-list-show-on-referral", 

  sendotpapi : endpoint + "/user/send-otp",
  verifyemailotp : endpoint + "/user/verify-email-otp",
  userupdatewithotp : endpoint + "/user/update",


  siteSetting:endpoint + "/setting/site-setting",
  accessPrivateGallary : endpoint + "/user/private-gallery-approved",
  categoryUpdate :endpoint + "/user/category-update",
  userCouponsApply :endpoint + "/user/user_coupons_apply",
  freePlanPurchase :endpoint + "/user/free_plan_purchase",
  filterInterestCategory :endpoint + "/user/filter-interest-category",
  offerModalGetDate :endpoint + "/setting/view",
  getGeneralAnnouncement :endpoint + "/setting/get-general-announcement",
  emailVerify : endpoint + "/user/email-send-to-verify",
  emailMobileUpdate : endpoint + "/user/email-mobile-update",
  verifyOtpEmailnumber : endpoint + "/user/email-mobile-otp-verify",
  referraluserlist : endpoint + "/user/view-referral-details",
  paymetreferrallist : endpoint + "/user/view-referral-transaction",
  influencerlogin : endpoint + "/user/influencer-Login",
  withdrawalrequest : endpoint + "/user/withdrawal-request",
  encashmentpaymetlist : endpoint + "/user/withdrawal-request-history",
  usercreditapply : endpoint + "/user/user-credit-apply",
  creditfreeplanpurchase : endpoint + "/user/free-plan-purchase-via-credit",
  eventCreate: endpoint + "/user/eventsAndParties/create-event",
  eventUpdate: endpoint + "/user/eventsAndParties/update-event",
  geteventall :endpoint + "/user/eventsAndParties/list-events",
  favoriteEvent :endpoint + "/user/eventsAndParties/favorite-event",
  geteventbyid :endpoint + `/user/eventsAndParties/get-event-by-id/`,
  favoriteEventList :endpoint + "/user/eventsAndParties/favorite-events-list",
  myeventlist :endpoint + "/user/eventsAndParties/user-list-events",
  addComment: endpoint + "/user/eventsAndParties/add-comment",
  commentList : endpoint + "/user/eventsAndParties/comment-list/",
  addverification : endpoint + "/user/userProfileVerification",
  viewverification : endpoint + "/user/userProfileVerification/view",


  eventFilter : endpoint + "/user/eventsAndParties/orderby-filter-events",
  getEventTags : endpoint + "/user/eventsAndParties/get-event-tags",

  reportComment: endpoint + "/user/eventsAndParties/comment-report",
  sendDefaultMessage: endpoint + "/default-pre-message-send"
};

export default api;