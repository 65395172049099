import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { MdShare } from "react-icons/md";
import { MdOutlineContentCopy } from "react-icons/md";
import { useAuth } from '../../middleware/AuthProvider';
import DataLoader from '../../components/ScreenLoader/DataLoader';
import Header from '../CommonComponet/Header';
import qrcodeimg from '../../images/qrcode.png'
import FooterMobile from '../CommonComponet/FooterMobile';
import Footer from '../CommonComponet/Footer';
import { WithdrawalRequest } from '../../api/Api';
import { IoMdDownload } from "react-icons/io";
import { IoClose } from 'react-icons/io5';


const Referraldashbord = () => {
  const navigate = useNavigate();
  const { AuthData , siteSetting} = useAuth()
  const [isDataLoader, setisDataLoader] = useState(false);
  const [skeletonUserImage, setSkeletonUserImage] = useState(false);
  const [unableModalShare, setunableModalShare] = useState(false);


  useEffect(() => {
    document.title = "Hukup Australia"
  }, []);

  const handleshowunablehidemodal = () => {
    setunableModalShare(false)
  }

  const copyTorefrrallink = (urlToCopy) => {
    navigator.clipboard
      .writeText(urlToCopy)
      Store.addNotification({
        message: "Referral Code Copied",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__zoomIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: false,
        },
      });
  };

  const copyToClipboard = (urlToCopy) => {
    handleShareClick(urlToCopy);
    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => {
      })
      .catch((error) => {
      });
  };

  const handleShareClick = async (urlToCopy) => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: urlToCopy,
          text: "Share Referral Link",
          url: urlToCopy,
        });
      } else {
        console.error("Web Share API is not supported in this browser.");
        setunableModalShare(true);
      }
    } catch (error) {
      console.error("Error sharing:", error.message);
    }
  };

  
    const handleShareClickimg = async (imageSrc) => {
      try {
        const imageData = await fetch(imageSrc).then(res => res.blob());
        const file = new File([imageData], "some.png", { type: "image/png" });
      
        if (navigator.canShare && navigator.canShare({ files: [file] })) {
          await navigator.share({
            title: "Share File",
            files: [file]
          });
          console.log("Image shared successfully");
        } else {
          console.error("Web Share API is not supported in this browser.");
          setunableModalShare(true);
        }
      } catch (err) {
        console.error("Share failed:", err.message);
      }
      // try {
      //   if (navigator.share) {
      //     await navigator.share({
      //       title: 'Share Image',
      //       text: 'Check out this image!',
      //       url: imageSrc,
      //     });
      //     console.log('Image shared successfully');
      //   } else {
      //     throw new Error('Web Share API not supported.');
      //   }
      // } catch (error) {
      //   console.error('Error sharing image:', error.message);
      // }
    };
  
    const handleButtonClick = (imageUrl) => {
      handleShareClickimg(imageUrl);
    };

    const paymentoutrefrerral = () => {
      if(AuthData?.credits < siteSetting?.siteSetting?.minimum_credits){
        Store.addNotification({
          message: "Insufficient credits",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false,
          },
        });
        return false;
      }
     WithdrawalRequest()
     .then(function (response) {
        if(!response.data.status) {
          Store.addNotification({
            message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false;
        }
        Store.addNotification({
          message: response.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false,
          },
        });
     })
     .catch(function (error) {
      Store.addNotification({
        message: error.message,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__zoomIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: false,
        },
      });
     });
    }

    const download = async (imageUrl) => {
      try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);     
        const element = document.createElement("a");
        element.href = url;
        element.download = "image.png";
        document.body.appendChild(element); // Required for this to work in Firefox
        element.click();
        document.body.removeChild(element);
      } catch (error) {
        console.error("Error downloading the image:", error);
      }
    };

  return (
    <>
      {isDataLoader && (
        <DataLoader />
      )}

      <Helmet>
        <meta name="description" content={"Hukup Australia"} />
      </Helmet>

      <div className="container-fluid p-0 main_section">
        <div className="background_section_main">
          <img alt='orangeline' src={require("../../../src/images/orangeline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_orangeline" />
          <img alt='greenline' src={require("../../../src/images/greenline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_greenline" />
          <img alt='Ellipse' src={require("../../../src/images/Ellipse.png")} className="footer_ellipse_img" />
          <img alt='footerblackline' src={require("../../../src/images/footerblackline.png")} className="footer_blackline_img" />
        </div>
        <Header />

        <div className="user_Detailspage_main_section">
          <div className="container ">
            <div className="row mt-4 ">

              <div className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 ">
                <div className="row">
                  <div className="col-12 position-relative">
                    <div className="mx-auto user_Detailspage_user_img_section">
                      {skeletonUserImage ? (
                        <SkeletonTheme >
                          <div>
                            <Skeleton height={400} />
                          </div>
                        </SkeletonTheme>
                      ) :
                        <img alt='Profile' src={AuthData.thumbnail_image} className="user_Detailspage_user_img" />
                      }
                    </div>
                  </div>
                  <div className="col-12">
                    {skeletonUserImage ? (
                      <SkeletonTheme >
                        <div className='mt-3 px-3'>
                          <Skeleton height={30} />
                        </div>
                      </SkeletonTheme>
                    ) :
                      <div className="text-center mx-auto  d-flex align-items-center justify-content-center mt-4" >
                        <h1 className='mr-3 UserDetails_title_name text-break'>{AuthData.name}</h1>
                      </div>
                    }          
                  </div>
                </div>
              </div>

              {/* ======= user details section ============= */}
              <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8  pr-0 pl-0 pl-sm-0 pl-md-0 pl-lg-5 pl-xl-5 mt-4 mt-sm-4 mt-md-0 mt-lg-0 mt-xl-0">
                <div className="user_all_Details_secondsection user_view_details_pageseco">
                  <Row>
                  <div className="text-end mb-2">
                  <button className='verify_email'
                          onClick={(e) => { 
                           paymentoutrefrerral()
                        }}
                        >
                          Pay Out
                        </button>
                      </div>
                    <div className='col-lg-8'>
                      <div className="mt-1">
                        <h1 className="user_Detailspage_About">Referral</h1>
                          <p className="user_Detailspage_about_introduction mb-0 text-break">
                          credit: {siteSetting?.paymentSetting?.symbol}{AuthData.credits} 
                          </p>
                      </div>
                      <div className='my-2'>
                        <Link to="/referral-users" className='email_number_birth_visible text-break linkoverright'>
                          {siteSetting?.siteSetting?.referral_slug}
                        </Link>
                      </div>

                      <InputGroup className="mt-3" id="profileinputgroupreferrlcode">
                        <Form.Control
                          placeholder="Referral (KBDHNP)"
                          aria-label="Referral Code"
                          aria-describedby="basic-addon2"
                          value={AuthData.referral_link}
                        />
                        <InputGroup.Text id="basic-addon2" className='copyreferrllink' onClick={() => copyTorefrrallink(AuthData.referral_link)}><MdOutlineContentCopy fill='#fff'/></InputGroup.Text>
                        <InputGroup.Text id="basic-addon2" className='sharereferrllink' onClick={() => copyToClipboard(AuthData.referral_link)}><MdShare fill='#fff'/></InputGroup.Text>
                      </InputGroup>

                      <div className="mt-4">
                      <button className='verify_email'
                          onClick={() => navigate("/referral-users")}
                        >
                          Referred Users
                        </button>
                      </div>
                    </div>
                    <div className='col-lg-4 d-flex justify-content-lg-end'>
                      <div className="mt-2">
                        <h1 className="user_Detailspage_About">QR Code</h1>
                        <div className='d-flex align-items-center'>
                        <img src={AuthData?.qrCode ? AuthData?.qrCode : qrcodeimg } alt="img" className='setqrcodeimgwidth mr-2'/>
                        <div>
                          <a className='qrcodeshareoption'   
                            onClick={(e) => { e.preventDefault(); download(AuthData?.qrCode); }}>
                            <IoMdDownload fill='#fff'/>
                            </a>
                          <div className='qrcodeshareoption mt-4'
                          onClick={() => 
                              handleButtonClick(AuthData.qrCode)}
                              >
                          <MdShare fill='#fff' />
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          <FooterMobile />
        </div>
        <Footer />
      </div>

      <Modal
        show={unableModalShare}
        onHide={handleshowunablehidemodal}
        centered
      >
        <div className="bg-white login_locatione_main_section">
          <div className="text-right login_locatione_close_main">
            <div className="pl-3">
              <p className="mb-0 login_locatione_title">PLEASE CHECK</p>
            </div>
            <div className="pr-2">
              <IoClose onClick={handleshowunablehidemodal} className="cursor_pointer_common login_locatione_close" />
            </div>
          </div>
          <Modal.Body>
            <p className="location_dis">Please check if your browser supports web share. If so, enable web share option from your browser settings</p>
            <div className="text-right">
              <button className="login_locatione_btn" onClick={handleshowunablehidemodal}>Ok</button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  )
}


export default Referraldashbord