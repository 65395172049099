import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { FaCamera } from 'react-icons/fa';
import { reportComment } from '../../../api/Api';
import { Store } from 'react-notifications-component';

const ReportComment = ({ show, onHide, comment_id }) => {
    const [reason, setReason] = useState('');
    const [validated, setValidated] = useState(true);
    const [image, setImage] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleReport = async () => {
        if (reason.trim() === '') {
            setValidated(false);
            return;
        }
        setValidated(true);
        setIsSubmitting(true);

        const formData = new FormData();
        formData.append('reportMessage', reason);
        formData.append('comment_id', comment_id);
        if (image) {
            formData.append('image', image);
        }
        reportComment(formData)
            .then(function (response) {
                console.log(response);
                if (!response.data.status) {
                    Store.addNotification({
                      message: response.data.message,
                      type: "danger",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animate__animated", "animate__zoomIn"],
                      animationOut: ["animate__animated", "animate__fadeOut"],
                      dismiss: {
                        duration: 2000,
                        onScreen: false,
                      },
                    });
                    return false;
                  } else {
                    Store.addNotification({
                      message: response.data.message,
                      type: "success",
                      insert: "top",
                      container: "top-right",
                      animationIn: ["animate__animated", "animate__zoomIn"],
                      animationOut: ["animate__animated", "animate__fadeOut"],
                      dismiss: {
                        duration: 2000,
                        onScreen: false,
                      },
                    });
                  }
                handleClose();
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function (){
                setIsSubmitting(false);
            });
    };

    const handleClose = () => {
        setReason('');
        setImage(null);
        setValidated(true); // Clear validation error
        onHide();
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(file);
        }
    };

    return (
        <>
            <style>
                {`
                .report-modal-body {
                    background-color: white;
                    border-radius: 8px;
                    padding: 20px;
                }
                
                .report-title {
                    text-align: left;
                    font-size: 18px;
                    font-weight: bold;
                    margin-bottom: 20px;
                }
                
                .upload-container {
                    
                       text-align: left;
                        justify-content: center;
                        margin-bottom: 10px;
                        display: flex;
                }
                
                .upload-btn {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    background-color: #ffCBD8;
                    color: #FF5777;
                    font-weight: bold;
                    padding: 8px 12px;
                    border-radius: 8px;
                    cursor: pointer;
                    width: fit-content;
                }
                
                .upload-icon {
                    margin-right: 10px;
                }
                
                .image-name {
                    color: #FF5777;
                    font-weight: bold;
                    margin-top: 5px;
                }
                
                .report-label {
                    font-weight: bold;
                }
                
                .report-textarea {
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    padding: 8px;
                    width: 100%;
                }
                
                .error {
                    border-color: #FF5777 !important;
                    box-shadow: 0 0 5px rgba(255, 87, 119, 0.5);
                }
                
                .error-text {
                    color: #FF5777;
                    font-size: 12px;
                }
                
                .btn-container {
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 20px;
                }
                
                .cancel-btn {
                    border-color: #FF5777;
                    color: #FF5777;
                    margin-right: 10px;
                }
                
                .cancel-btn:hover,
                .cancel-btn:focus {
                    background-color: transparent !important;
                    color: #FF5777 !important;
                    border-color: #FF5777 !important;
                }
                
                .report-btn {
                    background-color: #FF5777;
                    border-color: #FF5777;
                    color: white;
                }
                
                .report-btn:hover,
                .report-btn:focus {
                    background-color: #FF5777 !important;
                    border-color: #FF5777 !important;
                    color: white !important;
                }
                `}
            </style>

            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Body className="report-modal-body">
                    <h4 className="report-title text-center">Are you sure you want to report this reply?</h4>

                    {/* Image Upload */}
                    <div className="upload-container">
                        <label htmlFor="upload-image" className="upload-btn">
                            <FaCamera className="upload-icon" />
                            <span>{image ? image.name : "Upload Image"}</span>
                        </label>
                        <input
  id="upload-image"
  type="file"
  accept="image/jpeg, image/png, image/jpg"
  onChange={handleImageUpload}
  hidden
/>

                    </div>

                    {/* Reason for Report */}
                    <Form.Group controlId="reportReason">
                        <Form.Label className="report-label">Reason for Report</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Enter your reason..."
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            className={`report-textarea ${validated ? '' : 'error'}`}
                        />
                        {!validated && <Form.Text className="error-text">This field is required.</Form.Text>}
                    </Form.Group>

                    {/* Buttons aligned to right */}
                    <div className="btn-container">
                        <Button onClick={handleClose} variant="outline-light" className="cancel-btn" disabled={isSubmitting}>
                            Cancel
                        </Button>
                        <Button onClick={handleReport} className="report-btn" disabled={isSubmitting}>
                            {isSubmitting ? 'Reporting...' : 'Report'}
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ReportComment;
