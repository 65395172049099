import React, { useEffect } from "react";
import Header from "../CommonComponet/Header";
import Footer from "../CommonComponet/Footer";
import { useAuth } from '../../middleware/AuthProvider'
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSocket } from "../../middleware/SocketProvider";

const Blocked = () => {
  const { AuthData, siteSetting, setAuthData } = useAuth();
  const { Socket } = useSocket();
  const navigate = useNavigate();

  useEffect(() => {
    if (!AuthData.is_block) {
      navigate("/home")
    }
    document.title = "Blocked Users On Hukup Australia"
    if(Socket) {
      Socket.on('blocked', (blockData) => {
        setAuthData({ ...AuthData, is_block: blockData.status })
      })
  
      Socket.on('deleted', () => {
        var data = localStorage.getItem("locatione_allowed")
        localStorage.clear();
        localStorage.setItem("locatione_allowed", data);
        window.location.href = '/';
      })
  
      Socket.on('logout_user', () => {
        var data = localStorage.getItem("locatione_allowed")
        localStorage.clear();
        localStorage.setItem("locatione_allowed", data);
        window.location.href = '/';
      })
    }

    return () => {
      if(Socket) {
        Socket.off("blocked");
        Socket.off("deleted");
        Socket.off("logout_user");
      }
    };
  }, [])

  return (
    <>
      <Helmet>
        <meta name="description" content={"Blocked Users On Hukup Australia"} />
      </Helmet>

      <div className="container-fluid p-0 main_section">
        <div className="background_section_main">
          <div className="yellow_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="orange_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <img
            src={require("../../../src/images/Whitedot.png")}
            alt="Whitedot"
            className="d-none d-sm-none d-md-none d-lg-block Whitedot_first"
          />
          <img
            src={require("../../../src/images/Whitedot.png")}
            alt="Whitedot"
            className="d-none d-sm-none d-md-none d-lg-block Whitedot_second"
          />
          <img
            src={require("../../../src/images/orangeline.png")}
            alt="orangeline"
            className="d-none d-sm-none d-md-none d-lg-block footer_orangeline"
          />
          <img
            src={require("../../../src/images/greenline.png")}
            alt="greenline"
            className="d-none d-sm-none d-md-none d-lg-block footer_greenline"
          />
          <img
            src={require("../../../src/images/Ellipse.png")}
            alt="Ellipse"
            className="footer_ellipse_img"
          />
          <img
            src={require("../../../src/images/footerblackline.png")}
            alt="footerblackline"
            className="footer_blackline_img"
          />
        </div>
        <Header />

        <div className="container content_main">
          <div className="row">
            <div className="col-12 Blocked_User_section_contain">
              <div className="">
                <div className="mt-5">
                  <p>Hi {AuthData.name},</p>

                  <p>We hope this message finds you well. We regret to inform you that your account on {siteSetting?.siteSetting?.site_name} has been temporarily blocked by our admin team due to some recent activity.</p>

                  <p>For assistance or to discuss this further, To create support ticket <a target="_blank" href="https://blog.hukup.net/create-new-ticket/">click</a> here.</p>

                  <p>Thank you for your understanding.</p>

                  <p>Best regards,</p>
                  <p>{siteSetting?.siteSetting?.site_name}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Blocked;
