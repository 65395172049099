import React, { useEffect, useState } from "react";
import Header from "../CommonComponet/Header";
import Footer from "../CommonComponet/Footer";
import { useAuth } from '../../middleware/AuthProvider'
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import VerificationCard from "./Setting/VerificationCard";
import DataLoader from "../../components/ScreenLoader/DataLoader";

const Profileverification = () => {
  const { AuthData } = useAuth();
   const [isDataLoader, setisDataLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!AuthData.is_profile_verification_required) {
      navigate("/home")
    }
    document.title = "Hukup Australia"
  }, [])

  return (
    <>
      <Helmet>
        <meta name="description" content={"Hukup Australia"} />
      </Helmet>

      {isDataLoader && (
          <DataLoader />
      )}

      <div className="container-fluid p-0 main_section">
        <div className="background_section_main">
          <div className="yellow_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="orange_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <img
            src={require("../../../src/images/Whitedot.png")}
            alt="Whitedot"
            className="d-none d-sm-none d-md-none d-lg-block Whitedot_first"
          />
          <img
            src={require("../../../src/images/Whitedot.png")}
            alt="Whitedot"
            className="d-none d-sm-none d-md-none d-lg-block Whitedot_second"
          />
          <img
            src={require("../../../src/images/orangeline.png")}
            alt="orangeline"
            className="d-none d-sm-none d-md-none d-lg-block footer_orangeline"
          />
          <img
            src={require("../../../src/images/greenline.png")}
            alt="greenline"
            className="d-none d-sm-none d-md-none d-lg-block footer_greenline"
          />
          <img
            src={require("../../../src/images/Ellipse.png")}
            alt="Ellipse"
            className="footer_ellipse_img"
          />
          <img
            src={require("../../../src/images/footerblackline.png")}
            alt="footerblackline"
            className="footer_blackline_img"
          />
        </div>
        <Header />

        <div className="container content_main">
          <div className="row">
            <div className="col-12 Blocked_User_section_contain">
              <VerificationCard setisDataLoader={setisDataLoader}/>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Profileverification;
