import React, { useEffect, useRef, useState } from "react";
import Header from "../../CommonComponet/Header";
import Footer from "../../CommonComponet/Footer";
import AccountSettingsidebar from "./AccountSettingsidebar";
import FooterMobile from "../../CommonComponet/FooterMobile";
import { Helmet } from "react-helmet";
import VerificationCard from "./VerificationCard";
import RespoAccSettingsidebar from "./RespoAccSettingsidebar";
import DataLoader from "../../../components/ScreenLoader/DataLoader";

const Verification = () => {
  const [isDataLoader, setisDataLoader] = useState(false);

    useEffect(() => {
          document.title = "Verification Page On Hukup Australia"
    }, [])


    return(
       <>
         <Helmet>
        <meta name="description" content={"Private Me On Hukup Australia"} />
      </Helmet>
      {isDataLoader && (
          <DataLoader />
      )}
      <div className="container-fluid p-0 main_section">
        <div className="background_section_main">
          <div className="green_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="orange_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <img
            alt="smileemoji"
            src={require("../../../../src/images/Visitors/smileemoji.png")}
            className="visiter_smileemoji_img d-none d-sm-none d-md-none d-lg-block"
          />
          <img
            alt="CurveBlackline"
            src={require("../../../../src/images/Visitors/CurveBlackline.png")}
            className="CurveBlackline_img d-none d-sm-none d-md-none d-lg-block"
          />
          <img
            alt="orangeline"
            src={require("../../../../src/images/orangeline.png")}
            className="d-none d-sm-none d-md-none d-lg-block footer_orangeline"
          />
          <img
            alt="greenline"
            src={require("../../../../src/images/greenline.png")}
            className="d-none d-sm-none d-md-none d-lg-block footer_greenline"
          />
          <img
            alt="Ellipse"
            src={require("../../../../src/images/Ellipse.png")}
            className="footer_ellipse_img"
          />
          <img
            alt="footerblackline"
            src={require("../../../../src/images/footerblackline.png")}
            className="footer_blackline_img"
          />
        </div>
        <Header />
        <div className="container-fluid content_main px-0">

          <div className="Notifications_main_section">
            <div className="container">
              <div className="row">
                <div className="col-3  Settingnotifications_siderbar_main pl-lg-3 pl-xl-4 d-none d-lg-block ">
                  <AccountSettingsidebar />
                </div>

                {/*. === second section =====  */}
                <div className="col-12 col-lg-8 offset-0 offset-lg-1  ">

                <div className="d-flex justify-content-between align-items-center my-3 d-block d-lg-none ">
                    <div>
                    </div>
                    <div>
                    <RespoAccSettingsidebar />
                    </div>
                </div>
                 <VerificationCard setisDataLoader={setisDataLoader}/>
                </div>
              </div>
            </div>
          </div>
          <FooterMobile />
        </div>
        <Footer />
      </div>
       </>
    )
}
export default Verification;