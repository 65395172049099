import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { AddVerification, GetVerification } from "../../../api/Api";
import { Store } from 'react-notifications-component';
import { useAuth } from "../../../middleware/AuthProvider";
import { formatDate } from "../../../helper/getCookie";
import { Link } from "react-router-dom";
import Calendar from "../../../images/UserDetailspage/Calendar.png";
import { useSocket } from "../../../middleware/SocketProvider";
import DataLoader from "../../../components/ScreenLoader/DataLoader";

const VerificationCard = (props) => {
  const { Socket } = useSocket();
  const { AuthData, siteSetting } = useAuth();
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [imageSrc, setImageSrc] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [capturePhoto, setCapturePhoto] = useState(false);
  const [videoCameraShow, setVideoCameraShow] = useState(false);
  const [uploadSelfie, setUploadSelfie] = useState(false);
  const [selfiePhoto, setSelfiePhoto] = useState("");
  const [uploadDataShow, setUploadDataShow] = useState(false);
  const [selfiePhotoButton, setSelfiePhotoButton] = useState(true);
  const [verificationData, setVerificationData] = useState(null);

  const startCamera = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert("Camera is not supported on this device or browser.");
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "user" },
        audio: false,
      });
      setVideoCameraShow(true);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        setCapturePhoto(true);
      }
    } catch (error) {
      setVideoCameraShow(false);
      console.error("Error accessing the camera:", error);
      alert("Failed to access the camera. Please check your permissions or device settings.");
    }
  };

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      const tracks = stream.getTracks(); // Get all tracks
      tracks.forEach((track) => track.stop()); // Stop each track
      videoRef.current.srcObject = null; // Clear the video source
    }
    setSelfiePhotoButton(true)
    setVideoCameraShow(false); // Hide the video camera
    setCapturePhoto(false); // Reset the capture state
    setImageSrc("")
  };

  // Capture a photo
  const takePhoto = () => {
    setSelfiePhotoButton(false);
    const video = videoRef.current;
    const canvas = canvasRef.current;

    if (video && canvas) {
      const context = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Draw the current video frame on the canvas
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Convert the canvas content to an image URL
      const imageData = canvas.toDataURL("image/png");
      setImageSrc(imageData);
      if (videoRef.current && videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject;
        const tracks = stream.getTracks(); // Get all tracks
        tracks.forEach((track) => track.stop()); // Stop each track
        videoRef.current.srcObject = null; // Clear the video source
      }
      setVideoCameraShow(false); // Hide the video camera
      setCapturePhoto(false); // Reset the capture state
    }
  };


  useEffect(() => {
    VerificationViewApi();
    if(Socket) {
      Socket.off("is_profile_verification_rejected");
      Socket.on("is_profile_verification_rejected", () => {
        VerificationViewApi();
      });
      Socket.off("is_profile_verification_approved");
      Socket.on("is_profile_verification_approved", () => {
        VerificationViewApi();
      });
    }
    return () => {
      Socket.off("is_profile_verification_rejected");
      Socket.off("is_profile_verification_approved");
    }
  }, [])

  const VerificationViewApi = () => {
    setCapturePhoto(false);
    setVideoCameraShow(false);
    setSelfiePhoto("");
    setUploadDataShow(false);
    setUploadSelfie(false);
    setSelfiePhotoButton(false);
    setIsLoading(true);
    GetVerification().then(function (response) {
      setVerificationData(response.data.data)
      if (!response.data.data) {
        setUploadSelfie(true)
        setSelfiePhotoButton(true);
      } else if (response.data.data.status == "approved" && AuthData.is_profile_verification_required) {
        setUploadSelfie(true);
        setSelfiePhotoButton(true);
      } else if (response.data.data.status == "rejected") {
        setUploadSelfie(true);
        setUploadDataShow(true);
        setSelfiePhotoButton(true);
        setSelfiePhoto(response.data.data.image);
      } else {
        setUploadSelfie(false);
        setUploadDataShow(true);
        setSelfiePhoto(response.data.data.image);
      }
      setIsLoading(false);
    })
      .catch(function (error) {
        setIsLoading(false);
        console.log(error);
      });
  }


  const dataURLToBlob = (dataURL) => {
    const [header, base64Data] = dataURL.split(",");
    const mimeMatch = header.match(/:(.*?);/);
    const mime = mimeMatch ? mimeMatch[1] : "image/png";
    const binary = atob(base64Data);
    const array = [];

    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }

    return new Blob([new Uint8Array(array)], { type: mime });
  };

  const AddUploadVerification = () => {
    props.setisDataLoader(true)
    if (imageSrc) {
      const blob = dataURLToBlob(imageSrc);
      var formData = new FormData();
      formData.append("image", blob, "photo.png");
    }
    AddVerification(formData).then(function (response) {
      props.setisDataLoader(false);
      if (!response.data.status) {
        Store.addNotification({
          message: response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
        return false
      }
      VerificationViewApi();
    })
      .catch(function (error) {
        props.setisDataLoader(false);
      });
  }

  return (
    <>
      {isLoading ? (
        <>
          <DataLoader />
        </>
      ) : (
        <>
          {AuthData.is_profile_verification_required == 1 && (
            <div className="mt-5">
              {
                !verificationData ? (
                  <>
                    <p>Hi {AuthData.name},</p>
                    <p>Please verify your profile by uploading a selfie to unlock access to all features.</p>
                  </>
                ) : (
                  <>
                    {verificationData?.status === "approved" && (
                      <>
                        <p>Hi {AuthData.name},</p>
                        <p>Please verify your profile by uploading a selfie to unlock access to all features.</p>
                      </>
                    )}
                    {verificationData?.status === "pending" && (
                      <>
                        <p>Hi {AuthData.name},</p>
                        <p>Your profile verification is submitted, After admin verifying your profile, you will be able to unlock access to all features.</p>
                      </>
                    )}
                    {verificationData?.status === "rejected" && (
                      <>
                        <p>Hi {AuthData.name},</p>
                        <p>Your profile verification is rejected. you need to upload the selfie again. After verifying your profile, you will be able to unlock access to all features.</p>
                      </>
                    )}
                  </>
                )
              }
    
              <p>For assistance or to discuss this further, To create support ticket <a target="_blank" href="https://blog.hukup.net/create-new-ticket/">click</a> here.</p>
    
              <p>Thank you for your understanding.</p>
    
              <p>Best regards,</p>
              <p>{siteSetting?.siteSetting?.site_name}</p>
            </div>
          )}
          <div className="text-center  mt-5 mb-4">
            <h1 className="Settingnotifications_Setting_title">
              Verification
            </h1>
          </div>
          {
            !AuthData.is_profile_verification_required && (
              <Row>
                <Col className="col-12 col-md-8 col-lg-6 mx-auto text-center">
                  {verificationData?.status === "approved" ? (
                    <p>Your profile is verified.</p>
                  ) : verificationData?.status === "rejected" ? (
                    <p>Your verification request is rejected by admin, you need to upload the selfie again.</p>
                  ) : verificationData?.status === "pending" ? (
                    <p>Your verification request is pending, After admin verifying your profile, verification mark (✔) is display in your profile.</p>
                  ) : (
                    <p>Upload a selfie to display verification mark (✔) and gain credibility with other members.</p>
                  )}
                </Col>
              </Row>
            )
          }
    
          {uploadSelfie && (
            <Row>
              <Col className="col-12 col-md-8 col-lg-6 mx-auto">
    
    
                <div className="Settingnotifications_Allow_notification_main">
    
                  {selfiePhotoButton && (
                    <div className="my-2 text-center">
                      <Button className="Userdetails_modal_btn_Report_user" onClick={startCamera}>Upload Selfie</Button>
                    </div>
                  )}
                  {
                    videoCameraShow && (
                      <video ref={videoRef} autoPlay playsInline className="capture_photo" />
                    )
                  }
                  {
                    capturePhoto && (
                      <div className="text-center">
                        <Button className="Userdetails_modal_btn_Report_user" onClick={takePhoto}>Capture Photo</Button>
                      </div>
                    )
                  }
                  <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
                  {imageSrc && (
                    <div>
                      <h4 className="Settingnotifications_E_mail_Privacy mt-2">Captured Photo:</h4>
                      <div className="capture_photo text-center">
                        <img
                          src={imageSrc}
                          alt="Captured"
                          className="capture_img"
                        />
                      </div>
                      <div className="d-flex justify-content-between align-items-center gap-4 my-2">
                        <Button className="Userdetails_modal_btn_Report_user" onClick={() => AddUploadVerification()}>Upload</Button>
                        <Button className="Userdetails_modal_btn_Report_user" onClick={stopCamera}>Cancel</Button>
                      </div>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          )}
          {uploadDataShow && (
            <Row className={`${uploadSelfie ? "mt-4" : ""}`}>
              <Col className="col-12 col-md-8 col-lg-6 mx-auto">
                {
                  verificationData && (
                    <>
                      <div className="user_image_model">
                        <div className="user_post_header verification">
                          <div className="user_header_main">
                            <Link to={"/my-profile"}>
                              <img alt="Profile" src={AuthData.image} className="profile_model_image"></img>
                            </Link>
                            <div>
                              <div className="user_image_name">
                                <Link to={"/my-profile"}>
                                  {AuthData.name}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="p-0 gallery_modal_body_main rounded-0">
                          <div className="user_modal_main_section verification">
                            <img alt="Gallary" src={selfiePhoto} className="user_model_post verification"></img>
                          </div>
                        </div>
                        <div className="user_post_footer verification">
                          <div className="user_image_model_footer verification d-flex align-items-center justify-content-center">
                            <img alt="Calander" src={Calendar} className="icon_heart_red" />
                            <span className="mt-1 gallery_ima_modal_footer">
                              {formatDate(new Date(verificationData.created_at))}
                            </span>
                          </div> 
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <h4 className="Settingnotifications_label_name text-dark mt-2 text-end">Status:</h4>
                        <div className="ms-2 Settingnotifications_label_name text-dark">
                          {verificationData?.status == "approved" ? "Approved" : verificationData?.status == "rejected" ? "Rejected" : "pending"}</div>
                      </div>
                    </>
                  )
                }
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  )
}
export default VerificationCard;