import React, { useEffect, useRef, useState } from "react";
import Header from "../CommonComponet/Header";
import Footer from "../CommonComponet/Footer";
import { useAuth } from "../../middleware/AuthProvider";
import {
  AddComment,
  GetCommentList,
  GetEventById,
} from "../../api/Api";
import FooterMobile from "../CommonComponet/FooterMobile";
import { Helmet } from "react-helmet";
import {
  Button,
  Col,
  Form,
  Modal,
  Row
} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { Store } from "react-notifications-component";
import { MdOutlineOutlinedFlag } from "react-icons/md";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import { CiCalendar, CiLocationOn } from "react-icons/ci";
import ReportComment from "./ReportComment/ReportComment";
import sendmsg from "../../images/Vector 1.svg";
import verifyIcon from '../../images/verified-icon.png';

const EventsPage = () => {

  const divRef = useRef(null);
  const [allEventList, setAllEventList] = useState([]);
  const [cardSkeleton, setCardSkeleton] = useState(true);
  const { id } = useParams();
  useEffect(() => {
    document.title = "EVENTS and PARTIES On Hukup Australia";
    getEventDetailAPI();

  }, []);

  const getEventDetailAPI = () => {

    GetEventById(id)
      .then(function (response) {
        setCardSkeleton(false);
        setAllEventList([response.data.data]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content={"EVENTS and PARTIES On Hukup Australia"}
        />
      </Helmet>

      <div className="container-fluid p-0 main_section">
        <div className="background_section_main">
          <div className="yellow_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="orange_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <img
            alt="Whitedot"
            src={require("../../../src/images/Whitedot.png")}
            className="d-none d-sm-none d-md-none d-lg-block Whitedot_first"
          />
          <img
            alt="Whitedot"
            src={require("../../../src/images/Whitedot.png")}
            className="d-none d-sm-none d-md-none d-lg-block Whitedot_second"
          />
          <img
            alt="orangeline"
            src={require("../../../src/images/orangeline.png")}
            className="d-none d-sm-none d-md-none d-lg-block footer_orangeline"
          />
          <img
            alt="greenline"
            src={require("../../../src/images/greenline.png")}
            className="d-none d-sm-none d-md-none d-lg-block footer_greenline"
          />
          <img
            alt="Ellipse"
            src={require("../../../src/images/Ellipse.png")}
            className="footer_ellipse_img"
          />
          <img
            alt="footerblackline"
            src={require("../../../src/images/footerblackline.png")}
            className="footer_blackline_img"
          />
        </div>
        <Header />
        <div className="container-fluid content_main px-0">
          <div className="container">
            <div className="row">
              <div className="col-12 Blocked_User_section_contain">
                <div className="row  ">
                  <div className="col-12 Liked_section_slider event" ref={divRef}>
                    {cardSkeleton && (
                      <SkeletonTheme>
                        <Row className="">
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                            (ele) => {
                              return (
                                <Col xs={12} md={12} lg={12} xl={12}>
                                  <Skeleton height={200} count={1} />
                                </Col>
                              );
                            }
                          )}
                        </Row>
                      </SkeletonTheme>
                    )}
                    <div className="row row-cols-12 row-cols-lg-12 row-cols-md-12 row-cols-xl-13 px-0 px-sm-0 px-md-0 px-lg-0 px-xl-0">
                      {allEventList?.map((item, index) => {
                        return (
                          <ProfileCard
                            item={item}
                            index={index}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterMobile />
        </div>
        <Footer />
      </div>

    </>
  );
};
export default EventsPage;

const ProfileCard = (props) => {
  const navigate = useNavigate();
  const [commentMessageList, SetCommentMessageList] = useState([]);
  const [commentMessage, SetCommentMessage] = useState("");
  const [commentMessageError, setcommentMessageError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();


  const CommentListGet = () => {
    GetCommentList(id)
      .then(function (response) {
        SetCommentMessageList(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (id && props?.item?.status == "approved") {
      CommentListGet();
    }
  }, [id]);

  const Errorclear = (e) => {
    setcommentMessageError("");
  };

  const validationComment = () => {
    Errorclear();
    var isvalid = true;
    if (
      commentMessage == "" ||
      !commentMessage ||
      commentMessage.trim() == ""
    ) {
      setcommentMessageError("Reply is required");
      isvalid = false;
    }
    return isvalid;
  };

  const AddMessage = () => {
    if (!validationComment() || isLoading) {
      return false;
    }
    setIsLoading(true);
    var data = {
      event_id: id,
      comment: commentMessage,
    };
    AddComment(data)
      .then(function (response) {
        SetCommentMessage("");
        if (!response.data.status) {
          Store.addNotification({
            message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false,
            },
          });
          return false;
        }
        CommentListGet();
        setIsLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="col px-0 mt-3">
        <div className="linkover event-card-main-event-details-div">
          <div className="">
            
            <div className="event_card_img_height">
              <img
                src={props?.item?.image}
                className="card_background_img text-black img-fluid event_card_border_radius"
                alt="Profile"
              />
            </div>
            <div className=" ms-3 mt-3">
              <h4 className="my-0 event_name fw-bold h3 text-black">{props?.item?.name}</h4>
              {props?.item?.type == "Party" && (
                <div className="mb-2 mt-1">
                  <div className="event_name_modal event_name_modal-event-details cursor_pointer_common d-flex" onClick={() => navigate("/user/" + props?.item?.user_name)}>
                    <span className="fw-bold">Organizer:-</span>
                    <span className="d-flex align-items-center">
                      {props?.item?.fullName}
                      {props?.item?.userProfileVerify === 1 && <img alt='Verify' src={verifyIcon} className='user_verify-icon-home'></img>}
                    </span>
                  </div>
                </div>
              )}
              <div className="mb-2 mt-1">
                <div className="event_name_modal event_name_modal-event-details"><span className="fw-bold">Event Types:-</span> {props?.item?.type}</div>
              </div>
              <div className="mb-2 mt-1">
                <div className="event_name_modal event_name_modal-event-details"><span className="fw-bold">Tags:-</span> {props?.item?.tags}</div>
              </div>
              {props?.item?.type !== "Cruise" && props?.item?.type !== "Venue" && (
                <p className="my-0 event_location-event-details mt-1 fw-bold">
                  <CiCalendar />
                  <span className="event_location_color ms-1">
                    {`${new Date(props?.item?.date).toLocaleDateString('en-US', {
                      weekday: 'short'
                    })}, ${new Date(props?.item?.date).getDate()} ${new Date(props?.item?.date).toLocaleDateString('en-US', {
                      month: 'long'
                    })}, ${new Date(`2000-01-01 ${props?.item?.time}`).toLocaleTimeString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true
                    })}`}
                  </span>
                </p>
              )}

              <p className="my-0 event_location-event-details mt-1">
                <CiLocationOn />
                <span className="event_location_color ">
                  <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(props?.item?.address)}`} target="_blank" rel="noopener noreferrer">
                    {props?.item?.address}
                  </a>
                </span>
              </p>
              <b><p className="text-black mt-2">Description</p></b>
              <div className="my-0 text-black mt-1" dangerouslySetInnerHTML={{ __html: props?.item?.description }} />
              
            </div>
          </div>
          {props?.item?.status == "approved" && (
            <>
              <div className="my-2">
                <hr />
                <Row className="sticky-css">
                  <Col xs={12} className="d-flex position-relative">
                    <div className="w-100">
                      <input
                        value={commentMessage}
                        onChange={(e) => SetCommentMessage(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key == 'Enter') {
                            AddMessage();
                          }
                        }}
                        autocapitalize="sentences"
                        name="message"
                        type="text"
                        placeholder="Add Reply..."
                        className="form-control comment_input"
                      />
                    </div>
                    <button
                      className="send_msg_img"
                    >
                      <img alt="Send Message" onClick={AddMessage} src={sendmsg} className="send_msg_button h-100"></img>
                    </button>
                  </Col>
                  {/* <Col md={10}>
                    <Form.Control
                      type="text"
                      placeholder="Type Reply"
                      value={commentMessage}
                      onChange={(e) => SetCommentMessage(e.target.value)}
                      className="comment_input"
                    />
                    <p className="text-start errorClass mb-1">{commentMessageError}</p>
                  </Col>
                  <Col md={2} sm={6} className="text-center d-flex justify-content-center">
                    <Button
                      className="Userdetails_modal_btn_Cancel"
                      onClick={() => AddMessage()}
                    >
                      Send
                    </Button>
                  </Col> */}
                </Row>

              </div>

              <div className="preMessageBox-event-details">
                {commentMessageList?.length > 0 ? (
                  commentMessageList?.map((e, index) => {
                    return <CommentMessageListComponent data={e} key={index} />;
                  })
                ) : (
                  <div className="text-center d-flex justify-content-center align-items-center h-100 text-black">No reply</div>
                )}
              </div>
            </>
          )}

        </div>
      </div>
    </>
  );
};

const CommentMessageListComponent = ({ data }) => {
  const { AuthData } = useAuth();
  const [modalShow, setModalShow] = React.useState(false);
  const navigate = useNavigate();

  return (
    <>
      <div className="row border-bottom border-black mb-3">
        <div className="col-6 text-dark">
          <div className="d-flex">
            <div className=" text-black">
              <div className="cursor_pointer_common" onClick={() => navigate("/user/" + data?.user_name)}>
                <img src={data?.user_image || "https://www.datingguide.com.au/images-SponsoredAds/2587.jpg"} className="rounded-pill comment-user-image" />
                <span>
                  {data?.fullName}
                  {data.userProfileVerify === 1 && <img alt='Verify' src={verifyIcon} className='user_verify-icon-home'></img>}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6 text-dark text-end">
          {(() => {
            const commentTime = new Date(data?.created_at)
            const now = new Date()
            const diffMs = now - commentTime
            const diffMins = Math.floor(diffMs / 60000)
            const diffHours = Math.floor(diffMs / 3600000)
            const diffDays = Math.floor(diffMs / 86400000)
            const diffWeeks = Math.floor(diffDays / 7)

            if (diffMins < 1) {
              return 'Just Now'
            } else if (diffMins < 60) {
              return `${diffMins} minutes ago`
            } else if (diffHours < 24) {
              return `${diffHours} hours ago`
            } else if (diffDays < 7) {
              return `${diffDays} days ago`
            } else {
              return `${diffWeeks} weeks ago`
            }
          })()}
        </div>
        <div className="col-9">
          <div className="text-break-comment-event-details w-100 fw-bold p-0 mt-2 mb-2 ms-2">
            {data?.comment_text}
          </div>
        </div>
        <div className="col-3 text-dark text-end">
          {AuthData.id !== data.userId && (
            <div className="text-black cursor_pointer_common">
              <MdOutlineOutlinedFlag size={20} onClick={() => setModalShow(true)} />
            </div>
          )}
        </div>
      </div>
      <ReportComment
        show={modalShow}
        onHide={() => setModalShow(false)}
        comment_id={data.id}
      />
    </>
  );
};