import Cookies from "js-cookie";

export const getCookies = () => {
  const Token = Cookies.get("Hukup_Token");
  return Token;
};

export function timeAgo(isoTimestamp) {
  if(isoTimestamp == null) return ""
  const time = new Date(isoTimestamp);
  const now = new Date();
  const diffInSeconds = Math.floor((now - time) / 1000);

  const intervals = [
      { label: "year", seconds: 31536000 },
      { label: "month", seconds: 2592000 },
      { label: "week", seconds: 604800 },
      { label: "day", seconds: 86400 },
      { label: "hr", seconds: 3600 },
      { label: "min", seconds: 60 },
      // { label: "sec", seconds: 1 },
  ];

  for (const interval of intervals) {
      const count = Math.floor(diffInSeconds / interval.seconds);
      if (count >= 1) {
          return `${count} ${interval.label} ago`;
      }
  }

  return "just now";
}

export function formatDate(date) {
  const options = { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
  const formattedDate = date.toLocaleString('en-GB', options).replace(',', '');
  
  const day = date.getDate();
  const suffix = (day % 10 === 1 && day !== 11) ? 'st' :
                 (day % 10 === 2 && day !== 12) ? 'nd' :
                 (day % 10 === 3 && day !== 13) ? 'rd' : 'th';

  return formattedDate.replace(/\d{1,2}/, `${day}${suffix}`);
};

