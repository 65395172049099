import React, { useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import "../LoginandReg/LoginPage.css";
import gay from "../../images/Loginpage/1.gay.png";
import user from "../../images/logo.png";
import lesbian from "../../images/Loginpage/2.lesbian.png";
import bisexual from "../../images/Loginpage/3.bisexual.png";
import transgender from "../../images/Loginpage/4.transgender.png";
import roundimg from "../../images/Loginpage/Avatar.png"
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import layer from "../../images/Loginpage/Rectangle 4.png"
import ReactInputVerificationCode from "react-input-verification-code";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CountrycodeApi, LogInSendOtpApi, LoginApi, LoginPageLinksApi } from "../../api/Api";
import { useAuth } from "../../middleware/AuthProvider";
import { useSocket } from "../../middleware/SocketProvider";
import { browserName } from 'react-device-detect';
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import DataLoader from "../../components/ScreenLoader/DataLoader";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { Helmet } from "react-helmet";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import HeaderSecond from "../CommonComponet/HeaderSecond";


const Loginpage = () => {

  const { setAuthData, siteSetting } = useAuth();
  const { connectSocket } = useSocket();
  const [isDataLoader, setisDataLoader] = useState(false);
  const [disableLoginBtn, setDisableLoginBtn] = useState(false);
  const [genderIcon, SetGenderIcon] = useState([gay, lesbian, bisexual, transgender])
  const [step, setStep] = useState(0)
  const [number, setNumber] = useState("")
  const [numberError, setNumberError] = useState('');
  const [countryId, setCountryId] = useState("")
  const [countryData, setCountryData] = useState([])
  const [countrcode, setCountrcode] = useState("")

  const [pageLinks, setPageLinks] = useState([])
  const [verificationCode, setVerificationCode] = useState("")
  const [termandconditions, setTermandconditions] = useState(false)
  const [termandconditionsError, setTermandconditionsError] = useState("")
  const [referralCode, setReferralCode] = useState("");

  // const [loginModalLocatione, setLoginModalLocatione] = useState(false);

  const [loginwith, setLoginwith] = useState("")
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")
  const [detectdevice, setDetectdevice] = useState("")
  const location = useLocation();


  const navigate = useNavigate();

  useEffect(() => {
    if (verificationCode != "" & verificationCode.indexOf("·") == -1) {
      ValidateOtp();
    }
  }, [verificationCode])

  useEffect(() => {
    AOS.init({ duration: 2500 });
    CountrycodeApiCall();
    loginPageLinks();
    document.title = "Hukup Australia";
  }, []);


  const firstVisibleDigits = 6;
  const lastVisibleDigits = 4;
  const maskedNumber = '*'.repeat(firstVisibleDigits) + number.substring(number.length - lastVisibleDigits);

  const validationnumber = () => {
    Errorclearnumber();
    var isvalid = true
    if (number == "") {
      setNumberError("Please Enter Mobile Number");
      isvalid = false;
    }
    else {
      var numbervalidatine = countryData.find((ele) => ele.id == countryId);

      const mobileNumberPattern = new RegExp(`^[0-9]{${numbervalidatine.number_validation}}$`);
      if (!mobileNumberPattern.test(number)) {
        setNumberError(`Please Enter a ${numbervalidatine.number_validation}-digit Mobile Number`);
        isvalid = false;
      }

    }
    if (!termandconditions) {
      setTermandconditionsError("You must agree with the terms and conditions");
      isvalid = false;
    }

    return isvalid;
  }

  const Errorclearnumber = (e) => {
    setNumberError("");
    setTermandconditionsError(false)
  };

  const firstVisibleDigitsemail = 3;
  const lastVisibleDigitsemail = 12;
  const maskedNumberemail = '*'.repeat(firstVisibleDigitsemail) + email.substring(email.length - lastVisibleDigitsemail);

  const validationemail = () => {

    Errorclearemail();
    var isvalid = true

    if (email != "") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setEmailError("Email is not valid")
        isvalid = false;
      }
    } else {
      setEmailError("Please Enter Email")
    }
    if (!termandconditions) {
      setTermandconditionsError("You must agree with the terms and conditions");
      isvalid = false;
    }
    return isvalid;
  }

  const Errorclearemail = (e) => {
    setEmailError("");
    setTermandconditionsError(false)
  };


  const loginPageLinks = () => {
    LoginPageLinksApi().then((response) => {
      if (response.data.status) {
        setPageLinks(response.data.data)
      }
    })
  }

  const CountrycodeApiCall = () => {
    CountrycodeApi().then(function (response) {
      setCountryData(response.data.data)
      setCountryId(response.data.data[0].id)

    })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
  }

  const signin = () => {
    if (disableLoginBtn) {
      return false;
    }
    setDisableLoginBtn(true);
    var data = {
      type: loginwith,
    }
    if (loginwith == "mobile") {
      if (!validationnumber()) {
        setDisableLoginBtn(false);
        return false;
      }
      data = {
        ...data,
        mobile_number: number,
        country_id: countryId,
      }
    }

    if (loginwith == "email") {
      if (!validationemail()) {
        setDisableLoginBtn(false);
        return false;
      }
      data = {
        ...data,
        email: email,
      }
    }

    LogInSendOtpApi(data).then(function (response) {
      setDisableLoginBtn(false);
      if (response.data.status) {
        setStep(2);
        sendOTP();
      }
      else {
        Store.addNotification({
          message: response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
    })
      .catch(function (error) {
        setDisableLoginBtn(false);
        console.log(error);
        Store.addNotification({
          message: "Something goes wrong, please try again later!",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      });

  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
     if(params.get("referral_code")){
      setReferralCode(params.get("referral_code"))
     }
    }, [location.search])

  const handleCountryId = (e) => {

    if (e && e.target) {
      var Iddata = e.target.value;
      if (countryId != Iddata) {
        setNumber("");
        setCountryId(Iddata);
      } else {
        setCountryId(Iddata);
      }
    }
  }

  const handleEmailotp = (e) => {
    setEmail(e.target.value);
  }

  const ValidateOtp = () => {
    setisDataLoader(true)

    var data = {
      type: loginwith,
    }
    if (loginwith == "mobile") {
      data = {
        ...data,
        country_id: countryId,
        mobile_number: number,
        device_name: browserName,
        device_type: "web",
        device_name: detectdevice,
        otp: verificationCode,
        referral_code:referralCode
      }
    }
    if (loginwith == "email") {
      data = {
        ...data,
        type: loginwith,
        email: email,
        device_name: browserName,
        device_type: "web",
        device_name: detectdevice,
        otp: verificationCode,
        referral_code:referralCode
      }
    }


    LoginApi(data).then(function (response) {
      setisDataLoader(false)
      if (!response.data.status) {
        Store.addNotification({
          message: response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
        return false;
      }
      var userData = response.data.data;
      userData.token = response.data.token;
      userData.description = encodeURIComponent(userData.description)
      var encryptData = btoa(JSON.stringify({
        token: userData.token,
        id: userData.id
      }));
      localStorage.setItem('hukup-info', encryptData);
      connectSocket(userData.id)
      setAuthData(userData)
    })
      .catch(function (error) {
        setisDataLoader(false)
        console.log('error', error);
        Store.addNotification({
          message: "Something goes wrong, please try again later!",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      });
  }

  const handleMobileNumberChange = (e) => {
    const inputNumber = e.target.value;
    // Remove any non-numeric characters
    const numericValue = inputNumber.replace(/\D/g, '');
    var numbervalidatine = countryData.find((ele) => ele.id == countryId)
    setCountrcode(numbervalidatine.country_code)
    // Ensure the numeric value is exactly 10 digits
    if (numericValue.length <= numbervalidatine.number_validation) {
      setNumber(numericValue);
    }
  };

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [buttonClick, setButtonClick] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const sendOTP = () => {
    setMinutes(1);
  };

  const resendOTP = () => {
    // setMinutes(1);
    setButtonClick(buttonClick + 1);
    signin();
  };


  //  ----  smart banner code start ---------

  const [smartbanner, setSmartbanner] = useState(true);

  useEffect(() => {
    const storedValue = sessionStorage.getItem('smartbanner');
    if (storedValue == 'false') {
      setSmartbanner(false);
    }
  }, []);

  const closeSmartBanner = () => {
    sessionStorage.setItem('smartbanner', 'false');
    setSmartbanner(false);
  }

  const appStoreSmartBanner = () => {
    sessionStorage.setItem('smartbanner', 'false');
    setSmartbanner(false);
    window.open(siteSetting?.siteSetting?.app_store_app_link, '_blank');
  }

  const playStoreSmartBanner = () => {
    sessionStorage.setItem('smartbanner', 'false');
    setSmartbanner(false);
    window.open(siteSetting?.siteSetting?.play_store_app_link, '_blank');
  }

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "iOS";
    }
    return "unknown";
  }
  //  ----  smart banner code end  ---------

  useEffect(() => {
    getDetectdeviceName()
  }, [])


  function getDetectdeviceName() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Android detection
    if (/android/i.test(userAgent)) {
      setDetectdevice("MobileWeb");
      return "MobileWeb";
    }

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      setDetectdevice("MobileWeb");
      return "MobileWeb";
    }

    // Desktop detection
    if (/(windows|macintosh|linux)/i.test(userAgent)) {
      setDetectdevice("Desktop");
      return "Desktop";
    }
    return "unknown";
  }


  const lognumemailback = () => {
    setEmail("");
    setStep(step - 1);
    setEmailError("");
    setTermandconditionsError(false);
    setNumberError("");
    setNumber("");
    setTermandconditions(false);
  }

  return (
    <>
      {isDataLoader && (
        <DataLoader />
      )}

      <Helmet>
        <meta name="description" content={"Login On Hukup Australia"} />
      </Helmet>

      {
        (getMobileOperatingSystem() == "iOS" && siteSetting?.siteSetting?.app_store_app_link) && (
          window.innerWidth > 768 ? (
            <></>
          ) : (
            smartbanner && (
              <div className="position-relative">
                <div className="smart_banner_main">
                  <div className="d-flex align-items-center">
                    <div className="smart_img_main">
                      <img src={require("../../images/Smartbanner/App_logo.png")} className="smart_app_logo" alt="App Logo" />
                    </div>
                    <div className="ml-2">
                      <p className="mb-0 smart_title">Hukup Australia</p>
                      <p className="mb-0 smart_title_secon">Download app from here</p>
                      <p className="mb-0 smart_title_secon">
                        <a href={siteSetting?.siteSetting?.app_store_app_link} target="_blank">
                          FREE - In App Store
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="">
                    <button className="smart_view_btn" onClick={appStoreSmartBanner} >Open In App</button>
                  </div>
                </div>
                <div className="smart_close_icon_main">
                  <IoMdCloseCircleOutline className="smart_close_icon" onClick={closeSmartBanner} />
                </div>
              </div>
            )
          )
        )
      }
      {
        (getMobileOperatingSystem() != "iOS" && siteSetting?.siteSetting?.play_store_app_link) && (
          window.innerWidth > 768 ? (
            <></>
          ) : (
            smartbanner && (
              <div className="position-relative">
                <div className="smart_banner_main">
                  <div className="d-flex align-items-center">
                    <div className="smart_img_main">
                      <img src={require("../../images/Smartbanner/App_logo.png")} className="smart_app_logo" alt="App Logo" />
                    </div>
                    <div className="ml-2">
                      <p className="mb-0 smart_title">Hukup Australia</p>
                      <p className="mb-0 smart_title_secon">Download app from here</p>
                      <p className="mb-0 smart_title_secon">
                        <a href={siteSetting?.siteSetting?.play_store_app_link} target="_blank">
                          FREE - In Play Store
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="">
                    <button className="smart_view_btn" onClick={playStoreSmartBanner} >Open In App</button>
                  </div>
                </div>
                <div className="smart_close_icon_main">
                  <IoMdCloseCircleOutline className="smart_close_icon" onClick={closeSmartBanner} />
                </div>
              </div>
            )
          )
        )
      }

      <HeaderSecond loginbtnremove="loginbtnremove" />

      <div className="container-fluid bg-white with_out_login_page_header position-relative">
        <Row className={step === 1 ? "d-block" : "d-none"} >
          <Col className="arrowback" md={12} >
            <MdKeyboardArrowLeft fontSize={30} className="cursor_pointer_common" onClick={lognumemailback} />
          </Col>
        </Row>
        <Row className="loginmodalcenter">
          <Col className={step == 0 ? "logindetalis login_first_section" : "logindetalis d-none"} md={6}>

            <Row className="mb-4 pt-5 pt-md-2">
            {/* <Row className="mb-4 pt-5"> */}
              <Col className="px-0">
                <div className="d-flex align-items-center">
                  {siteSetting?.siteSetting?.app_store_app_link &&
                    <div className="mr-4 login_app_play_store">
                      <a href={siteSetting?.siteSetting?.app_store_app_link} target="_blank">
                        <img src={require("../../images/Loginpage/loginappStore.png")} className="w-100 h-100" />
                      </a>
                    </div>
                  }
                  {siteSetting?.siteSetting?.play_store_app_link &&
                    <div className="ml-4 login_app_play_store">
                      <a href={siteSetting?.siteSetting?.play_store_app_link} target="_blank">
                        <img src={require("../../images/Loginpage/loginPlayStore.png")} className="w-100 h-100" />
                      </a>
                    </div>
                  }
                </div>
              </Col>
            </Row>

            {siteSetting?.siteSetting?.head_line &&
              <Row className="">
                <Col md={10} lg={9} xl={6} className="mx-auto">
                  <p className="Welcoming_title_login text-center mb-5 mb-lg-2 mb-xl-3 mb-xxl-5">{siteSetting?.siteSetting?.head_line} <Link to="/about-us">more</Link></p>
                </Col>
              </Row>
            }

            <Row className="">
              <Col md={10} lg={9} xl={7} className="mx-auto main_log_page">
                <div className="divcenter">
                  <div className='mb-3 mb-xl-5'>
                    {/* <img alt="Hukup Australia Logo" src={siteSetting?.siteSetting?.site_logo ? siteSetting?.siteSetting?.site_logo : user} className="sidebar_icon_login" /> */}
                    {siteSetting?.siteSetting?.site_logo ?
                      <img alt="Hukup Australia Logo" src={siteSetting?.siteSetting?.site_logo} className="sidebar_icon_login" />
                      :
                      <SkeletonTheme>
                        <Skeleton count={1} height={50} width={200} />
                      </SkeletonTheme>
                    }
                  </div>
                  {siteSetting?.siteSetting?.tag_line ?
                    <p className="Welcoming_title_login text-center mb-4 mb-lg-2 mb-xl-3 mb-xxl-4">{siteSetting?.siteSetting?.tag_line}</p>
                    :
                    <SkeletonTheme>
                      <Skeleton count={1} height={50} width={200} />
                    </SkeletonTheme>
                  }
                  <div className="loginfont mb-2">LOGIN</div>

                  <div className="login_email_num_main text-center">
                    <button className="login_email_num_btn mb-3" onClick={(e) => { setStep(1); setLoginwith("mobile") }}>Login with mobile number</button>
                    <button className="login_email_num_btn" onClick={(e) => { setStep(1); setLoginwith("email"); }}>Login with email</button>
                  </div>
                  {/* ==== */}
                  <div className="mt-4">
                    <a target="_blank" href="https://blog.hukup.net/create-new-ticket/" className="mb-0 cursor_pointer_common">Trouble signing in?</a>
                  </div>

                  {/* <div className="mt-3">
                    <p className="mb-0 cursor_pointer_common" onClick={() => navigate("/claim-old-account")}>Claim your old Hukup account</p>
                  </div> */}

                  <div className="mt-4 text-center">
                    <a className="text-decoration-none" target="_blank" href={"https://blog.hukup.net/faqs/"} ><span className="mx-1 mx-md-2 fonthere FAQ_Terms_of_Use_title">FAQ</span></a>
                    {
                      pageLinks.map((ele) => {
                        return (
                          <>
                            {" | "}<a className="text-decoration-none" target="_blank" href={"/" + ele.slug} ><span className="mx-1 mx-md-2 fonthere FAQ_Terms_of_Use_title">{ele.title}</span></a>
                          </>
                        )
                      })
                    }
                    {/* <a className="text-decoration-none fonthere FAQ_Terms_of_Use_title" href="https://blog.hukup.net/" target="_blank" >Blog</a> */}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          {/* step 1 ======= */}
          <Col className={step === 1 ? "logindetalis" : "logindetalis d-none"} md={6} >
            <Row >
              <Col md={8} lg={8} xl={7} className="mx-auto">
                <div className="divcenter">
                  <div className='mb-3 mb-md-5'>
                    <img alt="Hukup Australia Logo" src={siteSetting?.siteSetting?.site_logo ? siteSetting?.siteSetting?.site_logo : user} className="sidebar_icon_login" />
                  </div>
                  <p className="Welcoming_title_login text-center">{siteSetting?.siteSetting?.tag_line}</p>
                  <div className="loginfont">LOGIN</div>
                  {loginwith == "mobile" && (
                    <>
                      <div className="d-flex border-login-input">
                        <div className="borderselct bg-white">
                          <Form.Select
                            aria-label="Default select example "
                            className="border-remove borderselct"
                            value={countryId}
                            onChange={(e) => handleCountryId(e)}
                          >
                            {countryData.map((e) => {
                              return (
                                <option value={e.id}>(+{e.country_code})</option>
                              )
                            })}
                          </Form.Select>
                        </div>
                        <div className="width_set">
                          <Form.Control
                            className="border-remove borderinput"
                            aria-label="Example text with button addon"
                            aria-describedby="basic-addon1"
                            type="number"
                            value={number}
                            placeholder="Enter mobile number"
                            onChange={handleMobileNumberChange}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter') {
                                signin(); // Call your OTP verification function
                              }
                            }}
                          />
                        </div>
                      </div>
                      <span className="errorClass">{numberError}</span>
                      <span className="mt-1">Used for OTP only, never shared</span>
                      <div className="mx-auto Term_conditions_mainsection" >
                        <div className="mt-3 Term_conditions_main_login">
                          <input
                            type="checkbox"
                            id="Termandconditions"
                            value={termandconditions}
                            checked={termandconditions}
                            onChange={(e) => setTermandconditions(!termandconditions)} className="form-check-input" />
                          <label for="Termandconditions" className="mb-0 ml-4" >
                            I agree with <a target="_blank" href="/privacy-policy" > Privacy Statement</a> and <a target="_blank" href="/terms-conditions">Terms and Conditions</a> of Hukup Australia
                          </label>
                        </div>
                      </div>
                      <span className="errorClass">{termandconditionsError}</span>
                      <div id="recaptcha-container" ></div>
                      <div className="login-button-colour mt-3 btncustom" onClick={() => signin()}>
                        <div className="d-flex align-items-center justify-content-center">
                          <div>Let’s Start</div>
                          <div className="">
                            <MdOutlineKeyboardArrowRight size={30} />
                            {disableLoginBtn && (
                              <Spinner animation="border" size="sm" />
                            )}
                          </div>
                        </div>
                      </div>
                    </>)}
                  {loginwith == "email" && (
                    <>
                      <div className="Claima_email">
                        <Form.Control
                          className="border-remove"
                          type="email"
                          placeholder="Enter email"
                          onChange={(e) => handleEmailotp(e)}
                          value={email}
                        />
                      </div>
                      <span className="errorClass">{emailError}</span>
                      <span className="mt-1">Never shared with anyone</span>
                      <div className="mx-auto Term_conditions_mainsection" >
                        <div className="mt-3 Term_conditions_main_login">
                          <input
                            type="checkbox"
                            id="Termandconditions"
                            value={termandconditions}
                            checked={termandconditions}
                            onChange={(e) => setTermandconditions(!termandconditions)} className="form-check-input" />
                          <label for="Termandconditions" className="mb-0 ml-4" >
                            I agree with <a target="_blank" href="/privacy-policy" > Privacy Statement</a> and <a target="_blank" href="/terms-conditions">Terms and Conditions</a> of Hukup Australia
                          </label>
                        </div>
                      </div>
                      <span className="errorClass">{termandconditionsError}</span>
                      <div id="recaptcha-container" ></div>
                      <div className="login-button-colour mt-3 btncustom " onClick={() => signin()}>
                        <div className="d-flex align-items-center justify-content-center">
                          <div>Let’s Start</div>
                          <div className="">
                            <MdOutlineKeyboardArrowRight size={30} />
                            {disableLoginBtn && (
                              <Spinner animation="border" size="sm" />
                            )}
                          </div>
                        </div>
                      </div>
                    </>)}

                  {/* ==== */}
                  <div className="mt-3">
                    <a target="_blank" href="https://blog.hukup.net/create-new-ticket/" className="mb-0 cursor_pointer_common">Trouble signing in?</a>
                  </div>
                  {/* <div className="mt-3">
                    <p className="mb-0 cursor_pointer_common" onClick={() => navigate("/claim-old-account")}>Claim your old Hukup account</p>
                  </div> */}
                  <div className="mt-4 text-center">
                    <a className="text-decoration-none" target="_blank" href={"https://blog.hukup.net/faqs/"} ><span className="mx-1 mx-md-2 fonthere FAQ_Terms_of_Use_title">FAQ</span></a>
                    {
                      pageLinks.map((ele) => {
                        return (
                          <>
                            {" | "}<a className="text-decoration-none" target="_blank" href={"/" + ele.slug} ><span className="mx-1 mx-md-2 fonthere FAQ_Terms_of_Use_title">{ele.title}</span></a>
                          </>
                        )
                      })
                    }
                    {/* <a className="text-decoration-none fonthere FAQ_Terms_of_Use_title" href="https://blog.hukup.net/" target="_blank" >Blog</a> */}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          {step === 2 && (
            <>
              <Row>
                <Col className="arrowback" md={12} onClick={(e) => { setStep(step - 1); setSeconds(0); setMinutes(0); setVerificationCode(""); setLoginwith(loginwith) }}>
                  <MdKeyboardArrowLeft fontSize={30} className="cursor_pointer_common" />
                </Col>
              </Row>
              <Col data-aos="fade-zoom-in" className="logindetalis px-lg-5" md={6}>
                <div className="seconddivcenter px-lg-5">
                  <div className='text-center mb-3 mb-md-5'>
                    <img alt="Hukup Australia Logo" src={siteSetting?.siteSetting?.site_logo ? siteSetting?.siteSetting?.site_logo : user} className="sidebar_icon_login mb-3 mb-lg-5" />
                  </div>
                  {loginwith == "mobile" && (
                    <h1 className="loginfontverification text-center mb-4">A text message with a verification code was just sent  to (+{countrcode}) {maskedNumber} </h1>
                  )}
                  {loginwith == "email" && (
                    <h1 className="loginfontverification text-center mb-4">A email  with a verification code was just sent  to {maskedNumberemail}  </h1>
                  )}
                  <p className="mb-4">Enter your verification code</p>
                  <ReactInputVerificationCode autoFocus={true} length={6} onChange={setVerificationCode} value={verificationCode} />
                  <div className="time_button">
                    <div className="countdown_text">
                      {seconds > 0 || minutes > 0 ? (
                        <p className="mb-0">
                          {minutes < 10 ? `0${minutes}` : minutes}:
                          {seconds < 10 ? `0${seconds}` : seconds} after
                        </p>
                      ) : (
                        <p className="mb-0">Didn't receive code?</p>
                      )}
                    </div>
                    <button
                      className="resend-code"
                      disabled={seconds > 0 || minutes > 0}
                      style={{
                        backgroundColor: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#EF3672",
                        color: seconds > 0 || minutes > 0 ? "black" : "white",
                      }}
                      onClick={resendOTP}
                    >
                      Re-send code
                    </button>
                    {/* ==== */}
                  </div>
                  <div className="mt-4">
                    Trouble sign-in? <a target="_blank" href="https://blog.hukup.net/create-new-ticket/" className="mb-0 cursor_pointer_common">Contact Us</a>
                  </div>
                </div>
              </Col>
            </>

          )}

          <Col className="login-second-bg d-none d-md-block" md={6}>
            <div>
              <img alt="layer" className="layerclass" src={layer} />
              <img alt="roundimg" className="roundimg" src={roundimg} />
              <div className="row">
                {genderIcon.map((e) => {
                  return (
                    <Col className="mx-2"><img alt="gender" className="genderimg" src={e} /></Col>
                  )
                })}
              </div>
            </div>
          </Col>

        </Row >
      </div >


    </>
  );
};

export default Loginpage;
