import React, { useCallback, useRef, useState } from 'react'
import "../../css/Matched.css"
import Header from '../CommonComponet/Header'
import Footer from '../CommonComponet/Footer'
import { Col, Row, Tab, Tabs } from 'react-bootstrap'
import "../../css/Liked.css"
import { useEffect } from 'react'
import { LikeUserApi, LikeView, LikeYou } from '../../api/Api'
import { Link, useNavigate } from 'react-router-dom'
import Vector from "../../images/Subscriptionplan/Vector.png";
import verifyIcon from '../../images/verified-icon.png';
import defaultImg from "../../images/user-profile-default-image.png";
import { useAuth } from '../../middleware/AuthProvider';
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useSocket } from '../../middleware/SocketProvider'
import MembershipModel from '../CommonComponet/MembershipModel'
import FooterMobile from '../CommonComponet/FooterMobile'
import { LuSend } from "react-icons/lu";
import '../CommonComponet/Privtemodal.css';
import { Helmet } from 'react-helmet'
import Userdetailcard from '../CommonComponet/Userdetailcard'


const Likedyou = () => {

    const navigate = useNavigate();
    const { AuthData } = useAuth();
    const { Socket } = useSocket();

    const [likeYouData, SetLikeYouData] = useState([])
    const [likeYouEncrypted, SetLikeYouEncrypted] = useState(false)

    const [totalLikeYou, SetTotalLikeYou] = useState("")
    const [likeMeData, SetLikeMeData] = useState([])
    const [likeMeEncrypted, SetLikeMeEncrypted] = useState(false)

    const [cardSkeleton, setCardSkeleton] = useState(true);
    const [cardSkeletonMeUser, setCardSkeletonMeUser] = useState(true);

    const [likeMeTotal, SetLikeMeTotal] = useState("")
    var girdItem = 0
    var girdItemMe = 0

    const [pageLikeByMe, setPageLikeByMe] = useState(1);
    const [pageLikeByeYou, setPageLikeByYou] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingLikeByYou, setIsLoadingLikeByYou] = useState(false);
    const [hasMoreRecord, setHasMoreRecord] = useState(true);
    const [hasMoreRecordYou, setHasMoreRecordYou] = useState(true);
    const [membershipModelShow, setMembershipModelShow] = useState(false);

    const divRef = useRef(null);
    const divRefYou = useRef(null);


    useEffect(() => {
        document.title = "Likes Users On Hukup Australia"
    }, [])

    useEffect(() => {
        // Me
        LikeYouApiFun()
        // YOU 
        LikeByYouApiFun()
        Socket.on("like-change-" + AuthData.id, (data) => {
            LikeYouApiFun(1)
        })
        return () => {
            Socket.off("like-change-" + AuthData.id)
        }
    }, [])

    const LikeYouApiFun = (page = pageLikeByMe) => {
        if (page != 1) {
            if (!hasMoreRecord || isLoading) {
                return false;
            }
        }
        var data = {
            page: page,
        };
        setIsLoading(true)
        LikeYou(data).then(function (response) {
            SetLikeMeEncrypted(response.data.encryptedData)
            SetTotalLikeYou(response.data.totalLike)
            setIsLoading(false)
            setCardSkeletonMeUser(false)
            if (page == 1) {
                SetLikeMeData(response.data.data)
            }
            else {
                SetLikeMeData(likeMeData.concat(response.data.data))
            }
            setPageLikeByMe(page + 1)
            var userDatas = response.data.data;
            if (!userDatas.length) {
                setHasMoreRecord(false);
            }
            else {
                setHasMoreRecord(true);
            }
        })
            .catch(function (error) {
                console.log(error);
            });

    }
    const LikeByYouApiFun = (page = pageLikeByeYou) => {
        if (pageLikeByeYou != 1) {
            if (!hasMoreRecordYou || isLoadingLikeByYou) {
                return false;
            }
        }
        var data = {
            page: page,
        };
        setIsLoadingLikeByYou(true)
        LikeView(data).then(function (response) {
            SetLikeYouData(response.data.data)
            SetLikeYouEncrypted(response.data.encryptedData)
            SetLikeMeTotal(response.data.totalLike)
            setPageLikeByYou(page + 1)
            setCardSkeleton(false)

            if (page == 1) {
                SetLikeYouData(response.data.data)
            }
            else {
                SetLikeYouData(likeYouData.concat(response.data.data))

            }
            setIsLoadingLikeByYou(false)
            var userDatas = response.data.data;
            if (!userDatas.length) {
                setHasMoreRecordYou(false);
            }
            else {
                setHasMoreRecordYou(true);
            }
        })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleScroll = useCallback(() => {
        if (
            divRef.current &&
            divRef.current.scrollTop + divRef.current.clientHeight >=
            divRef.current.scrollHeight - 200 &&
            !isLoading
        ) {
            LikeYouApiFun()
        }

    }, [isLoading, pageLikeByMe, likeMeData, hasMoreRecord]);

    useEffect(() => {
        if (divRef.current) {
            divRef.current.addEventListener("scroll", handleScroll);
        }
        return () => {
            if (divRef.current) {
                divRef.current.removeEventListener("scroll", handleScroll);
            }
        };
    }, [handleScroll]);

    // __________________________________
    const handleScrollLikeByYou = useCallback(() => {
        if (
            divRefYou.current &&
            divRefYou.current.scrollTop + divRefYou.current.clientHeight >=
            divRefYou.current.scrollHeight - 200 &&
            !isLoadingLikeByYou
        ) {
            LikeByYouApiFun()
        }

    }, [isLoadingLikeByYou, pageLikeByeYou, likeYouData, hasMoreRecordYou]);

    useEffect(() => {
        if (divRefYou.current) {
            divRefYou.current.addEventListener("scroll", handleScrollLikeByYou);
        }
        return () => {
            if (divRefYou.current) {
                divRefYou.current.removeEventListener("scroll", handleScrollLikeByYou);
            }
        };
    }, [handleScrollLikeByYou]);

    var Likedyoucount = <div className="liked_tab_title_name">Liked Me<span className='pinkcustom'> {likeMeTotal}</span></div>
    var Likedmecount = <div className="liked_tab_title_name">I Liked<span className='pinkcustom'> {totalLikeYou}</span></div>

    const [key, setKey] = useState('Likedyou');

    return (
        <>
            <Helmet>
                <meta name="description" content={"Likes Users On Hukup Australia"} />
            </Helmet>

            <div className="container-fluid p-0 main_section">
                <div className="background_section_main">
                    <div className="yellow_dot d-none d-sm-none d-md-none d-lg-block"></div>
                    <div className="green_dot d-none d-sm-none d-md-none d-lg-block"></div>
                    <div className="green_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
                    <div className="orange_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
                    <img alt='Whitedot' src={require("../../../src/images/Whitedot.png")} className="d-none d-sm-none d-md-none d-lg-block Whitedot_first" />
                    <img alt='Whitedot' src={require("../../../src/images/Whitedot.png")} className="d-none d-sm-none d-md-none d-lg-block Whitedot_second" />
                    <img alt='smileemoji' src={require("../../../src/images/Visitors/smileemoji.png")} className="visiter_smileemoji_img d-none d-sm-none d-md-none d-lg-block" />
                    <img alt='CurveBlackline' src={require("../../../src/images/Visitors/CurveBlackline.png")} className="CurveBlackline_img d-none d-sm-none d-md-none d-lg-block" />
                    <img alt='orangeline' src={require("../../../src/images/orangeline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_orangeline" />
                    <img alt='greenline' src={require("../../../src/images/greenline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_greenline" />
                    <img alt='Ellipse' src={require("../../../src/images/Ellipse.png")} className="footer_ellipse_img" />
                    <img alt='footerblackline' src={require("../../../src/images/footerblackline.png")} className="footer_blackline_img" />
                </div>
                <Header />

                <div className="container-fluid content_main px-0">
                    <div className="container">
                        <div className="row">
                            {/* ====== User Name And User Image section ======== */}
                            <div className="d-none d-sm-none d-md-none d-lg-flex col-lg-4 col-xl-4 pt-4 pt-sm-4 pt-md-0 pt-lg-0 pt-xl-0 add_new_center_col">
                                <Userdetailcard /> 
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8  Liked_section_contain">
                                <div className="row">
                                    <Tabs
                                        activeKey={key}
                                        onSelect={(k) => setKey(k)}
                                        className="my-2 px-1"
                                    >
                                        <Tab eventKey="Likedyou" title={Likedyoucount} className="px-0 tab_liked_you">
                                            <div className="col-12 Liked_section_slider" ref={divRef}>
                                                {cardSkeleton && (
                                                    <SkeletonTheme >
                                                        <Row className="">
                                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((ele) => {
                                                                return (
                                                                    <Col xs={6} md={4} lg={3}>
                                                                        <Skeleton height={200} count={1} />
                                                                    </Col>
                                                                )
                                                            })}
                                                        </Row>
                                                    </SkeletonTheme>
                                                )}
                                                <div className="row row-cols-2 row-cols-sm-2 row-cols-lg-3 row-cols-md-3 row-cols-xl-5 px-0 px-sm-0 px-md-0 px-lg-0 px-xl-0">
                                                    {
                                                        likeYouData.map((item, index) => {
                                                            var gridClass = "";
                                                            if (window.innerWidth < 768) {
                                                                if (index == 0) {
                                                                    gridClass = "slider_img";
                                                                    girdItem = 0;
                                                                }
                                                                else {
                                                                    girdItem = girdItem + 1;
                                                                    if (girdItem == 3 || girdItem == 4) {
                                                                        gridClass = "slider_img";
                                                                    }
                                                                    if (girdItem == 4) {
                                                                        girdItem = 0;
                                                                    }
                                                                }
                                                            }
                                                            else {
                                                                gridClass = index % 2 === 0 ? "" : "slider_img";
                                                            }
                                                            return (
                                                                <NewProfileCard LikeYouApiFun={LikeYouApiFun} gridClass={gridClass} item={item} index={index} />
                                                            )
                                                        })
                                                    }
                                                    {(likeMeTotal == 0 && !cardSkeleton) ? <img alt='liked' src={require("../../images/Visitors/LikedObject.png")} className="w-auto m-auto" /> : ""}
                                                    {/*==== liked you fake data ==== */}
                                                    {likeYouEncrypted && (() => {
                                                        const renderedFakeData = [];
                                                        for (let index = 0; index < likeMeTotal - likeYouData.length; index++) {
                                                            renderedFakeData.push(
                                                                <div key={index} className="card_main" onClick={() => setMembershipModelShow(true)} >
                                                                    <Fakedatacard setMembershipModelShow={setMembershipModelShow} />
                                                                </div>
                                                            );
                                                        }
                                                        return renderedFakeData;
                                                    })()}
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Likedme" title={Likedmecount} >
                                            <div className="col-12 Liked_section_slider" ref={divRefYou}>
                                                {cardSkeletonMeUser && (
                                                    <SkeletonTheme >
                                                        <Row className="">
                                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((ele) => {
                                                                return (
                                                                    <Col xs={6} md={4} lg={3}>
                                                                        <Skeleton height={200} count={1} />
                                                                    </Col>
                                                                )
                                                            })}
                                                        </Row>
                                                    </SkeletonTheme>
                                                )}
                                                <div className="row row-cols-2 row-cols-sm-2 row-cols-lg-3 row-cols-md-3 row-cols-xl-5 px-0 px-sm-0 px-md-0 px-lg-0 px-xl-0">
                                                    {
                                                        likeMeData.map((item, index) => {
                                                            var gridClass = "";
                                                            if (window.innerWidth < 768) {
                                                                if (index == 0) {
                                                                    gridClass = "slider_img";
                                                                    girdItemMe = 0;
                                                                }
                                                                else {
                                                                    girdItemMe = girdItemMe + 1;
                                                                    if (girdItemMe == 3 || girdItemMe == 4) {
                                                                        gridClass = "slider_img";
                                                                    }
                                                                    if (girdItemMe == 4) {
                                                                        girdItemMe = 0;
                                                                    }
                                                                }
                                                            }
                                                            else {
                                                                gridClass = index % 2 === 0 ? "" : "slider_img";
                                                            }
                                                            return (
                                                                <>
                                                                    <ProfileCard gridClass={gridClass} item={item} index={index} likeMeData={likeMeData} SetLikeMeData={SetLikeMeData} totalLikeYou={totalLikeYou} SetTotalLikeYou={SetTotalLikeYou} />
                                                                </>
                                                            )
                                                        })
                                                    }
                                                    {(totalLikeYou == 0 && !cardSkeletonMeUser) ? <img alt='Like' src={require("../../images/Visitors/LikedObject.png")} className="w-auto m-auto" /> : ""}

                                                    {/* ======== liked by you fake data ======== */}
                                                    {likeMeEncrypted && (() => {
                                                        const UserrenderedFakeData = [];
                                                        for (let index = 0; index < totalLikeYou - likeMeData.length; index++) {
                                                            UserrenderedFakeData.push(
                                                                <div key={index} className="card_main" onClick={() => setMembershipModelShow(true)}>
                                                                    <Fakedatacard setMembershipModelShow={setMembershipModelShow} />
                                                                </div>
                                                            );
                                                        }
                                                        return UserrenderedFakeData;
                                                    })()}
                                                </div>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterMobile />
                </div>
                <Footer />
            </div>
            <MembershipModel
                show={membershipModelShow}
                onHide={() => setMembershipModelShow(false)} />
        </>
    )
}

export default Likedyou


// == liked me =================== 
const NewProfileCard = (props) => {
    const navigate = useNavigate();
    const { Socket } = useSocket();
    const { AuthData } = useAuth();
    const [isLike, SetIsLike] = useState(props.item.is_like)
    const [isFav, SetIsFav] = useState(props.item.is_favourites)


    useEffect(() => {
        Socket.on("like-change-" + AuthData.id, (data) => {
            if (data.liked_user_id == props.item.user_id) {
                SetIsLike(data.is_like)
            }
        })
    }, [props])

    const LikeApi = () => {
        var likeData = {
            liked_user_id: props.item.user_id,
        };
        Socket.off("liked-response-" + props.item.user_id);
        Socket.emit("like", likeData);
        Socket.on("liked-response-" + props.item.user_id, (data) => {
            if (data.status == false) {
                Store.addNotification({
                    // title: data.message,
                    message: data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__zoomIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        onScreen: false
                    }
                });
            }
            else {
                SetIsLike(!isLike)
                props.LikeYouApiFun(1)
            }
        });
    };

    useEffect(() => {
        Socket.on("favorite-change-" + AuthData.id, (data) => {
            if (data.favourite_user_id == props.item.user_id) {
                SetIsFav(data.is_favourite)
            }
        })

        return () => {
            Socket.off("favorite-change-" + AuthData.id)
        }
    }, [props])

    const FavApi = () => {
        var favouriteData = {
            favourite_user_id: props.item.user_id,
        };
        Socket.off("favourites-response-" + props.item.user_id);
        Socket.emit("favourite", favouriteData);
        Socket.on("favourites-response-" + props.item.user_id, (data) => {
            if (data.status == false) {
                Store.addNotification({
                    // title: data.message,
                    message: data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__zoomIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        onScreen: false
                    }
                });
            }
            else {
                SetIsFav(!isFav)
            }
        });
    };


    return (
        <div className="card_main">

            <div className="card_section_main_sidebar_liked">
                <div className="card_section_sidebar_center_main" >


                    {/*== fav section  =====*/}
                    <div className="card_section_chiled" onClick={FavApi}>
                        {
                            isFav ? (
                                <img alt='Favourite' src={require("../../images/UserDetailspage/staruserdetailes.png")} className="fav_icon_heart" />
                            ) : (
                                <img alt='Favourite' src={require("../../images/UserDetailspage/Star2.png")} className="fav_icon_heart" />
                            )
                        }
                    </div>

                    {/*== like section  =====*/}
                    <div className="card_section_chiled" onClick={LikeApi}>
                        {
                            isLike ? (
                                <img alt='Like' src={require("../../images/Heartlike.png")} className="icon_heart_red_sidebar" />
                            ) : (
                                <img alt='Unike' src={require("../../images/Heartnotlike.png")} className="icon_heart_sidebar" />
                            )
                        }
                    </div>

                    {/*=== chat icon ====*/}
                    <div className="card_section_chiled">
                        <LuSend className="card_Chatpage_luSend_sidebar" onClick={() => navigate("/messages?username=" + props.item.username)} />
                    </div>

                </div>
            </div>

            <div className="col px-0 slide_main_section">
                <Link to={"/user/" + props.item.username} className='linkover'>
                    <div className="card_content_section">
                        <img src={props.item.thumbnail_image} className="card_background_img img-fluid" alt="Profile" ></img>
                        <div className={props.gridClass} ></div>
                        <div className="content_bottom card_content_bottom">
                            <div className="user_name_width_set">
                                <h4 className="my-0 content_slider_name">{props.item.name}
                                {props.item.userProfileVerify === 1 && <img alt='Verify' src={verifyIcon} className='user_verify-icon-home'></img>}
                                </h4>
                                <p className="my-0 content_slider_description"><span className="content_slider_description_color">
                                {props?.item?.hide_exact_location === 1 ? 
                                `${Math.ceil(props?.item?.distance)} KM` :
                                props?.item?.distance < 1 ? ` ${(props.item?.distance * 1000)} M` : ` ${props.item?.distance} KM`}</span></p>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>

        </div>
    )
}

//  ===. I liked ===================
const ProfileCard = (props) => {
    const { Socket } = useSocket();
    const { AuthData } = useAuth();
    const [isLike, SetIsLike] = useState(props.item.is_like)
    const [isFav, SetIsFav] = useState(props.item.is_favourites)
    const navigate = useNavigate();


    useEffect(() => {
        Socket.on("like-change-" + AuthData.id, (data) => {
            if (data.liked_user_id == props.item.user_id) {
                SetIsLike(data.is_like)
            }
        })

    }, [props])

    const LikeApi = () => {

        var likeData = {
            liked_user_id: props.item.user_id,
        };
        Socket.off("liked-response-" + props.item.user_id);
        Socket.emit("like", likeData);
        Socket.on("liked-response-" + props.item.user_id, (data) => {
            if (data.status == false) {
                Store.addNotification({
                    // title: data.message,
                    message: data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__zoomIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        onScreen: false
                    }
                });
            }
            else {
                SetIsLike(!isLike)
                props.SetLikeMeData(props.likeMeData?.filter((item) => {
                    return item.user_id != props.item.user_id
                }))
                props.SetTotalLikeYou(props.totalLikeYou - 1)
            }
        });
    };

    useEffect(() => {
        Socket.on("favorite-change-" + AuthData.id, (data) => {
            if (data.favourite_user_id == props.item.user_id) {
                SetIsFav(data.is_favourite)
            }
        })

        return () => {
            Socket.off("favorite-change-" + AuthData.id)
        }
    }, [props])

    const FavApi = () => {
        var favouriteData = {
            favourite_user_id: props.item.user_id,
        };
        Socket.off("favourites-response-" + props.item.user_id);
        Socket.emit("favourite", favouriteData);
        Socket.on("favourites-response-" + props.item.user_id, (data) => {
            if (data.status == false) {
                Store.addNotification({
                    // title: data.message,
                    message: data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__zoomIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        onScreen: false
                    }
                });
            }
            else {
                SetIsFav(!isFav)
            }
        });
    };

    return (
        <div className="card_main">

            <div className="card_section_main_sidebar_liked">
                <div className="card_section_sidebar_center_main" >


                    {/*== fav section  =====*/}
                    <div className="card_section_chiled" onClick={FavApi}>
                        {
                            isFav ? (
                                <img alt='Favourite' src={require("../../images/UserDetailspage/staruserdetailes.png")} className="fav_icon_heart" />
                            ) : (
                                <img alt='Favourite' src={require("../../images/UserDetailspage/Star2.png")} className="fav_icon_heart" />
                            )
                        }
                    </div>

                    {/*== like section  =====*/}
                    <div className="card_section_chiled" onClick={LikeApi}>
                        {
                            <img alt='Like' src={require("../../images/Heartlike.png")} className="icon_heart_red_sidebar" />
                        }
                    </div>

                    {/*=== chat icon ====*/}
                    <div className="card_section_chiled">
                        <LuSend className="card_Chatpage_luSend_sidebar" onClick={() => navigate("/messages?username=" + props.item.username)} />
                    </div>
                </div>
            </div>

            <div className="col px-0 slide_main_section">
                <Link to={"/user/" + props.item.username} className='linkover'>
                    <div className="card_content_section">
                        <img src={props.item.thumbnail_image} className="card_background_img img-fluid" alt="Favourite"></img>
                        <div className={props.gridClass} ></div>
                        <div className="content_bottom card_content_bottom">
                            <div className="user_name_width_set">
                                <h4 className="my-0 content_slider_name">{props.item.name}
                                {props.item.userProfileVerify === 1 && <img alt='Verify' src={verifyIcon} className='user_verify-icon-home'></img>}
                                </h4>
                                <p className="my-0 content_slider_description">
                                    <span className="content_slider_description_color">
                                    {props?.item?.hide_exact_location === 1 ? 
                                    `${Math.ceil(props?.item?.distance)} KM` :
                                    props?.item?.distance < 1 ? ` ${(props.item?.distance * 1000)} M` : ` ${props.item?.distance} KM`}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>

        </div>
    )
}

const Fakedatacard = (props) => {
    return (
        <>
            <div className="card_section_main_sidebar_liked blur_img_fake_contain">
                <div className="card_section_sidebar_center_main" >
                    {/*== fav section  =====*/}
                    <div className="card_section_chiled">
                        <img alt='Favourite' src={require("../../images/UserDetailspage/Star2.png")} className="fav_icon_heart" />
                    </div>

                    {/*== like section  =====*/}
                    <div className="card_section_chiled">
                        <img alt='Like' src={require("../../images/Heartlike.png")} className="icon_heart_red_sidebar" />
                    </div>

                    {/*=== chat icon ====*/}
                    <div className="card_section_chiled">
                        <LuSend className="card_Chatpage_luSend_sidebar" />
                    </div>
                </div>
            </div>
            <div className="col px-0 slide_main_section">
                <Link className='linkover'>
                    <div className="card_content_section">
                        <img src={require("../../images/nophoto.png")} className="card_background_img img-fluid blur_img_fake_contain" alt="Favourite"></img>
                        {/* <div className={props.gridClass} ></div> */}
                        <div className="fake_slider_img" ><img alt="Private" src={Vector} className="fake_private_image" onClick={() => props.setMembershipModelShow(true)} /></div>
                        <div className="content_bottom card_content_bottom">
                            <div className="user_name_width_set">
                                <h4 className="my-0 content_slider_name blur_name_like_fake_contain">prit</h4>
                                <p className="my-0 content_slider_description blur_name_like_fake_contain"><span className="content_slider_description_color">10.5km</span></p>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </>
    )
}