import React, { useState } from "react";
import Header from "../CommonComponet/Header";
import Footer from "../CommonComponet/Footer";
import "../../css/BlockedUser.css";
import { useEffect } from "react";
import defaultImg from "../../images/user-profile-default-image.png";
import { useAuth } from '../../middleware/AuthProvider'
import DataLoader from "../../components/ScreenLoader/DataLoader";
import { Store } from 'react-notifications-component';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import FooterMobile from "../CommonComponet/FooterMobile";
import { Helmet } from "react-helmet";
import Userdetailcard from "../CommonComponet/Userdetailcard";
import { Badge, Form, InputGroup, Nav, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { MyProfileData, ReferralPageLinksApi, Referraluserdatalist, WithdrawalRequest } from "../../api/Api";
import { MdOutlineContentCopy, MdShare } from "react-icons/md";
import qrcodeimg from '../../images/qrcode.png';
import { IoMdDownload } from "react-icons/io";


const Referraluser = () => {
  const { AuthData, setAuthData , siteSetting} = useAuth();
  const [isDataLoader, setisDataLoader] = useState(false);
  const [referraluserData, setReferraluserData] = useState([]);
  const [cardSkeleton, setCardSkeleton] = useState(true);
  const [userData, setUserData] = useState([]);
  const [pageLinks, setPageLinks] = useState([]);
  const [unableModalShare, setunableModalShare] = useState(false);


  const navigate = useNavigate();

  useEffect(() => {
    MyProfileApi();
    ReferraluserlistApi();
    referralPageLinks();
    document.title = "Referred Users On Hukup Australia"
  }, []);

  const ReferraluserlistApi = () => {
    Referraluserdatalist()
      .then(function (response) {
        console.log("response", response.data.data);
        setReferraluserData(response.data.data);
        setCardSkeleton(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const referralPageLinks = () => {
    ReferralPageLinksApi().then((response) => {
      if (response.data.status) {
        setPageLinks(response.data.data)
      }
    })
  }

  function formatDate(dateString) {
    // Parse the date string
    const date = new Date(dateString);
  
    // Get day, month, year, and time components
    const day = date.getUTCDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    
    // Format the day with the appropriate suffix
    const daySuffix = (day) => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };
  
    // Format time to 12-hour format
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12 AM
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedMinutes = minutes.toString().padStart(2, '0'); // Ensure two digits for minutes
  
    // Construct the formatted date string
    return `${day}${daySuffix(day)} ${month} ${year}, ${formattedHours}:${formattedMinutes} ${ampm}`;
  }
  
  // // Example usage
  // const formattedDate = formatDate("2024-05-21T11:05:14.000Z");
  // console.log(formattedDate); // Output: "21st May 2024, 11:05 AM"

  const MyProfileApi = () => {
    MyProfileData()
      .then(function (response) {
        setUserData(response.data.data)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleButtonClick = (imageUrl) => {
    handleShareClickimg(imageUrl);
  };

  const copyTorefrrallink = (urlToCopy) => {
    navigator.clipboard
      .writeText(urlToCopy)
      Store.addNotification({
        message: "Referral Code Copied",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__zoomIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: false,
        },
      });
  };

  const copyToClipboard = (urlToCopy) => {
    handleShareClick(urlToCopy);
    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => {
      })
      .catch((error) => {
      });
  };
  const handleshowunablehidemodal = () => {
    setunableModalShare(false)
  }

  const handleShareClickimg = async (imageSrc) => { 
    try {
        const imageData = await fetch(imageSrc).then(res => res.blob());
        const file = new File([imageData], "some.png", { type: "image/png" });
        if (navigator.canShare && navigator.canShare({ files: [file] })) {
          await navigator.share({
            title: "Share File",
            files: [file]
          });
          console.log("Image shared successfully");
        } else {
          console.error("Web Share API is not supported in this browser.");
          setunableModalShare(true);
        }
      } catch (err) {
        console.error("Share failed:", err.message);
      }
    
    // try {
    //   if (navigator.share) {
    //     await navigator.share({
    //       title: 'Share Image',
    //       text: 'Check out this image!',
    //       url: imageSrc,
    //     });
    //     console.log('Image shared successfully');
    //   } else {
    //     throw new Error('Web Share API not supported.');
    //   }
    // } catch (error) {
    //   console.error('Error sharing image:', error.message);
    // }
  };

  const handleShareClick = async (urlToCopy) => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: urlToCopy,
          text: "Share Referral Link",
          url: urlToCopy,
        });
      } else {
        console.error("Web Share API is not supported in this browser.");
        setunableModalShare(true);
      }
    } catch (error) {
      console.error("Error sharing:", error.message);
    }
  };
  
  const paymentoutrefrerral = () => {
    if(userData?.credits < siteSetting?.siteSetting?.minimum_credits){
      Store.addNotification({
        message: "Insufficient credits",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__zoomIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: false,
        },
      });
      return false;
    }
    WithdrawalRequest()
    .then(function (response) {
       if(!response.data.status) {
         Store.addNotification({
           message: response.data.message,
           type: "danger",
           insert: "top",
           container: "top-right",
           animationIn: ["animate__animated", "animate__zoomIn"],
           animationOut: ["animate__animated", "animate__fadeOut"],
           dismiss: {
             duration: 2000,
             onScreen: false,
           },
         });
         return false;
       }
       Store.addNotification({
         message: response.data.message,
         type: "success",
         insert: "top",
         container: "top-right",
         animationIn: ["animate__animated", "animate__zoomIn"],
         animationOut: ["animate__animated", "animate__fadeOut"],
         dismiss: {
           duration: 2000,
           onScreen: false,
         },
       });
    })
    .catch(function (error) {
     Store.addNotification({
       message: error.message,
       type: "danger",
       insert: "top",
       container: "top-right",
       animationIn: ["animate__animated", "animate__zoomIn"],
       animationOut: ["animate__animated", "animate__fadeOut"],
       dismiss: {
         duration: 2000,
         onScreen: false,
       },
     });
    });
   }

   const download = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);     
      const element = document.createElement("a");
      element.href = url;
      element.download = "image.png";
      document.body.appendChild(element); // Required for this to work in Firefox
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      console.error("Error downloading the image:", error);
    }
  };



  return (
    <>

      {isDataLoader && (
        <DataLoader />
      )}
      <Helmet>
        <meta name="description" content={"Referred Users On Hukup Australia"} />
      </Helmet>

      <div className="container-fluid p-0 main_section">
        <div className="background_section_main">
          <div className="yellow_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="green_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <div className="orange_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
          <img
            src={require("../../../src/images/Whitedot.png")}
            alt="Whitedot"
            className="d-none d-sm-none d-md-none d-lg-block Whitedot_first"
          />
          <img
            src={require("../../../src/images/Whitedot.png")}
            alt="Whitedot"
            className="d-none d-sm-none d-md-none d-lg-block Whitedot_second"
          />
          <img
            src={require("../../../src/images/orangeline.png")}
            alt="orangeline"
            className="d-none d-sm-none d-md-none d-lg-block footer_orangeline"
          />
          <img
            src={require("../../../src/images/greenline.png")}
            alt="greenline"
            className="d-none d-sm-none d-md-none d-lg-block footer_greenline"
          />
          <img
            src={require("../../../src/images/Ellipse.png")}
            alt="Ellipse"
            className="footer_ellipse_img"
          />
          <img
            src={require("../../../src/images/footerblackline.png")}
            alt="footerblackline"
            className="footer_blackline_img"
          />
        </div>
        <Header />

        <div className="container-fluid content_main px-0">
          <div className="container">
            <div className="row">
              {/* ====== User Name And User Image section ======== */}
              <div className="d-none d-sm-none d-md-none d-lg-flex col-lg-4 col-xl-4 pt-4 pt-sm-4 pt-md-0 pt-lg-0 pt-xl-0 add_new_center_col">
                <Userdetailcard /> 
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 addnewreferraluserlist">
                <div className="myrefaluserlist mt-4">
                  <Row className="mt-2">
                        <div className="text-end mb-2">
                        <button className='verify_email'
                                onClick={(e) => { 
                                paymentoutrefrerral()
                              }}
                              >
                          Pay Out
                        </button>
                        </div>
                      <div className='col-lg-8'>
                        <div className="mt-1">
                          <h1 className="user_Detailspage_About">Referral</h1>
                            <p className="user_Detailspage_about_introduction mb-0 text-break">
                            credit: {siteSetting?.paymentSetting?.symbol}{userData.credits} 
                            </p>
                        </div>
                        <p className='email_number_birth_visible text-break my-2'>
                          {siteSetting?.siteSetting?.referral_slug}
                        </p>
                        <InputGroup className="mt-3" id="profileinputgroupreferrlcode">
                          <Form.Control
                            placeholder="Referral (KBDHNP)"
                            aria-label="Referral Code"
                            aria-describedby="basic-addon2"
                            value={userData.referral_link}
                          />
                          <InputGroup.Text id="basic-addon2" className='copyreferrllink' onClick={() => copyTorefrrallink(userData.referral_link)}><MdOutlineContentCopy fill='#fff'/></InputGroup.Text>
                          <InputGroup.Text id="basic-addon2" className='sharereferrllink' onClick={() => copyToClipboard(userData.referral_link)}><MdShare fill='#fff'/></InputGroup.Text>
                        </InputGroup>
                        {pageLinks.map((e) => {
                          return (
                            <Nav.Link as={Link} to={"/" + e.slug} className="text-danger mt-2">{e.title}</Nav.Link>
                          )
                        })}
                      </div>
                      <div className='col-lg-4 d-flex justify-content-lg-end'>
                        <div className="mt-2">
                          <h1 className="user_Detailspage_About">QR Code</h1>
                          <div className='d-flex align-items-center'>
                          <img src={userData?.qrCode ? userData?.qrCode : qrcodeimg } alt="img" className='setqrcodeimgwidth mr-2'/>
                          <div>
                            <a className='qrcodeshareoption'   
                            onClick={(e) => { e.preventDefault(); download(userData.qrCode); }}>
                            <IoMdDownload fill='#fff'/>
                            </a>
                            <div className='qrcodeshareoption mt-4'   
                              onClick={() => 
                              handleButtonClick(userData.qrCode)}>
                            <MdShare fill='#fff' />
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </Row>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-11 col-xl-10 text-center Blocked_User_section_title">
                    <h1 className="Blocked_User_title">
                    Referred Users{" "}
                      <span className="Blocked_User_Data_Count">
                        {referraluserData.length}
                      </span>
                    </h1>
                  </div>
                  <div className="col-11 col-md-12 col-lg-11 col-xl-10 mx-auto Blocked_User_section_slider px-0">
                    {cardSkeleton && (
                      <SkeletonTheme >
                        <div className=''>
                          <Skeleton height={50} count={10} className="mb-2" />
                        </div>
                      </SkeletonTheme>
                    )}
                    {referraluserData?.map((items) => {
                      return (
                        <div className="row mb-2 py-2 Blocked_User_main_row mx-0">
                          <div className="col-12 d-flex ">
                            <div className="Blocked_User_slider_first_section">
                              <div className="Blocked_User_img_section">
                                <Link to={"/user/" + items.username}>
                                <img
                                  src={items.image}
                                  className="Blocked_User_img"
                                  />
                                </Link>
                              </div>
                            </div>
                            <div className="Blocked_User_slider_second_section d-flex">
                              <div className="Blocked_User_slider_name_city_section pl-2 pl-md-0">
                                <span className="pl-0  pl-md-3 pl-lg-3 pl-xl-3 Blocked_User_slider_name text-break">
                                  <Link to={"/user/" + items.username} className='linkoverright text-dark mr-2'>
                                    {items.name}
                                  </Link>
                                  {items.is_plan_purchase == 0 ? <Badge bg="danger" className="bagesclassset">Unpaid</Badge> :<Badge bg="success" className="bagesclassset">Paid</Badge>}
                                </span>
                                <span className="pl-0 pl-md-3 pl-lg-3 pl-xl-3 Blocked_User_slider_city">
                                  {items.location}
                                </span>
                              </div>
                              <div className="visitors_list_thired_section">
                                <span className="visitors_list_user_just_view_profile">{formatDate(items.created_at)}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                       );
                    })} 
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FooterMobile />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Referraluser;
