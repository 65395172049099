import React, { useCallback, useEffect, useRef, useState } from 'react'
import Header from '../CommonComponet/Header'
import Footer from '../CommonComponet/Footer'
import "../../css/Visitors.css"
import { Tab, Tabs } from 'react-bootstrap'
import { UserVisitor, VistedByUserApi } from '../../api/Api'
import { Link } from 'react-router-dom'
import defaultImg from "../../images/user-profile-default-image.png";
import verifyIcon from '../../images/verified-icon.png';
import { useAuth } from '../../middleware/AuthProvider';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import FooterMobile from '../CommonComponet/FooterMobile'
import { Helmet } from 'react-helmet'
import Userdetailcard from '../CommonComponet/Userdetailcard'
import { timeAgo } from '../../helper/getCookie'

const Visitors = () => {

    const { AuthData, setAuthData } = useAuth();
    const [vistedByUserData, setVistedByUserData] = useState([])
    const [vistedByUserDataCount, setVistedByUserDataCount] = useState("")

    const [userVisitorData, setUserVisitorData] = useState([])
    const [userVisitorDataCount, setUserVisitorDataCount] = useState("")

    const [pagePublic, setPagePublic] = useState(1);
    const [pagePvt, setPagePvt] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingpvt, setIsLoadingpvt] = useState(false);
    const [hasMoreRecord, setHasMoreRecord] = useState(true);
    const [hasMoreRecordPvt, setHasMoreRecordPvt] = useState(true);

    const [cardSkeleton, setCardSkeleton] = useState(true);
    const [cardSkeletonMeUser, setCardSkeletonMeUser] = useState(true);
    const [yourvisitorsEncrypted, setYourvisitorsEncrypted] = useState(false)
    const [profilesvisitedEncrypted, setProfilesvisitedEncrypted] = useState(false)

    const divRef = useRef(null);
    const divRefpvt = useRef(null);


    useEffect(() => {
        document.title = "Visitors Page On Hukup Australia"
    }, [])


    useEffect(() => {
        VistedByUserApicall()
        UserVisitorApi()
    }, [])

    // === Your visitors ==
    const UserVisitorApi = (page = pagePvt) => {

        if (!hasMoreRecordPvt || isLoadingpvt) {
            return false;
        }
        var data = {
            page: page,
        };
        setIsLoadingpvt(true)
        UserVisitor(data).then(function (response) {
            setUserVisitorDataCount(response.data.totalVisitors)
            setYourvisitorsEncrypted(response.data.encryptedData)
            setPagePvt(page + 1)
            if (page == 1) {
                setUserVisitorData(response.data.data)
            }
            else {
                setUserVisitorData(userVisitorData.concat(response.data.data))

            }
            setIsLoadingpvt(false)
            setCardSkeleton(false)
            var userDatas = response.data.data;
            if (!userDatas.length) {
                setHasMoreRecordPvt(false);
            }
            else {
                setHasMoreRecordPvt(true);
            }

        })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    }

    // === User Visited ==
    const VistedByUserApicall = (page = pagePublic) => {

        if (!hasMoreRecord || isLoading) {
            return false;
        }
        var data = {
            page: page,
        };
        setIsLoading(true)

        VistedByUserApi(data).then(function (response) {
            setVistedByUserDataCount(response.data.totalVisitors)
            setProfilesvisitedEncrypted(response.data.encryptedData)
            setIsLoading(false)
            setCardSkeletonMeUser(false)
            if (page == 1) {
                setVistedByUserData(response.data.data)
            }
            else {
                setVistedByUserData(vistedByUserData.concat(response.data.data))
            }
            setPagePublic(page + 1)
            var userDatas = response.data.data;
            if (!userDatas.length) {
                setHasMoreRecord(false);
            }
            else {
                setHasMoreRecord(true);
            }
        })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    }

    const handleScroll = useCallback(() => {
        if (
            divRef.current &&
            divRef.current.scrollTop + divRef.current.clientHeight >=
            divRef.current.scrollHeight - 200 &&
            !isLoading
        ) {
            VistedByUserApicall();
        }

    }, [isLoading, pagePublic, vistedByUserData, hasMoreRecord]);

    useEffect(() => {
        if (divRef.current) {
            divRef.current.addEventListener("scroll", handleScroll);
        }
        return () => {
            if (divRef.current) {
                divRef.current.removeEventListener("scroll", handleScroll);
            }
        };
    }, [handleScroll]);

    // __________________________________
    const handleScrollPvtImg = useCallback(() => {
        if (
            divRefpvt.current &&
            divRefpvt.current.scrollTop + divRefpvt.current.clientHeight >=
            divRefpvt.current.scrollHeight - 200 &&
            !isLoadingpvt
        ) {
            UserVisitorApi();
        }

    }, [isLoadingpvt, pagePvt, userVisitorData, hasMoreRecordPvt]);

    useEffect(() => {
        if (divRefpvt.current) {
            divRefpvt.current.addEventListener("scroll", handleScrollPvtImg);
        }
        return () => {
            if (divRefpvt.current) {
                divRefpvt.current.removeEventListener("scroll", handleScrollPvtImg);
            }
        };
    }, [handleScrollPvtImg]);


    var Yourvisitors = <div className="visited_tab_title_name">Visited Me<span className="visitor_page_tab_counte ml-1 ml-md-2" >{userVisitorDataCount}</span></div>;
    var Profilesvisited = <div className="visited_tab_title_name" >I Visited<span className="visitor_page_tab_counte ml-1 ml-md-2" >{vistedByUserDataCount}</span></div>;


    const [key, setKey] = useState('Yourvisitors');
    return (
        <>
            <Helmet>
                <meta name="description" content={"Visitors Page On Hukup Australia"} />
            </Helmet>
            <div className="container-fluid p-0 main_section">
                <div className="background_section_main">
                    <div className="yellow_dot d-none d-sm-none d-md-none d-lg-block"></div>
                    <div className="green_dot d-none d-sm-none d-md-none d-lg-block"></div>
                    <div className="green_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
                    <div className="orange_secod_dot d-none d-sm-none d-md-none d-lg-block"></div>
                    <img alt='Whitedot' src={require("../../../src/images/Whitedot.png")} className="d-none d-sm-none d-md-none d-lg-block Whitedot_first" />
                    <img alt='Whitedot' src={require("../../../src/images/Whitedot.png")} className="d-none d-sm-none d-md-none d-lg-block Whitedot_second" />
                    <img alt='smileemoji' src={require("../../../src/images/Visitors/smileemoji.png")} className="visiter_smileemoji_img d-none d-sm-none d-md-none d-lg-block" />
                    <img alt='CurveBlackline' src={require("../../../src/images/Visitors/CurveBlackline.png")} className="CurveBlackline_img d-none d-sm-none d-md-none d-lg-block" />
                    <img alt='orangeline' src={require("../../../src/images/orangeline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_orangeline" />
                    <img alt='greenline' src={require("../../../src/images/greenline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_greenline" />
                    <img alt='Ellipse' src={require("../../../src/images/Ellipse.png")} className="footer_ellipse_img" />
                    <img alt='footerblackline' src={require("../../../src/images/footerblackline.png")} className="footer_blackline_img" />
                </div>
                <Header />

                <div className="container-fluid content_main px-0">
                    <div className="container visitor_page_main_section">
                        <div className="row">
                            {/* ====== User Name And User Image section ======== */}
                            <div className="d-none d-sm-none d-md-none d-lg-flex col-lg-4 col-xl-4 pt-4 pt-sm-4 pt-md-0 pt-lg-0 pt-xl-0 add_new_center_col">
                                <Userdetailcard />
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                                <div className="row">
                                    <Tabs
                                        activeKey={key}
                                        onSelect={(k) => setKey(k)}
                                        className="my-2 px-1"
                                    >
                                        <Tab eventKey="Yourvisitors" title={Yourvisitors} className=" ">
                                            <div className="col-12 visiter_section_slider px-0" ref={divRefpvt}>
                                                {cardSkeleton && (
                                                    <SkeletonTheme >
                                                        <div className='px-3 px-md-0'>
                                                            <Skeleton height={50} count={10} className='mb-2' />
                                                        </div>
                                                    </SkeletonTheme>
                                                )}
                                                {
                                                    userVisitorData.map((items) => {
                                                        if(items?.hide_emy_track === 1){
                                                          return false;
                                                        }
                                                        return (
                                                            <div className="mx-auto col-12 mb-2  px-md-1">
                                                                <div className="d-flex  visitors_list_main_section ">
                                                                    <div className="visitors_list_first_section my-auto">
                                                                        <div className="mx-auto visitors_list_first_main_section" >
                                                                            <Link className='linkoverright visitor_user_icon' to={"/" + "user/" + items.username} >
                                                                                <img alt='Profile' src={items.thumbnail_image} className="visitors_list_first_icon" />
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                    <div className="visitors_list_second_section">
                                                                        <div className="visitors_list_second_section_common" >
                                                                        <div className='d-flex align-items-center'>
                                                                            <h4 className="d-flex align-items-center mb-0 visitors_list_user_name visitors_list_user_name_custom_width" >
                                                                                <Link className='linkoverright text-dark' to={"/" + "user/" + items.username} >
                                                                                    {items.name}
                                                                                </Link>
                                                                            </h4>
                                                                                    {items.userProfileVerify === 1 && <img alt='Verify' src={verifyIcon} className='user_verify-icon-home'></img>}
                                                                            </div>
                                                                            {items.just_now && (
                                                                                <span className="visitors_list_user_just_view_profile">just view your profile</span>
                                                                            )}
                                                                        </div>
                                                                        <div className="visitors_list_second_section_common"> <p className="visitors_list_city_country my-0">{items.location}</p></div>
                                                                    </div>
                                                                    <div className="visitors_list_thired_section">
                                                                        <span className="visitors_list_user_just_view_profile">{timeAgo(items.visitor_updated_at_date)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                                {(userVisitorDataCount == 0 && !cardSkeleton) ? <img alt='Profile' src={require("../../images/Visitors/VisitorObject.png")} className="visitors_is_non_img" /> : ""}
                                                {/* fake data  */}
                                                {
                                                    yourvisitorsEncrypted && (() => {
                                                        const renderedFakeData = [];
                                                        for (let index = 0; index < userVisitorDataCount - userVisitorData.length; index++) {
                                                            renderedFakeData.push(
                                                                <div key={index} className="mx-auto col-12 mb-2 px-md-1">
                                                                    <Fakedatavisitercard />
                                                                </div>
                                                            );
                                                        }
                                                        return renderedFakeData;
                                                    })()}
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Profilesvisited" title={Profilesvisited} className=" ">
                                            <div className="col-12 visiter_section_slider px-0" ref={divRef}>
                                                {cardSkeletonMeUser && (
                                                    <SkeletonTheme >
                                                        <div className='px-3 px-md-0'>
                                                            <Skeleton height={50} count={10} className='mb-2' />
                                                        </div>
                                                    </SkeletonTheme>
                                                )}
                                                {
                                                    vistedByUserData.map((items) => {
                                                        return (
                                                            <div className="mx-auto col-12 mb-2  px-md-1">
                                                                <div className="d-flex  visitors_list_main_section ">
                                                                    <div className="visitors_list_first_section my-auto">
                                                                        <div className="mx-auto visitors_list_first_main_section" >
                                                                            <Link className='linkoverright visitor_user_icon' to={"/" + "user/" + items.username} >
                                                                                <img alt='Profile' src={items.thumbnail_image} className="visitors_list_first_icon" />
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                    <div className="visitors_list_second_section">
                                                                        <div className="visitors_list_second_section_common">
                                                                            <div className='d-flex align-items-center'>
                                                                            <h4 className="d-flex align-items-center mb-0 visitors_list_user_name visitors_list_user_name_custom_width">
                                                                                <Link className='linkoverright text-dark ' to={"/" + "user/" + items.username} >
                                                                                    {items.name}
                                                                                </Link>
                                                                            </h4>
                                                                                    {items.userProfileVerify === 1 && <img alt='Verify' src={verifyIcon} className='user_verify-icon-home'></img>}
                                                                            </div>
                                                                            {items.just_now && (
                                                                                <span className="visitors_list_user_just_view_profile">You just view profile</span>
                                                                            )}
                                                                        </div>
                                                                        <div className="visitors_list_second_section_common"><p className="visitors_list_city_country my-0">{items.location}</p></div>
                                                                    </div>
                                                                    <div className="visitors_list_thired_section">
                                                                        <span className="visitors_list_user_just_view_profile">{timeAgo(items.visitor_updated_at_date)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {(vistedByUserDataCount == 0 && !cardSkeletonMeUser) ? <img alt='Profile' src={require("../../images/Visitors/VisitorObject.png")} className="visitors_is_non_img" /> : ""}
                                                {/*  fake data  */}
                                                {
                                                    profilesvisitedEncrypted && (() => {
                                                        const UserrenderedFakeData = [];
                                                        for (let index = 0; index < vistedByUserDataCount - vistedByUserData.length; index++) {
                                                            UserrenderedFakeData.push(
                                                                <div key={index} className="mx-auto col-12 mb-2 px-md-1">
                                                                    <Fakedatavisitercard />
                                                                </div>
                                                            );
                                                        }
                                                        return UserrenderedFakeData;
                                                    })()}
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterMobile />
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Visitors


// ------ fake data componet  ------
const Fakedatavisitercard = () => {
    return (
        <>
            <div className="d-flex  visitors_list_main_section ">
                <div className="visitors_list_first_section my-auto blur_name_like_fake_contain">
                    <div className="mx-auto visitors_list_first_main_section" >
                        <Link className='linkoverright visitor_user_icon'>
                            <img alt='Profile' src={defaultImg} className="visitors_list_first_icon" />
                        </Link>
                    </div>
                </div>
                <div className="visitors_list_second_section blur_name_like_fake_contain">
                    <div className="visitors_list_second_section_common">
                        <h4 className="mb-0 visitors_list_user_name">
                            <Link className='linkoverright text-dark'>
                                Prit
                            </Link>
                        </h4>
                    </div>
                    <div className="visitors_list_second_section_common"><p className="visitors_list_city_country my-0">rajkot</p></div>
                </div>
                <div className="visitors_list_thired_section blur_name_like_fake_contain">
                    <span className="visitors_list_user_just_view_profile">April 02 2024, 06:21 am</span>
                </div>
            </div>
        </>
    )
}