import React, { useEffect, useState } from 'react'
import "../../css/UserDetailspage.css"
import Rectangle from "../../images/nophoto.png"
import { LuSend } from "react-icons/lu";
import usersetaileslocation from "../../images/UserDetailspage/usersetaileslocation.png"
import heartuserdetailes from "../../images/UserDetailspage/heartprofile.png"
import heartlikeuserdetailes from "../../images/UserDetailspage/heartprofilelike.png"
import staruserdetailes from "../../images/UserDetailspage/staruserdetailes.png"
import staricon from "../../images/UserDetailspage/Star.png"
import privateImg from "../../images/privare_image.png"
import privateImglock from "../../images/lock.png"
import GenderMale from "../../images/UserDetailspage/GenderMale.png"
import Calendar from "../../images/UserDetailspage/Calendar.png"
import Favourites2 from "../../images/UserDetailspage/Star2.png"
import verifyIcon from '../../images/verified-icon.png';

import { MdOutlineInfo } from "react-icons/md";

import Footer from '../CommonComponet/Footer';
import Header from '../CommonComponet/Header';
import { Button, Col, Dropdown, Form, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { AccessUserPrivateGallary, BlockAndUnblockUser, DropdownListReport, FavoritesUserApi, LikeUserApi, PreMessageEditApi, PreMessageViewApi, ReportUser, sendDefaultMessage, UserDataApi, UserDetailesNoteApi } from '../../api/Api'
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../middleware/AuthProvider';
import { HiDotsVertical } from 'react-icons/hi';
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useSocket } from '../../middleware/SocketProvider';
import DataLoader from '../../components/ScreenLoader/DataLoader';
import MembershipModel from '../CommonComponet/MembershipModel';
import FooterMobile from '../CommonComponet/FooterMobile';
import { FaPlay } from 'react-icons/fa6';
import { Helmet } from 'react-helmet';
import { FaTelegramPlane } from 'react-icons/fa';


const UserDetailspage = () => {
  const { AuthData } = useAuth();
  const { username } = useParams()
  const { Socket } = useSocket();
  const [isDataLoader, setisDataLoader] = useState(false);
  const [userData, setUserdata] = useState({})
  const [reportDataList, setReportDataList] = useState([])
  const [preTypeMessages, setPreTypeMessages] = useState([])
  const [isLiked, setIsLiked] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const [isBlockAndDelete, setIsBlockAndDelete] = useState(false);
  const [reason, SetReason] = useState("");
  const [reportType, setReportType] = useState("");
  const [membershipModelShow, setMembershipModelShow] = useState(false);
  const [preTypeMessageModalShow, setPreTypeMessageModalShow] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(0);
  const [selectedMessageError, setSelectedMessageError] = useState("");

  const [addnote, setAddnote] = useState("")

  const [erroraddnote, setErrorAddnote] = useState("")
  const [errorreason, SetErrorReason] = useState("");


  const [modalShow, setModalShow] = useState(false);
  const [blockUserModal, setBlockUserModal] = useState(false);
  const [addNoteModal, setAddNoteModal] = useState(false);

  const [allowGallaryAccess, setAllowGallaryAccess] = useState(false);

  const [skeletonUserImage, setSkeletonUserImage] = useState(true);


  const handleClose = () => {
    setModalShow(false)
    SetErrorReason("")
  }
  const handleBlockUserClose = () => {
    setBlockUserModal(false)
  }
  const handleAddNoteClose = () => {
    setAddnote(userData.note)
    setAddNoteModal(false)
    setErrorAddnote("")
  }

  const handleopenAddNote = () => {
    setAddnote(userData.note)
    setAddNoteModal(true)
  }

  const copyUserLink = () => {
    const urlToCopy = window.location.href;
    navigator.clipboard
      .writeText(urlToCopy)  // Use Clipboard API to copy text
    Store.addNotification({
      message: "Profile link copied to clipboard",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__zoomIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 2000,
        onScreen: false,
      },
    });
  }

  useEffect(() => {
    if (username == AuthData.username) {
      navigate("/my-profile")
    }
    getUserData();
    DropdownListReportApi()

    return () => {
      Socket.off("like-change-" + AuthData.id)
    }
  }, [])



  const getUserData = () => {
    UserDataApi(username).then(function (response) {
      if (response.data.visit_limit_over && !response.data.status) {
        Store.addNotification({
          title: "View Profile Limit",
          message: response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
        navigate("/home")
        return false
      }
      if (response.data.visit_limit_over || !response.data.status) {
        Store.addNotification({
          message: response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
        navigate("/home")
        return false
      }
      setUserdata(response.data.data)
      setSkeletonUserImage(false)
      Socket.off("like-change-" + AuthData.id)
      Socket.on("like-change-" + AuthData.id, (data) => {
        if (data.liked_user_id == response.data.data.id) {
          setIsLiked(data.is_like)
        }
      })

      const specifiedDate = userData.lastLoginDate;
      setIsFavourite(response.data.data.is_favourites)
      setIsLiked(response.data.data.isLiked)
      setAddnote(response.data.data.note)
      setAllowGallaryAccess(response.data.data.gallery_permission)
    })
      .catch(function (error) {
        console.log(error);
      });
  }


  const AllowGallaryAccessApi = (id) => {
    var data = {
      userId: id,
    }
    AccessUserPrivateGallary(data).then(function (response) {
      if (response.data.status == true) {
        Store.addNotification({
          message: response.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
        setAllowGallaryAccess(!allowGallaryAccess)
      } else {
        Store.addNotification({
          message: response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
    })
      .catch(function (error) {
        console.log(error);
      });
  }

  const LikedApi = (id) => {
    var likeData = {
      liked_user_id: id,
    };
    Socket.off("liked-response-" + id);
    Socket.emit("like", likeData);
    Socket.on("liked-response-" + id, (data) => {
      if (data.status == false) {
        Store.addNotification({
          message: data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      } else {
        setIsLiked(!isLiked)
      }
    });
  }

  const AddFav = (id) => {
    var favouriteData = {
      favourite_user_id: id,
    };
    Socket.off("favourites-response-" + id);
    Socket.emit("favourite", favouriteData);
    Socket.on("favourites-response-" + id, (data) => {
      if (data.status == false) {
        Store.addNotification({
          message: data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__zoomIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: false
          }
        });
      }
      else {
        setIsFavourite(!isFavourite)
      }
    });
  };



  const ReportUserApi = (id) => {
    setisDataLoader(true)
    SetErrorReason("")
    let valid = true;

    if (reason == "" || reason?.trim() == "") {
      SetErrorReason("Enter reason")
      valid = false;
    }

    if (valid) {
      var data = {
        reported_user_id: id,
        reason: reason,
        type: reportType,
        report_from: "profile",
        block_and_delete_chat: isBlockAndDelete
      };
      ReportUser(data).then(function (response) {
        setisDataLoader(false)
        SetErrorReason("")
        SetReason("");
        setReportType("")
        handleClose()
        if (response.data.status == true) {
          Store.addNotification({
            // title: response.data.message,
            message: response.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
          if (isBlockAndDelete) {
            navigate("/home")
          }
        }
        else {
          Store.addNotification({
            // title: response.data.message,
            message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
        }
      })
        .catch(function (error) {
          setisDataLoader(false)
          console.log(error);
        });
    }
    setisDataLoader(false)
  }

  const BlockUserApi = (id) => {
    var data = {
      blocked_user_id: id
    }
    BlockAndUnblockUser(data)
      .then(function (response) {
        handleBlockUserClose()
        if (response.data.status == true) {
          Store.addNotification({
            // title: response.data.message,
            message: response.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
          navigate("/home")

        }
        else {
          Store.addNotification({
            // title: response.data.message,
            message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
        }
        // navigate("/home")
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const UserNote = (id) => {

    let valid = true;
    setErrorAddnote("")

    if (addnote == "" || addnote?.trim() == "") {
      setErrorAddnote("Add note")
      valid = false;
    }

    if (valid) {
      var data = {
        noted_user_id: id,
        note: addnote,
      }

      UserDetailesNoteApi(data)
        .then(function (response) {
          handleAddNoteClose()
          setAddnote(addnote)
          setUserdata({ ...userData, note: addnote })
          if (response.data.status == true) {
            Store.addNotification({
              // title: response.data.message,
              message: response.data.message,
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__zoomIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: false
              }
            });
          }
          else {
            Store.addNotification({
              // title: response.data.message,
              message: response.data.message,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__zoomIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
                onScreen: false
              }
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  const setDefaultMessage = (e) => {
    e.preventDefault();

    setSelectedMessageError("")

    if (!selectedMessage) {
      setSelectedMessageError("Please select message")
      return false
    }

    const pre_type_message_data = preTypeMessages.find((e) => {
      return e.id == selectedMessage
    });
    if(!pre_type_message_data) {
      setSelectedMessageError("Message not found");
      return false
    }

    var data = {
      message: pre_type_message_data.message,
      is_default: true,
    }

    PreMessageEditApi(selectedMessage, data)
      .then(function (response) {
        setPreTypeMessageModalShow(false);
        setUserdata({ ...userData, pre_type_message: pre_type_message_data.message });
        if (response.data.status == true) {
          Store.addNotification({
            // title: response.data.message,
            message: response.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
        }
        else {
          Store.addNotification({
            // title: response.data.message,
            message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const DropdownListReportApi = () => {
    DropdownListReport().then(function (response) {
      setReportDataList(response.data.data)
      setReportType(response.data.data[0]?.report_user_type);

    })
      .catch(function (error) {
        console.log(error);
      });
  }

  const navigate = useNavigate();

  function DateTimeDisplay(inputDate) {

    const currentDate = new Date();
    const specifiedDateTime = new Date(inputDate);

    const timeDifference = currentDate - specifiedDateTime;
    const hoursDifference = Math.floor(timeDifference / (60 * 60 * 1000));
    const minutesDifference = Math.floor((timeDifference % (60 * 60 * 1000)) / (60 * 1000));

    const isWithin24Hours = Math.abs(hoursDifference) <= 24;

    if (isWithin24Hours) {
      if (hoursDifference === 0 && minutesDifference === 0) {
        return "Just now";
      } else {
        return hoursDifference === 0
          ? `${minutesDifference} min ago`
          : `${hoursDifference} hr ago`;
      }
    } else {
      // Calculate the difference in days
      const daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
      return `${daysDifference} days ago`;
    }
  }


  const tooltip = (
    <Tooltip id="tooltip" className="userdetaile_tooltip" >
      {userData.note}
    </Tooltip>
  );

  const alertForGallery = () => {
    Store.addNotification({
      message: "You have not uploaded a private gallery",
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__zoomIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 2000,
        onScreen: false
      }
    });
  }


  useEffect(() => {
    document.title = `${username} profile On Hukup Australia`;
  }, [username]);

  const SendMeaasgeApi = async () => {
    let IsSendDefaultMessage = false;
    if(userData.pre_type_message) {
      IsSendDefaultMessage = true;
    }
    else {
      const response = await PreMessageViewApi();
      setPreTypeMessages(response.data.data);
      if(!response.data.data.length) {
        IsSendDefaultMessage = true;
      }
      else {
        setPreTypeMessageModalShow(true);
        setSelectedMessageError("");
      }
    }
    if(IsSendDefaultMessage) {
      var data = {
        receiver_id: userData.id
      }
      sendDefaultMessage(data).then(function (response) {
        if (response.data.status == true) {
          Store.addNotification({
            // title: response.data.message,
            message: "Message sent successfully.",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
        }
        else {
          Store.addNotification({
            // title: response.data.message,
            message: response.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__zoomIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: false
            }
          });
        }
      })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          // always executed
        });
    }

  }

  return (
    <>
      {isDataLoader && (
        <DataLoader />
      )}

      <Helmet>
        <meta name="description" content={`${username} profile On Hukup Australia`} />
      </Helmet>

      <div className="container-fluid p-0 main_section">
        <div className="background_section_main">
          <img alt='orangeline' src={require("../../../src/images/orangeline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_orangeline" />
          <img alt='greenline' src={require("../../../src/images/greenline.png")} className="d-none d-sm-none d-md-none d-lg-block footer_greenline" />
          <img alt='Ellipse' src={require("../../../src/images/Ellipse.png")} className="footer_ellipse_img" />
          <img alt='footerblackline' src={require("../../../src/images/footerblackline.png")} className="footer_blackline_img" />
        </div>
        <Header />
        <div className="user_Detailspage_main_section">
          <div className="container">
            <div className="row mt-4 ">

              <div className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4">
                <div className="row">
                  <div className="col-12 position-relative">
                    <div className="mx-auto user_Detailspage_user_img_section">
                      {skeletonUserImage ? (
                        <SkeletonTheme >
                          <div>
                            <Skeleton height={410} />
                          </div>
                        </SkeletonTheme>
                      ) :
                        <>
                          <img alt='Profile' src={userData.thumbnail_image ? userData.thumbnail_image : Rectangle} className="user_Detailspage_user_img" />
                          <div className="heart_star_main_section pl-0">
                            <div className="mx-2 close_icon_section  " onClick={() => navigate("/messages?username=" + userData.username)}><LuSend className="user_Detailspage_luSend" /></div>
                            <div className="mx-2 heart_icon_section  " onClick={() => LikedApi(userData.id)}><img src={isLiked ? heartlikeuserdetailes : heartuserdetailes} /></div>
                            <div className="mx-2 star_icon_section  " onClick={() => AddFav(userData.id)}><img src={isFavourite ? staruserdetailes : staricon} /></div>

                          </div>
                        </>
                      }
                    </div>
                  </div>
                  <div className="col-12">
                    {skeletonUserImage ? (
                      <SkeletonTheme >
                        <div className='mt-3 px-3'>
                          <Skeleton height={30} />
                        </div>
                      </SkeletonTheme>
                    ) :
                      <>
                        <div className="text-center mx-auto  d-flex align-items-center justify-content-center mt-5 " >
                          <h1 className="UserDetails_title_name text-break">{userData.name}
                            {userData.userProfileVerify === 1 && <img alt='Verify' src={verifyIcon} className='user_verify-icon'></img>}
                          </h1>
                        </div>
                        <div className="text-center mx-auto  d-flex align-items-center justify-content-center" >
                          <button className='message-de' onClick={() => SendMeaasgeApi()}>
                            <FaTelegramPlane /> 1 click message
                          </button>
                        </div>
                      </>
                    }
  

                    <div id="userdetaile_switch"
                      onClick={userData.private_image_uploaded ? null : () => alertForGallery()}
                      className="d-flex align-items-center justify-content-between mt-3 mx-auto userdetila_switch_gallery">
                      <label className="form-check-label  mx-md-0 mx-lg-auto Allow_Private_lable" >Allow Private Gallary Access</label>
                      <span className="form-check form-switch mx-md-0 mx-lg-auto">
                        <input className="form-check-input"
                          type="checkbox"
                          role="switch"
                          checked={userData.private_image_uploaded ? allowGallaryAccess : false}
                          onChange={() => AllowGallaryAccessApi(userData.id)}
                          disabled={userData.private_image_uploaded ? false : true}
                        />
                      </span>
                    </div>

                    {userData?.images?.length ?
                      <div className="d-flex justify-content-between align-items-center mt-3 mx-auto UserDetails_title_Gallery_main_section" >
                        <h1 className="UserDetails_title_Gallery mb-0">Gallery</h1>
                        <h1 className="UserDetails_title_Seeall mb-0" onClick={() => navigate("/usergallery/" + userData?.username)}>See all</h1>
                      </div> : ""
                    }
                    {skeletonUserImage ? (
                      <SkeletonTheme >
                        <Row>
                          {[1, 2, 3, 4].map((ele) => {
                            return (
                              <Col className='mt-2'>
                                <Skeleton height={70} />
                              </Col>
                            )
                          }
                          )}
                        </Row>
                      </SkeletonTheme>
                    ) :
                      <div className="d-flex mx-auto mt-3 UserDetails_gallery_main_section">
                        {userData?.images?.map((e) => {
                          return (
                            <div className="UserDetails_gallery_img_section mx-1" onClick={() => navigate("/usergallery/" + userData?.username)}>
                              {(e.type === "image" || (e.is_private && e.url == "")) ?
                                <>
                                  <img alt='Gallary' src={e.url} onError={(element) => {
                                    element.target.src = privateImg
                                  }} className="UserDetails_gellery_img" />
                                  {
                                    (e.is_private && e.url == "") && (
                                      <img alt='Lock' className='imglock' src={privateImglock} />
                                    )
                                  }
                                </> :
                                <div className="videoPlay w-100 h-100">
                                  <video className='UserDetails_gellery_img' >
                                    <source src={e.url} type="video/mp4" />
                                  </video>
                                  <span className="play_icon_userdetail_silde">
                                    <FaPlay />
                                  </span>
                                </div>
                              }
                            </div>
                          )
                        })}
                      </div>
                    }
                  </div>
                </div>
              </div>

              {/* ======= user details section ============= */}
              <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8  pr-0 pl-0 pl-sm-0 pl-md-0 pl-lg-5 pl-xl-5 mt-4 mt-sm-4 mt-md-0 mt-lg-0 mt-xl-0">
                <div className="user_Detailspage_all_Details_secondsection">
                  <Row className="mb-2">
                    <div className='col-10 col-md-10 col-lg-10'>
                    </div>
                    <div className='col-2 col-md-2 col-lg-2 pr-1'>
                      <div className="d-flex justify-content-end align-items-start">
                        {
                          !userData.note == " " ?
                            <OverlayTrigger placement="bottom" overlay={tooltip} >
                              <Button className="user_detailes_page_tooltip_btn py-0"><MdOutlineInfo className="user_detailes_page_tooltip_icon" /></Button>
                            </OverlayTrigger>
                            :
                            ""
                        }

                        <Dropdown className=" Report_blocked_add_dote p-0 text-end ">
                          <Dropdown.Toggle id="dropdown-basic" >
                            <span >
                              <HiDotsVertical />
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="p-0 Dropdown_Menu_user_details">
                            {userData.share_my_profile ?
                              <Dropdown.Item className="text-center" onClick={() => copyUserLink()}>Share Profile</Dropdown.Item>
                              : ""
                            }
                            <Dropdown.Item className="text-center" onClick={() => setModalShow(true)}>Report User</Dropdown.Item>
                            <Dropdown.Item className="text-center" onClick={() => setBlockUserModal(true)}>Block User</Dropdown.Item>
                            <Dropdown.Item className="text-center" onClick={handleopenAddNote}>{addnote != "" ? "Update Note" : "Add Note"}</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </Row>
                  <Row className="">
                    <div className='col-12'>
                      <div className='d-block d-flex align-items-center justify-content-between'>
                        <div className='user_Detailspage_location_para_main'>
                          <h1 className="user_Detailspage_location_title mb-0 my-2">Location</h1>
                          {skeletonUserImage ? (
                            <SkeletonTheme >
                              <div>
                                <Skeleton />
                              </div>
                            </SkeletonTheme>
                          ) :
                            <p className="user_Detailspage_location_name my-2 text-break">{userData.location}</p>
                          }
                        </div>
                        <div className="user_Detailspage_kilometer_main_section">
                          <div className="user_Detailspage_kilometer_section ml-2">
                            <img alt='Location' src={usersetaileslocation} className="user_Detailspage_grlocation" />
                            {skeletonUserImage ? (
                              <SkeletonTheme >
                                <Skeleton />
                              </SkeletonTheme>
                            ) :
                              <span className="ml-2 user_Detailspage_kilometer_title">
                                {userData?.hide_exact_location === 1 ?
                                  `${Math.ceil(userData?.distance)} KM` :
                                  userData?.distance < 1 ? ` ${(userData?.distance * 1000)} M` : ` ${userData?.distance} KM`}
                              </span>
                            }
                          </div>
                          {AuthData.is_premium ? (
                            (
                              userData.lastLoginDate ? (
                                <div className="d-flex my-2">
                                  <p className="m-0 d-flex align-items-center last_seen_main_section">
                                    {userData?.is_online == 1 ? (
                                      <>
                                        <span className="mr-2 last_seen_dot_section_active"></span>
                                        <span>Online Now</span>
                                      </>
                                    ) : (

                                      <div className="d-flex flex-column align-items-end">
                                        <div className="d-flex align-items-center">
                                          <p className="mr-2 mb-0 last_seen_dot_section"></p>
                                          <p className="mb-0 userd_Last_seen_online">Last seen online</p>
                                        </div>
                                        <p className="mb-0 userd_Last_seen_online">{DateTimeDisplay(userData.lastActivityDate)}</p>
                                      </div>

                                    )}
                                  </p>
                                </div>
                              ) : (
                                <></>
                              )
                            )
                          ) : (<>
                            <p className="my-1 text-end userd_Last_seen_online">Last seen online<p className="userDetail_premium_link" onClick={() => setMembershipModelShow(true)}>Upgrade To View</p></p>
                          </>)
                          }
                        </div>
                      </div>
                    </div>
                  </Row>

                  {skeletonUserImage && (
                    <SkeletonTheme >
                      <div className="mt-4">
                        <h1 className="user_Detailspage_About">About</h1>
                        <Skeleton />
                      </div>
                    </SkeletonTheme>
                  )}

                  {(userData.description !== "" && userData.description?.trim() != "" && userData.description) &&
                    <div className="">
                      <h1 className="user_Detailspage_About">About</h1>
                      <p className="user_Detailspage_about_introduction">{userData.description}</p>
                    </div>
                  }

                  {
                    userData.i_am_here_to_value?.length ? (
                      <div className=" ">
                        <h1 className="user_Detailspage_About my-2">Here To</h1>
                        <div className="d-flex flex-wrap">
                          {
                            userData?.i_am_here_to_value?.map((item) => {
                              return (
                                <div className={"user_Detailspage_Interests_section mr-2 my-2"}>
                                  <span>{item}</span>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    ) : (
                      <></>
                    )
                  }

                  {skeletonUserImage && (
                    <SkeletonTheme >
                      <div className="mt-4">
                        <h1 className="user_Detailspage_About">Interests</h1>
                        <Skeleton count={3} />
                      </div>
                    </SkeletonTheme>
                  )}
                  {
                    userData.interest?.length ? (
                      <div className=" ">
                        <h1 className="user_Detailspage_About my-2">Interests</h1>
                        <div className="d-flex flex-wrap">
                          {
                            userData?.interest?.map((item) => {
                              var intrestClass = "user_Detailspage_Interests_section mr-2 my-2";
                              if (AuthData.interest.find((ele) => ele.id == item.id)) {
                                intrestClass += " selected";
                              }
                              return (
                                <div className={intrestClass}>
                                  <img alt='Interesr' className="iconintrest" src={item.image} />{" "}
                                  <span>{item.name}</span>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                  {
                    userData.is_couple == 0 ?
                      <NormalUserProfile skeletonUserImage={skeletonUserImage} userData={userData} />
                      : <PartnerProfile skeletonUserImage={skeletonUserImage} userData={userData} />

                  }
                </div>
              </div>
            </div>
          </div>
          <FooterMobile />
        </div>

        {/* ======== Report user Modal ========= */}
        <Modal aria-labelledby="contained-modal-title-vcenter" centered
          show={modalShow}
          onHide={handleClose}
        // size="md"
        >
          <div className="Userdetails_modal_main_section text-center">
            <h4 className="Userdetails_modal_title">Report {userData.name} ?</h4>
            <p className="Userdetails_modal_report_resone mt-3">Why are you reporting this account ?</p>
            <Form>
              <Form.Group>
                <Form.Select
                  className="form-control Userdetails_modal_selector"
                  id="match only"
                  name="match_only"
                  value={reportType} onChange={(e) => setReportType(e.target.value)}
                >
                  {reportDataList.map((e) => {
                    return (
                      <>
                        <option value={e.report_user_type}>{e.report_user_type}</option>
                      </>
                    )
                  })}
                </Form.Select>
              </Form.Group>
              <Form.Group className="my-3" controlId="exampleForm.ControlTextarea1">
                <Form.Control as="textarea" placeholder="Reason.." rows={2} className="Userdetails_modal_textarea" value={reason} onChange={(e) => SetReason(e.target.value)} />
                <span className="text-danger">{errorreason}</span>
              </Form.Group>
              <div className="form-check my-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={isBlockAndDelete}
                  id="Block and delete chat"
                  onChange={(e) => setIsBlockAndDelete(!isBlockAndDelete)}
                />
                <label class="form-check-label" for="Block and delete chat">
                  Block and delete chat
                </label>
              </div>
              <div className="mb-3">
                <Button className="Userdetails_modal_btn_Report_user" onClick={(e) => ReportUserApi(userData.id)} >Report user</Button>
              </div>
              <div className="">
                <Button className="Userdetails_modal_btn_Cancel" onClick={() => handleClose()} >Cancel</Button>
              </div>
            </Form>
          </div>
        </Modal>

        {/* ======== block user Modal ========= */}
        <Modal aria-labelledby="contained-modal-title-vcenter" centered
          show={blockUserModal}
          onHide={handleBlockUserClose}
        >
          <div className="Userdetails_modal_main_section text-center">
            <Form>
              <h4 className="Userdetails_modal_title pb-3">Are you sure want to block {userData.name}?</h4>

              <div className="mb-3">
                <Button className="Userdetails_modal_btn_Report_user" onClick={(e) => BlockUserApi(userData.id)}>Yes</Button>
              </div>
              <div className="">
                <Button className="Userdetails_modal_btn_Cancel" onClick={() => handleBlockUserClose()}>Cancel</Button>
              </div>
            </Form>
          </div>
        </Modal>

        {/* ======== add Note Modal ========= */}
        <Modal aria-labelledby="contained-modal-title-vcenter" centered
          show={addNoteModal}
          onHide={handleAddNoteClose}
        >
          <div className="Userdetails_modal_main_section text-center">
            <Form>
              <Form.Group className="my-3" controlId="exampleForm.ControlTextarea1">
                <Form.Control as="textarea" placeholder="Add Note.." rows={2} className="Userdetails_modal_textarea" value={addnote} onChange={(e) => setAddnote(e.target.value)} />
                <span className="text-danger">{erroraddnote}</span>
              </Form.Group>
              <div className="mb-3">
                <Button className="Userdetails_modal_btn_Report_user" onClick={(e) => UserNote(userData.id)}>{userData.note != "" ? "Update Note" : "Add Note"}</Button>
              </div>
              <div className="">
                <Button className="Userdetails_modal_btn_Cancel" onClick={() => handleAddNoteClose()}>Cancel</Button>
              </div>
            </Form>
          </div>
        </Modal>

        {/* ======== Pre Type Message Modal ========= */}
        <Modal aria-labelledby="contained-modal-title-vcenter" centered
          show={preTypeMessageModalShow}
          onHide={() => {
            setPreTypeMessageModalShow(false)
          }}
        >
          <div className="Userdetails_modal_main_section text-center">
            <h4 className="Userdetails_modal_title">Set as 1 Click</h4>
            <Form>
              {
                preTypeMessages.map((preTypeMessage) => {
                  return (
                    <div className="form-check pb-3 d-flex align-items-center justify-content-between w-100 offcanvas_checkbox_primeam">
                      <p className="p-0 m-0">
                        {preTypeMessage.message}
                      </p>
                      <input
                        type="checkbox"
                        value={preTypeMessage.id}
                        name={preTypeMessage.id}
                        id={"pre_type_" + preTypeMessage.id}
                        onChange={(e) => {
                          let { checked } = e.target;
                          if(checked) {
                            setSelectedMessage(preTypeMessage.id)
                          }
                        }}
                        checked={preTypeMessage.id == selectedMessage}
                      />
                    </div>
                  )
                })
              }
              <div className="mb-3">
                <p className="text-start errorClass">{selectedMessageError}</p>
                <Button className="Userdetails_modal_btn_Report_user" onClick={setDefaultMessage}>Set as Default</Button>
              </div>
              <div className="">
                <Button className="Userdetails_modal_btn_Cancel" onClick={() => setPreTypeMessageModalShow(false)}>Cancel</Button>
              </div>
            </Form>
          </div>
        </Modal>

        <MembershipModel
          show={membershipModelShow}
          onHide={() => setMembershipModelShow(false)} />

        <Footer />
      </div>
    </>
  )
}


const NormalUserProfile = ({ skeletonUserImage, userData }) => {
  return (
    <>
      <div className="mt-4 container mb-2">
        {skeletonUserImage ? (
          <SkeletonTheme >
            <div>
              <Skeleton />
            </div>
          </SkeletonTheme>
        ) :
          <div className="row">
            <h1 className="user_Detailspage_About p-0 text-break">{userData.name}’s Info</h1>
          </div>
        }
        <div className="row">
          {skeletonUserImage ? (
            <SkeletonTheme >
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pl-0">
                <Skeleton count={7} />
              </div>
            </SkeletonTheme>
          ) :
            <>
              {userData?.user_info?.length != 0 && (
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pl-0 pb-4 pb-lg-0 order-last order-lg-first" >
                  <UserInfoComponent dataInfo={userData?.user_info} />
                </div>
              )}
            </>
          }
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pr-0 pl-0 order-first order-lg-last">
            <div className="d-flex flex-wrap justify-content-around user_Detailspage_Gender_Age_mainsection py-0 py-md-4">
              <div className="text-center user_Detailspage_Gender_Age_icon_main use_detai_view_single_user my-3 my-md-0">
                <div className="mx-auto user_Detailspage_Gender_Age_icon_section">
                  <img alt='Gender' src={GenderMale} className="user_Detailspage_Gender_Favourites2_img" />
                </div>
                <p className="user_Detailspage_Gender_Age_Favourites_title mb-0 mt-4">Gender</p>
                {skeletonUserImage ? (
                  <SkeletonTheme >
                    <div className='px-2'>
                      <Skeleton />
                    </div>
                  </SkeletonTheme>
                ) :
                  <h3 className="user_Detailspage_Gender_Age_Favourites_value mb-0 mt-1">{userData.gender}</h3>
                }
              </div>
              <div className="text-center user_Detailspage_Gender_Age_icon_main use_detai_view_single_user my-3 my-md-0">
                <div className="mx-auto user_Detailspage_Gender_Age_icon_section">
                  <img alt='Gender' src={GenderMale} className="user_Detailspage_Gender_Favourites2_img" />
                </div>
                <p className="user_Detailspage_Gender_Age_Favourites_title mb-0 mt-4">Category</p>
                {skeletonUserImage ? (
                  <SkeletonTheme >
                    <div className='px-2'>
                      <Skeleton />
                    </div>
                  </SkeletonTheme>
                ) :
                  <h3 className="user_Detailspage_Gender_Age_Favourites_value mb-0 mt-1">{userData.category_name}</h3>
                }
              </div>
              {userData?.hide_age === 0 &&
                (userData.date_of_birth || skeletonUserImage) && (
                  <div className="text-center user_Detailspage_Gender_Age_icon_main use_detai_view_single_user my-3 my-md-0">
                    <div className="mx-auto user_Detailspage_Gender_Age_icon_section">
                      <img alt='Calander' src={Calendar} className="user_Detailspage_Calendar_img" />
                    </div>

                    <p className="user_Detailspage_Gender_Age_Favourites_title mb-0 mt-4" >Age</p>
                    {skeletonUserImage ? (
                      <SkeletonTheme >
                        <div className='px-2'>
                          <Skeleton />
                        </div>
                      </SkeletonTheme>
                    ) :
                      <h3 className="user_Detailspage_Gender_Age_Favourites_value mb-0 mt-1">{userData.age}</h3>
                    }
                  </div>
                )
              }
              <div className="text-center user_Detailspage_Gender_Age_icon_main use_detai_view_single_user my-3 my-md-0">
                <div className="mx-auto user_Detailspage_Gender_Age_icon_section">
                  <img alt='Favourite' src={Favourites2} className="user_Detailspage_Gender_Favourites2_img" />
                </div>
                <p className="user_Detailspage_Gender_Age_Favourites_title mb-0 mt-4">Favourites</p>
                {skeletonUserImage ? (
                  <SkeletonTheme >
                    <div className='px-2'>
                      <Skeleton />
                    </div>
                  </SkeletonTheme>
                ) :
                  <h3 className="user_Detailspage_Gender_Age_Favourites_value mb-0 mt-1">{userData.total_favourites}</h3>
                }
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const PartnerProfile = ({ skeletonUserImage, userData }) => {



  return (
    <>
      <div className="mt-4 container  pl-0 pr-0 mb-3">

        <div className="row mt-2">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
            <div className="d-flex flex-wrap justify-content-around user_Detailspage_Gender_Age_mainsection py-0 py-md-4">
              <div className="text-center user_Detailspage_Gender_Age_icon_main_new my-3 my-md-0 ">
                <div className="mx-auto user_Detailspage_Gender_Age_icon_section">
                  <img alt='Gender' src={GenderMale} className="user_Detailspage_Gender_Favourites2_img" />
                </div>
                <p className="user_Detailspage_Gender_Age_Favourites_title mb-0 mt-4">Gender</p>
                {skeletonUserImage ? (
                  <SkeletonTheme >
                    <div className='px-2'>
                      <Skeleton />
                    </div>
                  </SkeletonTheme>
                ) :
                  <h3 className="user_Detailspage_Gender_Age_Favourites_value mb-0 mt-1">{userData.gender}</h3>
                }
              </div>
              <div className="text-center user_Detailspage_Gender_Age_icon_main_new my-3 my-md-0 ">
                <div className="mx-auto user_Detailspage_Gender_Age_icon_section">
                  <img alt='Gender' src={GenderMale} className="user_Detailspage_Gender_Favourites2_img" />
                </div>
                <p className="user_Detailspage_Gender_Age_Favourites_title mb-0 mt-4">Category</p>
                {skeletonUserImage ? (
                  <SkeletonTheme >
                    <div className='px-2'>
                      <Skeleton />
                    </div>
                  </SkeletonTheme>
                ) :
                  <h3 className="user_Detailspage_Gender_Age_Favourites_value mb-0 mt-1">{userData.category_name}</h3>
                }
              </div>
              {
                (
                  userData.is_couple === 1 && userData.hide_age === 0 && (

                    (userData.date_of_birth || skeletonUserImage) && (
                      <div className="text-center user_Detailspage_Gender_Age_icon_main_new my-3 my-md-0">
                        <div className="mx-auto user_Detailspage_Gender_Age_icon_section">
                          <img alt='Calander' src={Calendar} className="user_Detailspage_Calendar_img" />
                        </div>

                        <p className="user_Detailspage_Gender_Age_Favourites_title mb-0 mt-4" >Age</p>
                        {skeletonUserImage ? (
                          <SkeletonTheme >
                            <div className='px-2'>
                              <Skeleton />
                            </div>
                          </SkeletonTheme>
                        ) :
                          <h3 className="user_Detailspage_Gender_Age_Favourites_value mb-0 mt-1">{userData.age}</h3>
                        }
                      </div>
                    )

                  )
                )

              }
              {
                (
                  userData.is_couple === 1 && userData.hide_age === 0 && (
                    (userData.partner_details?.partner_dob || skeletonUserImage || userData.is_couple === 0 && userData.hide_age === 0) && (
                      <div className="text-center user_Detailspage_Gender_Age_icon_main_new my-3 my-md-0 mt-md-4 mt-lg-0">
                        <div className="mx-auto user_Detailspage_Gender_Age_icon_section">
                          <img alt='Calander' src={Calendar} className="user_Detailspage_Calendar_img" />
                        </div>

                        <p className="user_Detailspage_Gender_Age_Favourites_title mb-0 mt-4" >Partner Age</p>
                        {skeletonUserImage ? (
                          <SkeletonTheme >
                            <div className='px-2'>
                              <Skeleton />
                            </div>
                          </SkeletonTheme>
                        ) :
                          <h3 className="user_Detailspage_Gender_Age_Favourites_value mb-0 mt-1">{userData.partner_details?.partner_age}</h3>
                        }
                      </div>
                    )
                  )

                )
              }
              <div className="text-center user_Detailspage_Gender_Age_icon_main_new my-3 my-md-0 mt-md-4 mt-lg-0">
                <div className="mx-auto user_Detailspage_Gender_Age_icon_section">
                  <img alt='Favourite' src={Favourites2} className="user_Detailspage_Gender_Favourites2_img" />
                </div>
                <p className="user_Detailspage_Gender_Age_Favourites_title mb-0 mt-4">Favourites</p>
                {skeletonUserImage ? (
                  <SkeletonTheme >
                    <div className='px-2'>
                      <Skeleton />
                    </div>
                  </SkeletonTheme>
                ) :
                  <h3 className="user_Detailspage_Gender_Age_Favourites_value mb-0 mt-1">{userData.total_favourites}</h3>
                }
              </div>

            </div>
          </div>
        </div>
        <div className='row '>
          {/* user info */}
          <div className='col-lg-6 col-md-12 mt-4 '>
            {skeletonUserImage ? (
              <SkeletonTheme >
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pl-0 ">
                  <Skeleton count={7} />
                </div>
              </SkeletonTheme>
            ) :
              <>
                {userData?.user_info?.length != 0 && (
                  <div className="col-12 pl-0 pb-4 pb-lg-0 pr-0" >
                    <h1 className="user_Detailspage_About p-0 text-break">{userData.is_mf ? "Male & Profile" : `${userData?.name}'s Info`}</h1>
                    <UserInfoComponent dataInfo={userData?.user_info} />
                  </div>
                )}
              </>
            }
          </div>
          {/* partner info */}
          <div className='col-lg-6 col-md-12 mt-4  pr-0'>
            {skeletonUserImage ? (
              <SkeletonTheme >
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 pl-0 ">
                  <Skeleton count={7} />
                </div>
              </SkeletonTheme>
            ) :
              <>
                {userData?.partner_info?.length != 0 && (
                  <div className="col-12 pl-0 pb-4 pb-lg-0" >
                    <h1 className="user_Detailspage_About p-0 text-break">{userData.is_mf ? "Female Info" : `${userData?.partner_details?.partner_name}'s Info`}</h1>
                    <UserInfoComponent dataInfo={userData?.partner_info} />
                  </div>
                )}
              </>
            }
          </div>
        </div>
      </div>
    </>
  )
}

const UserInfoComponent = ({ dataInfo }) => {

  return (
    <>
      {dataInfo?.map((item) => {
        return (
          <>
            <h4 className="user_Detailspage_info my-2 ">{item.group_name}</h4>
            {
              item?.fields?.map((ele) => {
                return (
                  <div className="add_new_center_imginfo border-bottom">
                    <img alt='Field' className="text-center user_info_img_table" src={ele.image}></img>
                    <div className="d-flex justify-content-between align-items-center user_info_data_table">
                      <span className="user_info_data_table_catag">{ele.label}</span>
                      <span class="text-end text-break">{ele.field_value.split(",").map(value => {
                        if (isNaN(value)) {
                          return value;
                        }
                        if (value.indexOf('.') == -1) {
                          return value;
                        }
                        return parseFloat(value).toFixed(0)
                      }).join(", ")}</span>
                    </div>
                  </div>
                )
              })
            }
          </>
        )
      })}
    </>
  )
}

export default UserDetailspage

