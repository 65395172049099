import React, { useEffect, useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import "./Header.css"
import "../../css/OfferModal.css"
// import "../../css/Notificationspage.css"
import user from "../../images/logo.png"
import { BsChatDots } from 'react-icons/bs';
import { LogOutApi, offerModal } from '../../api/Api';
import { useAuth } from '../../middleware/AuthProvider';
import defaultImg from "../../images/user-profile-default-image.png";
import { useSocket } from '../../middleware/SocketProvider';
import Vector from "../../images/Subscriptionplan/Vector.png";
import MembershipModel from './MembershipModel';
import { Button, Modal } from 'react-bootstrap';
import { IoClose } from "react-icons/io5";
import "./HeaderMobile.css"
import { HiMenuAlt1 } from "react-icons/hi";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { IoMdCloseCircleOutline } from "react-icons/io";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';


const Header = ({ page, currentRoom }) => {
  const navigate = useNavigate();
  const { Socket } = useSocket();
  const { isAuthenticated, AuthData, setAuthData, siteSetting } = useAuth();
  const [unreadCount, setUnreadCount] = useState(0);
  const [membershipModelShow, setMembershipModelShow] = useState(false);
  const [hasExpired, setHasExpired] = useState(false);
  const [planValidityMessage, setPlanValidityMessage] = useState("");

  const Logout = () => {
    handleCloseheadersidebar()
    LogOutApi().then(function (response) {
      if (response.status) {
        var data = localStorage.getItem("locatione_allowed")
        localStorage.clear();
        localStorage.setItem("locatione_allowed", data);
        window.location.href = '/';
      }
    })
      .catch(function (error) {
        console.log(error);
      });

  }

  useEffect(async () => {
    if (AuthData.is_block) {
      return false
    }
    if (Socket) {
      Socket.emit("has_unread_message");
      Socket.on("has_unread_message", (data) => {
        setUnreadCount(data.unreadCount);
      });
      Socket.off("notification");
      Socket.on("notification", (data) => {
        if (data.status) {
          if (data.data.additional_data.type == "Message") {
            if (page == 'message') {
              if (window.innerWidth > 992) {
                return false
              }
              if (!currentRoom.room_id) {
                return false
              }
              if (currentRoom.member_id == data.data.additional_data.user_id) {
                return false;
              }
            }
          }
          Notification.requestPermission(async function (result) {
            if (result === 'granted') {
              navigator.serviceWorker.ready.then(async function (registration) {
                var url = null;
                if (data.data.additional_data.type == 'Gallery_Request_Approved') {
                  url = "/usergallery/" + data.data.additional_data.username
                }
                else {
                  url = "/messages?username=" + data.data.additional_data.username
                }
                data.data.additional_data.data = { url };
                const notification = registration.showNotification(data.data.title, data.data.additional_data);
              });
            }
          });
        }
      });
    }

    return () => {
      if (!AuthData.is_block) {
        if (Socket) {
          Socket.off("has_unread_message");
          Socket.off("notification");
        }
      }
    };

  }, [navigate, currentRoom, page, Socket]);



  useEffect(() => {
    var getPageLoadData = localStorage.getItem('firstLoad')
    if (getPageLoadData) {
      Socket.emit("check-plan-validity");
      Socket.on("check-plan-validity", (data) => {
        if (data.status) {
          setPlanValidityMessage(data.message)
          setHasExpired(true)
          if (window.innerWidth > 767) {
            var bodyStyles = document.body.style;
            bodyStyles.setProperty('--banner-div-height', '30px');
          } else {
            var bodyStyles = document.body.style;
            bodyStyles.setProperty('--banner-div-height', '20px ');
          }
        }
      })
    }
    return () => {
      Socket.off("check-plan-validity");
    };
  }, [localStorage.getItem('firstLoad')]);

  const alertclose = () => {
    localStorage.removeItem('firstLoad');
    setHasExpired(false)
    var bodyStyles = document.body.style;
    bodyStyles.setProperty('--banner-div-height', '0px');
  }

  const [show, setShow] = useState(false);
  const [offerdata, setOfferdata] = useState([])

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    // Fetch the last API call date from localStorage
    if (AuthData.role == "user") {
      var storedDate = "";
      storedDate = localStorage.getItem('BannerApiCallDate');
      // Check if the stored date is different from the current date
      const currentDate = getCurrentDate();
      if (storedDate != currentDate) {
        offerModal()
          .then(function (response) {
            if (response.data.status) {
              if (response.data.data.length) {
                console.log("resp", response.data);
                setOfferdata(response.data.data)
                setShow(true)
                localStorage.setItem('BannerApiCallDate', currentDate);
              }
            } else {
              setShow(false)
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  }, []);

  useEffect(() => {
    Socket.on('blocked', (blockData) => {
      setAuthData({ ...AuthData, is_block: blockData.status })
    })

    Socket.on('is_profile_verification_required', (profileData) => {
      setAuthData({ ...AuthData, is_profile_verification_required: profileData.status })
    })

    Socket.on('deleted', () => {
      var data = localStorage.getItem("locatione_allowed")
      localStorage.clear();
      localStorage.setItem("locatione_allowed", data);
      window.location.href = '/';
    })

    Socket.on('logout_user', () => {
      var data = localStorage.getItem("locatione_allowed")
      localStorage.clear();
      localStorage.setItem("locatione_allowed", data);
      window.location.href = '/';
    })

    return () => {
      Socket.off("blocked");
      Socket.off("is_profile_verification_required");
      Socket.off("deleted");
      Socket.off("logout_user");
    };
  }, [AuthData])

  //  ----  smart banner code start ---------

  const [smartbanner, setSmartbanner] = useState(true);

  useEffect(() => {
    const storedValue = sessionStorage.getItem('smartbanner');
    if (storedValue == 'false') {
      setSmartbanner(false);
    }
  }, []);
  // --- banner close handlechanges ---------
  const closeSmartBanner = () => {
    sessionStorage.setItem('smartbanner', 'false');
    setSmartbanner(false);
  }

  // --- show appstore ---------
  const appStoreSmartBanner = () => {
    sessionStorage.setItem('smartbanner', 'false');
    setSmartbanner(false);
    window.open(siteSetting?.siteSetting?.app_store_app_link, '_blank');
  }

  // --- show playstore  ---------
  const playStoreSmartBanner = () => {
    sessionStorage.setItem('smartbanner', 'false');
    setSmartbanner(false);
    window.open(siteSetting?.siteSetting?.play_store_app_link, '_blank');
  }

  // ----- this function cheak to ios or android  
  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "iOS";
    }
    return "unknown";
  }

  //  ----  smart banner code end  ---------

  const [headersidebar, setHeadersidebar] = useState(false);

  const handleCloseheadersidebar = () => setHeadersidebar(false);
  const handleShowheadersidebar = () => setHeadersidebar(true);


  return (
    <>
      {/* --------- plan expiry alert ---------  */}
      {(isAuthenticated && !AuthData.is_block && AuthData.role == "user") &&
        (
          window.innerWidth > 768 ?
            hasExpired &&
            <div className="alert alert-danger text-center " role="alert">
              {planValidityMessage}
              <button type="button" class="close" onClick={alertclose} >
                <span aria-hidden="true">&times;</span>
              </button>
              <span className="mb-0 ml-4 cursor_pointer_common" onClick={() => {
                if(!AuthData.is_profile_verification_required) {
                  setMembershipModelShow(true)
                }
              }}>Upgrade</span>
            </div>
            :
            hasExpired &&
            <div className="alert alert-danger text-center d-flex" role="alert">
              <marquee scrollamount="3" loop="infinite" >{planValidityMessage}</marquee>
              <span className="mb-0  mx-3 cursor_pointer_common" onClick={() => {
                if(!AuthData.is_profile_verification_required) {
                  setMembershipModelShow(true)
                }
              }}>Upgrade</span>
              <button type="button" class="close" onClick={alertclose} >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
        )
      }

      {/* ---------- ios and android show to banner condition ------- */}
      {(AuthData.role == "user") &&
        (getMobileOperatingSystem() == "iOS" && siteSetting?.siteSetting?.app_store_app_link) && (
          window.innerWidth > 768 ? (
            <></>
          ) : (
            smartbanner && (
              <div className="position-relative">
                <div className="smart_banner_main">
                  <div className="d-flex align-items-center">
                    <div className="smart_img_main">
                      <img src={require("../../images/Smartbanner/App_logo.png")} className="smart_app_logo" alt="App Logo" />
                    </div>
                    <div className="ml-2">
                      <p className="mb-0 smart_title">Hukup Australia</p>
                      <p className="mb-0 smart_title_secon">Download app from here</p>
                      <p className="mb-0 smart_title_secon">
                        <a href={siteSetting?.siteSetting?.app_store_app_link} target="_blank">
                          FREE - In App Store
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="">
                    <button className="smart_view_btn" onClick={appStoreSmartBanner} >Open In App</button>
                  </div>
                </div>
                <div className="smart_close_icon_main">
                  <IoMdCloseCircleOutline className="smart_close_icon" onClick={closeSmartBanner} />
                </div>
              </div>
            )
          )
        )
      }

      {/* ---------- ios and android show to banner condition ------- */}
      {(AuthData.role == "user") &&
        (getMobileOperatingSystem() != "iOS" && siteSetting?.siteSetting?.play_store_app_link) && (
          window.innerWidth > 768 ? (
            <></>
          ) : (
            smartbanner && (
              <div className="position-relative">
                <div className="smart_banner_main">
                  <div className="d-flex align-items-center">
                    <div className="smart_img_main">
                      <img src={require("../../images/Smartbanner/App_logo.png")} className="smart_app_logo" alt="App Logo" />
                    </div>
                    <div className="ml-2">
                      <p className="mb-0 smart_title">Hukup Australia</p>
                      <p className="mb-0 smart_title_secon">Download app from here</p>
                      <p className="mb-0 smart_title_secon">
                        <a href={siteSetting?.siteSetting?.play_store_app_link} target="_blank">
                          FREE - In Play Store
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="">
                    <button className="smart_view_btn" onClick={playStoreSmartBanner} >Open In App</button>
                  </div>
                </div>
                <div className="smart_close_icon_main">
                  <IoMdCloseCircleOutline className="smart_close_icon" onClick={closeSmartBanner} />
                </div>
              </div>
            )
          )
        )
      }

      <div className="container-fluid shadow-sm header_main position-relative">
        {/* ------  mobile and ipad header to show login user only ------- */}
        <div className="h-100 d-block d-lg-none">
          <div className="h-100  header_mobile_tab_main px-3">
            <div className='header_mobile_tab_logo_section '>
              {siteSetting?.siteSetting?.site_logo ?
                <img onClick={() => navigate("/home")} src={siteSetting?.siteSetting?.site_logo ? siteSetting?.siteSetting?.site_logo : user} className="sidebar_icon" alt="Hukup Australia Logo" />
                :
                <SkeletonTheme>
                  <Skeleton count={1} height={30} width={150} />
                </SkeletonTheme>
              }
            </div>
            <div className='header_mobile_tab_btn_section'>
              <Button className="hea_btn_btn_main" onClick={handleShowheadersidebar}>
                <HiMenuAlt1 className="hea_btn_icon" />
              </Button>
            </div>
          </div>
        </div>

        {/* ------  desktop header to show login user only------- */}
        <div className="d-none d-lg-block h-100">
          <Navbar collapseOnSelect expand="lg" className="h-100">
            <div className=" container-lg">
              <Navbar.Brand as={Link} to="/home" className="ml-2 ml-sm-0 ml-md-3 ml-lg-5 ml-xl-0 py-0 py-sm-0 py-md-0 py-lg-auto py-xl-auto logoset" >
                {siteSetting?.siteSetting?.site_logo ?
                  <img src={siteSetting?.siteSetting?.site_logo ? siteSetting?.siteSetting?.site_logo : user} className="navabar_brand_logo" alt="Hukup Australia Logo" />
                  :
                  <SkeletonTheme>
                    <Skeleton count={1} height={30} width={150} />
                  </SkeletonTheme>
                }
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                {(isAuthenticated && !AuthData.is_block && AuthData.role == "influencer") && (
                  <Nav className="navbar_nav_content w-100" >
                    <div className="d-flex flex-column flex-lg-row flex-xl-row flex-sm-column flex-md-column justify-content-end navbar_center_content">
                      <Nav.Link >
                        <NavLink as={Link} to="/home" className={({ isActive }) =>
                          isActive
                            ? "navbar_menu_home_title d-none d-lg-block"
                            : " navbar_menu_section d-none d-lg-block"
                        } >
                          Dashboard
                        </NavLink>
                      </Nav.Link>
                      <Nav.Link >
                        <NavLink as={Link} to="/referral-users" className={({ isActive }) =>
                          isActive
                            ? "navbar_menu_home_title"
                            : " navbar_menu_section"
                        } >
                          My Rewards
                        </NavLink>
                      </Nav.Link>
                      <Nav.Link >
                        <NavLink as={Link} to="/paymenthistory" className={({ isActive }) =>
                          isActive
                            ? "navbar_menu_home_title"
                            : " navbar_menu_section"
                        } >
                          Transaction History
                        </NavLink>
                      </Nav.Link>
                    </div>
                    <div className="d-flex flex-column flex-lg-row flex-xl-row flex-sm-column flex-md-column justify-content-end navbar_user_detail_dropdown mb-2 mb-lg-0 mt-2 mt-lg-0">
                      <div className="ml-xs-0 ml-sm-0 ml-md-0 ml-lg-5 user_dropdown">
                        <img
                          src={AuthData.thumbnail_image ? AuthData.thumbnail_image : defaultImg}
                          className="header_logo_img user_dropdown_img"
                          alt="Profile"
                        />
                        <NavDropdown align={{ lg: 'end' }} title={AuthData?.name?.length > 10 ? AuthData.name.substring(0, 10) + "..." : AuthData.name} id="collasible-nav-dropdown" className="user_dropdown_selector navbar_menu_section">
                          <NavDropdown.Item as={Link} to="/home" className="border-bottom text-center">Dashboard</NavDropdown.Item>
                          <NavDropdown.Item as={Link} to="/referral-users" className="text-center border-bottom">My Rewards</NavDropdown.Item>
                          <NavDropdown.Item as={Link} to="/paymenthistory" className="border-bottom text-center">Transaction History</NavDropdown.Item>
                          <NavDropdown.Item className="text-center" onClick={(e) => Logout()}>Log Out</NavDropdown.Item>
                        </NavDropdown>
                      </div>
                    </div>
                  </Nav>
                )}

                {(isAuthenticated && !AuthData.is_block && AuthData.role == "user") && (
                  <Nav className="navbar_nav_content w-100" >
                    <div className="d-flex flex-column flex-lg-row flex-xl-row flex-sm-column flex-md-column justify-content-end navbar_center_content">
                      <Nav.Link  >
                        <NavLink as={Link} to="/home" className={({ isActive }) =>
                          isActive
                            ? "navbar_menu_home_title d-none d-lg-block"
                            : " navbar_menu_section d-none d-lg-block"
                        } >
                          Home
                        </NavLink>
                      </Nav.Link>
                      <Nav.Link >
                        <NavLink as={Link} to="/likedyou" className={({ isActive }) =>
                          isActive
                            ? "navbar_menu_home_title"
                            : " navbar_menu_section"
                        } >
                          Liked
                        </NavLink>
                      </Nav.Link>
                      <Nav.Link >
                        <NavLink as={Link} to="/favourites" className={({ isActive }) =>
                          isActive
                            ? "navbar_menu_home_title"
                            : " navbar_menu_section"
                        } >
                          Favourites
                        </NavLink>
                      </Nav.Link>
                      <Nav.Link >
                        <NavLink as={Link} to="/matched" className={({ isActive }) =>
                          isActive
                            ? "navbar_menu_home_title"
                            : " navbar_menu_section"
                        } >
                          Matched
                        </NavLink>
                      </Nav.Link>
                      <Nav.Link >
                        <NavLink as={Link} to="/visitors" className={({ isActive }) =>
                          isActive
                            ? "navbar_menu_home_title"
                            : " navbar_menu_section"
                        } >
                          Visitors
                        </NavLink>
                      </Nav.Link>

                      <Nav.Link >
                        <NavLink as={Link} to="/events-announcement" className={({ isActive }) =>
                          isActive
                            ? "navbar_menu_home_title"
                            : " navbar_menu_section"
                        } >
                          Events And Parties
                        </NavLink>
                      </Nav.Link>
                    </div>
                    <div className="d-flex flex-column flex-lg-row flex-xl-row flex-sm-column flex-md-column justify-content-end navbar_user_detail_dropdown mb-2 mb-lg-0 mt-2 mt-lg-0">
                      <div className="ml-xs-0 ml-sm-0 ml-md-0 ml-lg-5 user_dropdown">
                        {AuthData.is_premium ?
                          <img src={Vector}
                            className={AuthData?.name?.length >= 5 ? AuthData?.name?.length >= 9 ? "premium_user_icon_10" : "premium_user_icon_9" : "premium_user_icon_5"}
                            alt="Premium"
                          /> : <p className="premium_user_link mb-0" onClick={() => setMembershipModelShow(true)}>Upgrade</p>}
                        <img
                          src={AuthData.thumbnail_image ? AuthData.thumbnail_image : defaultImg}
                          className="header_logo_img user_dropdown_img"
                          alt="Profile"
                        />
                        <NavDropdown align={{ lg: 'end' }} title={AuthData?.name?.length > 10 ? AuthData.name.substring(0, 10) + "..." : AuthData.name} id="collasible-nav-dropdown" className="user_dropdown_selector navbar_menu_section">
                          <NavDropdown.Item as={Link} to="/my-profile" className="border-bottom text-center">My Profile</NavDropdown.Item>
                          <NavDropdown.Item as={Link} to={"/my-events"} className="text-center border-bottom">My Party</NavDropdown.Item>
                          <NavDropdown.Item as={Link} to="/subscriptionplan" className="border-bottom text-center">Membership Plan </NavDropdown.Item>
                          <NavDropdown.Item as={Link} to="/paymenthistory" className="border-bottom text-center">Payment History</NavDropdown.Item>
                          <NavDropdown.Item as={Link} to="/accountsetting" className="text-center border-bottom">Account Setting</NavDropdown.Item>
                          {
                            AuthData.is_premium ?

                              <NavDropdown.Item as={Link} to="/privacy" className="text-center border-bottom">
                                Private Me  <img alt='Premium' src={Vector} className="Home_Private_Me_premium_icon" /></NavDropdown.Item>
                              :
                              <NavDropdown.Item onClick={() => setMembershipModelShow(true)} className="text-center border-bottom">Private Me <img alt='Premium' src={Vector} className="Home_Private_Me_premium_icon" /></NavDropdown.Item>
                          }
                          <NavDropdown.Item as={Link} to={"/reportproblem"} className="text-center border-bottom">Report a Problem</NavDropdown.Item>
                          <NavDropdown.Item as={Link} to={"/verification"} className="text-center border-bottom">Verification</NavDropdown.Item>
                          <NavDropdown.Item as={Link} to={"/general-announcement"} className="text-center border-bottom d-none d-lg-block">What’s New</NavDropdown.Item>
                          <NavDropdown.Item as={Link} to="/blockeduser" className="text-center border-bottom">Blocked User</NavDropdown.Item>
                          {AuthData.eligible_for_referral === 1 && AuthData.is_referral_available && (
                            <NavDropdown.Item as={Link} to="/referral-users" className="text-center border-bottom">My Rewards</NavDropdown.Item>
                          )}
                          <NavDropdown.Item className="text-center" onClick={(e) => Logout()}>Log Out</NavDropdown.Item>
                        </NavDropdown>
                      </div>
                    </div>
                    <div className="position-relative d-none d-lg-block">
                      {unreadCount > 0 ? <div className="header_messages_notification_dot cursor_pointer_common" onClick={() => navigate("/messages")}><span>{unreadCount}</span></div> : ""}
                      <BsChatDots onClick={() => navigate("/messages")} className="ml-0  ml-lg-3 mb-3 mb-lg-0 mt-2 Bs_Chat_Dots_icon" />
                    </div>
                  </Nav>
                )}
              </Navbar.Collapse>
            </div>
          </Navbar>
        </div>

      </div>
      <MembershipModel
        show={membershipModelShow}
        onHide={() => setMembershipModelShow(false)} />


      <Modal show={show} onHide={handleClose}
        className="offerModal_main"
        centered >
        {offerdata.map((item) => {
          return (<>
            <div className="position-relative">
              <div className="offerModal_close_icon">
                <IoClose onClick={handleClose} />
              </div>
              <div className="offerModal_image_main text-center mt-3">
                <img alt='Bannner' src={item.banner_image} className="offerModal_image_main_inimag" />
              </div>
              <p className="offerModal_descrip mt-2">
                {item.banner_text}
              </p>
            </div>
          </>
          )
        })

        }
      </Modal>

      <div >
        <Offcanvas show={headersidebar} onHide={handleCloseheadersidebar} id="sidebar_header_main">
          <div className="sidebar_header_section_main d-flex">
            <div className="sidebar_header_title_icon_section align-items-center">
              <div className="sidebar_header_icon ">
                <img
                  src={AuthData.thumbnail_image ? AuthData.thumbnail_image : defaultImg}
                  className="sidebar_logo_img"
                  alt="Profile"
                />
              </div>
              {(isAuthenticated && !AuthData.is_block && AuthData.role == "influencer") && (
                <div className='ml-2'>
                  <div className="d-flex align-items-center justify-content-end">
                    <h3 className="mb-0">{AuthData?.name?.length > 10 ? AuthData.name.substring(0, 10) + "..." : AuthData.name}</h3>
                  </div>
                  {/* <p className="mb-0 sidebar_show_profile" onClick={() => navigate("/home")}>Dashboard</p> */}
                </div>
              )}

              {(isAuthenticated && !AuthData.is_block && AuthData.role == "user") && (
                <div className='ml-2'>
                  <div className="d-flex align-items-center justify-content-end">
                    <h3 className="mb-0">{AuthData?.name?.length > 10 ? AuthData.name.substring(0, 10) + "..." : AuthData.name}</h3>
                    {AuthData.is_premium ?
                      <img alt='premium' src={Vector} width={20} height={20} className="ml-2" />
                      :
                      <p className="mb-0 ml-2 sidebar_Upgrade_link" onClick={() => setMembershipModelShow(true)}>Upgrade</p>
                    }
                  </div>
                  <p className="mb-0 sidebar_show_profile" onClick={() => navigate("/my-profile")} >My profile</p>
                </div>
              )}
            </div>

            <div className="sidebar_header_close_btn_section d-flex align-items-center justify-content-end">
              <Button className="sidebar_hea_close" onClick={handleCloseheadersidebar}>
                <IoClose className="hea_btn_icon" />
              </Button>
            </div>
          </div>

          <div className="sidebar_header_section_main  mt-3">
            {(isAuthenticated && !AuthData.is_block && AuthData.role == "influencer") && (
              <div className="px-1">
                <div className="border-bottom sidebar_menu_main_sec">
                  <NavLink to="/home" className={({ isActive }) =>
                    isActive
                      ? "sidbar_menu_active"
                      : " sidbar_menu_deactive"
                  } >
                    <h6 className="my-2">
                      Dashboard
                    </h6>
                  </NavLink>
                </div>
                <div className="border-bottom sidebar_menu_main_sec">
                  <NavLink to="/referral-users" className={({ isActive }) =>
                    isActive
                      ? "sidbar_menu_active"
                      : " sidbar_menu_deactive"
                  } >
                    <h6 className="my-2">
                      My Rewards
                    </h6>
                  </NavLink>
                </div>
                <div className="border-bottom sidebar_menu_main_sec">
                  <NavLink to="/paymenthistory" className={({ isActive }) =>
                    isActive
                      ? "sidbar_menu_active"
                      : " sidbar_menu_deactive"
                  } >
                    <h6 className="my-2">
                      Transaction History
                    </h6>
                  </NavLink>
                </div>
                <div className="border-bottom sidebar_menu_main_sec">
                  <NavLink onClick={(e) => Logout()} className={({ isActive }) =>
                    isActive
                      ? " sidbar_menu_deactive"
                      : " sidbar_menu_deactive"
                  } >
                    <h6 className="my-2">
                      Logout
                    </h6>
                  </NavLink>
                </div>
              </div>
            )}
            {(isAuthenticated && !AuthData.is_block && AuthData.role == "user") && (
              <div className="px-1">
                   <div className="border-bottom sidebar_menu_main_sec">
                  <NavLink to="/my-events" className={({ isActive }) =>
                    isActive
                      ? "sidbar_menu_active"
                      : " sidbar_menu_deactive"
                  } >
                    <h6 className="my-2">

                    My Party
                    </h6>
                  </NavLink>
                </div>
                <div className="border-bottom sidebar_menu_main_sec">
                  <NavLink to="/matched" className={({ isActive }) =>
                    isActive
                      ? "sidbar_menu_active"
                      : " sidbar_menu_deactive"
                  } >
                    <h6 className="my-2">

                      Matched
                    </h6>
                  </NavLink>
                </div>

                <div className="border-bottom sidebar_menu_main_sec">
                  <NavLink to="/favourites" className={({ isActive }) =>
                    isActive
                      ? "sidbar_menu_active"
                      : " sidbar_menu_deactive"
                  } >
                    <h6 className="my-2">

                      Favourites
                    </h6>
                  </NavLink>
                </div>
                <div className="border-bottom sidebar_menu_main_sec">
                  <NavLink to="/gallery" className={({ isActive }) =>
                    isActive
                      ? "sidbar_menu_active"
                      : " sidbar_menu_deactive"
                  } >
                    <h6 className="my-2">
                      My Gallery
                    </h6>
                  </NavLink>
                </div>
                <div className="border-bottom sidebar_menu_main_sec">
                  <NavLink to="/subscriptionplan" className={({ isActive }) =>
                    isActive
                      ? "sidbar_menu_active"
                      : " sidbar_menu_deactive"
                  } >
                    <h6 className="my-2">

                      Membership Plan
                    </h6>
                  </NavLink>
                </div>
                <div className="border-bottom sidebar_menu_main_sec">
                  <NavLink to="/paymenthistory" className={({ isActive }) =>
                    isActive
                      ? "sidbar_menu_active"
                      : " sidbar_menu_deactive"
                  } >
                    <h6 className="my-2">

                      Payment History
                    </h6>
                  </NavLink>
                </div>
                <div className="border-bottom sidebar_menu_main_sec">
                  <NavLink to="/accountsetting" className={({ isActive }) =>
                    isActive
                      ? "sidbar_menu_active"
                      : " sidbar_menu_deactive"
                  } >
                    <h6 className="my-2">

                      Account Setting
                    </h6>
                  </NavLink>
                </div>
                <div className="border-bottom sidebar_menu_main_sec">

                  {
                    AuthData.is_premium ?
                      <NavLink to="/privacy" className={({ isActive }) =>
                        isActive
                          ? "sidbar_menu_active"
                          : " sidbar_menu_deactive"
                      } >
                        <h6 className="my-2">
                          Private Me <img alt='Premium' src={Vector} className="Home_Private_Me_premium_icon" />
                        </h6>
                      </NavLink>
                      :
                      <NavLink onClick={() => setMembershipModelShow(true)} className={({ isActive }) =>
                        isActive
                          ? "sidbar_menu_deactive"
                          : " sidbar_menu_deactive"
                      } >
                        <h6 className="my-2">
                          Private Me <img alt='Premium' src={Vector} className="Home_Private_Me_premium_icon" />
                        </h6>
                      </NavLink>
                  }
                </div>

                <div className="border-bottom sidebar_menu_main_sec">
                  <NavLink to="/reportproblem" className={({ isActive }) =>
                    isActive
                      ? "sidbar_menu_active"
                      : " sidbar_menu_deactive"
                  } >
                    <h6 className="my-2">
                      Report Problem
                    </h6>
                  </NavLink>
                </div>
                <div className="border-bottom sidebar_menu_main_sec">
                  <NavLink to="/verification" className={({ isActive }) =>
                    isActive
                      ? "sidbar_menu_active"
                      : " sidbar_menu_deactive"
                  } >
                    <h6 className="my-2">
                      Verification
                    </h6>
                  </NavLink>
                </div>
                <div className="border-bottom sidebar_menu_main_sec">
                  <NavLink to="/general-announcement" className={({ isActive }) =>
                    isActive
                      ? "sidbar_menu_active"
                      : " sidbar_menu_deactive"
                  } >
                    <h6 className="my-2">
                    Announcement
                    </h6>
                  </NavLink>
                </div>
                <div className="border-bottom sidebar_menu_main_sec">
                  <NavLink to="/blockeduser" className={({ isActive }) =>
                    isActive
                      ? "sidbar_menu_active"
                      : " sidbar_menu_deactive"
                  } >
                    <h6 className="my-2">
                      Blocked User
                    </h6>
                  </NavLink>
                </div>
                {AuthData.eligible_for_referral === 1 && AuthData.is_referral_available && (
                  <div className="border-bottom sidebar_menu_main_sec">
                    <NavLink to="/referral-users" className={({ isActive }) =>
                      isActive
                        ? "sidbar_menu_active"
                        : " sidbar_menu_deactive"
                    } >
                      <h6 className="my-2">
                        My Rewards
                      </h6>
                    </NavLink>
                  </div>
                )}
                <div className="border-bottom sidebar_menu_main_sec">
                  <NavLink onClick={(e) => Logout()} className={({ isActive }) =>
                    isActive
                      ? " sidbar_menu_deactive"
                      : " sidbar_menu_deactive"
                  } >
                    <h6 className="my-2">
                      Logout
                    </h6>
                  </NavLink>
                </div>
              </div>
            )}
          </div>
        </Offcanvas>
      </div>
    </>
  )
}

export default Header