import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import errorImg from "../../../images/nophoto.png";
import { useAuth } from "../../../middleware/AuthProvider";
import { timeAgo } from "../../../helper/getCookie";
import verifyIcon from '../../../images/verified-icon.png';

const MessageUserCard = (props) => {
  const { AuthData } = useAuth();
  const [unreadCount, setUnreadCount] = useState(props.e.unread_message);
  const [isEncrypted, setIsEncrypted] = useState((!AuthData.is_premium && props.index >= props.topActiveChatLimit));
  console.log("props",props.e);
  useEffect(() => {
    setUnreadCount(props.e.unread_message)
    if(props.e.room_id == props.currentRoomId) {
      setUnreadCount(0);
    }
  }, [props.e.room_id, props.e.unread_message, props.currentRoomId])

  const removeHtmlTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return doc.body.textContent || "" ;
  };
  

  return (
    <Col xs={12} className={"p-0 moblie_card moblie_card_" + isEncrypted} >
      <div className={"card all_user_chat all_user_chat_" + (props.e.room_id == props.currentRoomId)} onClick={() => {
        if(props.e.room_id != props.currentRoomId && !isEncrypted) {
          setUnreadCount(0);
          props.handleCardClick(props.e.room_id)
        }
      }}>
        <Row className="m-0 align-items-center">
          <Col lg={1} md={1} xs={2} className="profile_img_col p-0">
            <img
              alt="Profile"
              src={props.e.thumbnail_image}
              className="user_chat_profile_img"
              onError={(e) => {
                e.target.src = errorImg;
              }}
            ></img>
          </Col>
          <Col lg={8} md={8} xs={7}>
            <p className="chat_user_name my-0">
              {props.e.user_name}
              {props.e.userProfileVerify === 1 && <img alt='Verify' src={verifyIcon} className='user_verify-icon-home'></img>}
            </p>
            {
              props.e.message_type == "private_gallery_sent_notification" && (
                <p className="chat_user_msg my-0">Gallery Access Request</p>
              )
            }
            {
              props.e.message_type == "private_gallery_approved_notification" && (
                <p className="chat_user_msg my-0">Gallery Access Granted</p>
              )
            }
            {
              props.e.message_type == "private_gallery_rejected_notification" && (
                <p className="chat_user_msg my-0">Gallery Access Rejected</p>
              )
            }
            {
              props.e.message_type == "text" && (
                <p className="chat_user_msg my-0">{ removeHtmlTags(props.e.message) }</p>
              )
            }
            {
              ['image', 'video', 'audio', 'document', 'location', 'profile', 'sticker'].includes(props.e.message_type) && (
                <p className="chat_user_msg my-0">{props.e.message_type}</p>
              )
            }
            {
              ['liked_notification', 'visite_notification', 'favorite_notification', 'match_notification'].includes(props.e.message_type) && (
                <p className="chat_user_msg my-0">{props.e.message}</p>
              )
            }
          </Col>
          <Col lg={3} md={3} xs={3} className="chat_number_col text-center px-0">
            {(unreadCount !== 0 && props.e.room_id != props.currentRoomId) && (
              <p className="mb-0">
                <div className="chat_number my-0">{unreadCount}</div>
              </p>
            )}
            <p className="chat_time my-0">
              {timeAgo(props.e.created_at)}
            </p>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default MessageUserCard;
